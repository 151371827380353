











































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ButtonComponent extends Vue {
    @Prop({ default: 'button' }) type!: string;
    @Prop({ type: Boolean, default: false }) small!: boolean;
    @Prop() color!: any;
    @Prop({ type: Boolean }) block!: boolean;
    @Prop({ type: Boolean }) icon!: boolean;
    @Prop({ type: Boolean }) text!: boolean;
    @Prop({ type: Boolean }) plain!: boolean;
    @Prop({ type: Boolean, default: true }) outlined!: boolean;
    @Prop({ type: Boolean }) loading!: boolean;
    @Prop({ type: Boolean }) disabled!: boolean;
    @Prop() to!: string | any;

    @Prop() badgeColor!: string;
    @Prop() badgeContent!: string;

    get outlinedSetUp(): boolean {
        return this.outlined && !this.icon;
    }
}
