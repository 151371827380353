import Vue from 'vue';
import App from './app.vue';
import CountryFlag from 'vue-country-flag';

import router from './router';
import store from './store';

import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import vuePrototype from './plugins/vue-prototype';

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

/* COMPONENTS */
import ButtonComponent from '@/ui/components/inputs/button.component.vue';
import DateFieldComponent from '@/ui/components/inputs/date-field.component.vue';
import DatetimePickerComponent from '@/ui/components/inputs/datetime-picker.component.vue';
import NumberFieldComponent from '@/ui/components/inputs/number-field.component.vue';
import PasswordFieldComponent from '@/ui/components/inputs/password-field.component.vue';
import SelectFieldComponent from '@/ui/components/inputs/select-field.component.vue';
import TextAreaComponent from '@/ui/components/inputs/text-area.component.vue';
import TextFieldComponent from '@/ui/components/inputs/text-field.component.vue';

import ConfirmComponent from '@/ui/components/confirm/confirm.component.vue';
import YesNoComponent from '@/ui/components/yes-no.component.vue';

import LinesSelectComponent from '@/ui/components/lines/lines-select.component.vue';

Vue.config.productionTip = false;

Vue.use(vuePrototype);

Vue.component('country-flag', CountryFlag);
Vue.component('c-btn', ButtonComponent);
Vue.component('c-date-field', DateFieldComponent);
Vue.component('c-datetime-picker', DatetimePickerComponent);
Vue.component('c-number-field', NumberFieldComponent);
Vue.component('c-password-field', PasswordFieldComponent);
Vue.component('c-select-field', SelectFieldComponent);
Vue.component('c-text-area', TextAreaComponent);
Vue.component('c-text-field', TextFieldComponent);

Vue.component('c-yes-no', YesNoComponent);

Vue.component('c-confirm', ConfirmComponent);

Vue.component('c-lines', LinesSelectComponent);

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
