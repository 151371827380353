import { AxiosResponse } from 'axios';
import buildQuery, { QueryOptions } from 'odata-query';

import { BaseApiService } from './base/base-api.service';
import { ODataResult } from '@/models/odata.result';

export class ODataService extends BaseApiService {
    getList<T>(controller: string, query?: Partial<QueryOptions<T>>): Promise<AxiosResponse<ODataResult<T>>> {
        return this.get<ODataResult<T>>(`odata/${controller}` + buildQuery(query));
    }

    getById<T>(controller: string, key: number | string, query?: Partial<QueryOptions<T>>): Promise<AxiosResponse<T>> {
        return this.get<T>(`odata/${controller}` + buildQuery({ ...query, key }));
    }

    getListPost<T>(controller: string, data?: Partial<QueryOptions<T>>, query?: Partial<QueryOptions<T>>): Promise<AxiosResponse<T>> {
        return this.post<T>(`odata/${controller}/$query` + buildQuery(query), buildQuery(data).substring(1), {
            headers: {
                'Content-Type': 'text/plain',
            },
        });
    }

    create<T>(controller: string, data: unknown): Promise<AxiosResponse<T>> {
        return this.post<T>(`odata/${controller}`, data);
    }

    update<T>(controller: string, key: number, data: unknown): Promise<AxiosResponse<T>> {
        return this.patch<T>(`odata/${controller}(${key})`, data);
    }

    remove(controller: string, key: number): Promise<AxiosResponse> {
        return this.delete(`odata/${controller}(${key})`);
    }

    function<T>(controller: string, query: Partial<QueryOptions<T>>): Promise<AxiosResponse<T>> {
        return this.get<T>('odata/' + controller + buildQuery(query));
    }

    action<T>(controller: string, data: unknown, query: Partial<QueryOptions<T>>): Promise<AxiosResponse<T>> {
        return this.post<T>('odata/' + controller + buildQuery(query), data);
    }
}
