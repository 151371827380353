import { RouteConfig } from 'vue-router';

import LineListPage from '@/ui/pages/admin/line/line-list.page.vue';
import LineDetailPage from '@/ui/pages/admin/line/line-detail.page.vue';

export const LINE_ROUTES: RouteConfig[] = [
    {
        path: '',
        component: LineListPage,
        name: 'line.list',
    },
    {
        path: ':id',
        component: LineDetailPage,
        name: 'line.detail',
    },
    {
        path: 'new',
        component: LineDetailPage,
        name: 'line.new',
    },
    {
        path: '*',
        redirect: 'line.list',
    },
];
