var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-toolbar',{staticClass:"mb-1",attrs:{"dense":""}},[_c('c-btn',{staticClass:"mx-4",attrs:{"small":"","color":"primary","to":{ name: 'user.list' }}},[_vm._v(_vm._s(_vm.$t('Global.Back')))])],1),_c('v-system-bar',{attrs:{"dark":""}},[_c('v-checkbox',{attrs:{"label":_vm.$t('Global.OnlyActive')},on:{"change":_vm.changeDebounce},model:{value:(_vm.filter.active),callback:function ($$v) {_vm.$set(_vm.filter, "active", $$v)},expression:"filter.active"}}),_c('v-spacer'),_c('c-refresher',{attrs:{"isLoading":_vm.isLoading,"refreshTime":_vm.refreshTime},on:{"refresh":_vm.fetchData}})],1),_c('v-data-table',{staticClass:"row-pointer",attrs:{"dense":"","loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.users ? _vm.users.value : [],"options":_vm.options,"server-items-length":_vm.users ? _vm.users['@odata.count'] : -1,"footer-props":{ 'items-per-page-options': [10, 20, 30, 50, 100] },"item-class":_vm.itemClass},on:{"update:options":[function($event){_vm.options=$event},_vm.fetchData],"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [(item.isActive)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.isAllowed",fn:function(ref){
var item = ref.item;
return [(item.isAllowed)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.userGroups",fn:function(ref){
var item = ref.item;
return _vm._l((item.userGroups),function(group,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(group.group.name)+" "),(index < item.userGroups.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])})}},{key:"item.requestDeleteDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$formatDate(item.requestDeleteDate, 'dd.MM.yyyy HH:mm'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex"}},[_c('v-btn',{attrs:{"outlined":"","small":"","color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.accept(item.id)}}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(_vm.$t('Global.Accept'))+" ")],1),_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","small":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.reject(item.id)}}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(_vm._s(_vm.$t('Global.Reject'))+" ")],1)],1)]}},{key:"body.prepend",fn:function(){return [_c('tr',{staticClass:"filter-row"},[_c('td',[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"auto","clearable":""},on:{"keyup":_vm.changeDebounce,"click:clear":_vm.changeDebounce},model:{value:(_vm.filter.username),callback:function ($$v) {_vm.$set(_vm.filter, "username", $$v)},expression:"filter.username"}})],1),_c('td',[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"auto","clearable":""},on:{"keyup":_vm.changeDebounce,"click:clear":_vm.changeDebounce},model:{value:(_vm.filter.lastName),callback:function ($$v) {_vm.$set(_vm.filter, "lastName", $$v)},expression:"filter.lastName"}})],1),_c('td',[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"auto","clearable":""},on:{"keyup":_vm.changeDebounce,"click:clear":_vm.changeDebounce},model:{value:(_vm.filter.firstName),callback:function ($$v) {_vm.$set(_vm.filter, "firstName", $$v)},expression:"filter.firstName"}})],1),_c('td',[_c('v-text-field',{staticClass:"my-1",attrs:{"outlined":"","dense":"","hide-details":"auto","clearable":""},on:{"keyup":_vm.changeDebounce,"click:clear":_vm.changeDebounce},model:{value:(_vm.filter.email),callback:function ($$v) {_vm.$set(_vm.filter, "email", $$v)},expression:"filter.email"}})],1)])]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }