


















































































































































import { Line } from '@/models/line';
import { ODataResult } from '@/models/odata.result';
import { Station } from '@/models/station';
import { GridOptions } from '@/models/vuetify';
import { debounce, get } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class JidokaLogTab extends Vue {
    dialog = false;
    isLoading = false;
    data: ODataResult<any> = { value: [], '@odata.count': 0 };

    filter: any = {};

    lines: Line[] = [];
    stations: Station[] = [];

    audit: any | null = null;
    auditValues: any | null = null;

    options: GridOptions = {
        page: 1,
        itemsPerPage: +(localStorage.getItem('jidoka_log_itemsPerPage') || 20),
        sortBy: ['timestamp'],
        sortDesc: [true],
        multiSort: true,
        mustSort: false,
    };

    headers = [
        { text: this.$t('Audit.Header.Action'), value: 'action', width: '200', class: 'light-green--text subtitle-2' },
        // { text: 'ID', value: 'jidoka.id', width: 150, class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Line.Name'), value: 'jidoka.line.name', width: '200', class: 'light-green--text subtitle-2' },
        {
            text: this.$t('Audit.Header.OldValue'),
            value: 'currentRuleOldValue',
            class: 'light-green--text subtitle-2',
            sortable: false,
        },
        {
            text: this.$t('Audit.Header.NewValue'),
            value: 'currentRuleNewValue',
            class: 'light-green--text subtitle-2',
            sortable: false,
        },
        {
            text: this.$t('Audit.Header.OldValue'),
            value: 'targetFPYOldValue',
            class: 'light-green--text subtitle-2',
            sortable: false,
        },
        {
            text: this.$t('Audit.Header.NewValue'),
            value: 'targetFPYNewValue',
            class: 'light-green--text subtitle-2',
            sortable: false,
        },
        { text: this.$t('Entity.Jidoka.Description'), value: 'description', class: 'light-green--text subtitle-2', sortable: false },
        {
            text: this.$t('Entity.Audit.User'),
            value: 'user.displayName',
            width: '200',
            class: 'light-green--text subtitle-2',
        },
        { text: this.$t('Audit.Header.Date'), value: 'timestamp', width: '150', class: 'light-green--text subtitle-2' },
    ];

    actions = [
        { text: this.$t('Audit.Action.Create'), value: 'Create' },
        { text: this.$t('Audit.Action.Update'), value: 'Update' },
        { text: this.$t('Audit.Action.Delete'), value: 'Delete' },
        { text: this.$t('Audit.Action.Convert'), value: 'Convert' },
    ];

    getLine(id: number): string {
        return this.lines.find((f) => f.id == id)?.name ?? '';
    }

    getStation(id: number): string {
        return this.stations.find((f) => f.id == id)?.name ?? '';
    }

    changeDebounce!: () => void;
    created(): void {
        this.changeDebounce = debounce(() => {
            if (this.options.page != 1) {
                this.options.page = 1;
            } else {
                this.fetchData();
            }
        }, 400);
    }

    mounted(): void {
        this.$odata
            .getList<Line>('line', { orderBy: ['name'], select: ['id', 'name', 'isActive'] })
            .then((a) => (this.lines = a.data.value));

        this.$odata.getList<Station>('station', { orderBy: ['name'], select: ['id', 'name'] }).then((a) => (this.stations = a.data.value));
    }

    getOldValue(item: any, prop: string) {
        if (!item) return '';

        const oldValues = JSON.parse(item.oldValues);
        return get(oldValues, prop);
    }

    getNewValue(item: any, prop: string) {
        if (!item) return '';

        const newValues = JSON.parse(item.newValues);
        return get(newValues, prop);
    }

    fetchData(): void {
        this.isLoading = true;

        if (isNaN(Number(this.options.itemsPerPage)) || !this.options.itemsPerPage) {
            this.options.itemsPerPage = 20;
        }

        const filter: any[] = [];

        const top = this.options.itemsPerPage;
        const skip = (this.options.page - 1) * this.options.itemsPerPage;
        const orderBy = this.options.sortBy.map(
            (sort, index) => sort.replaceAll('.', '/') + (this.options.sortDesc[index] ? ' desc' : '')
        ) as any;

        if (this.filter.action) filter.push({ action: { eq: this.filter.action } });
        // if (this.filter.users && this.filter.users.length) filter.push({ userId: { in: this.filter.users } });
        if (this.filter.lines && this.filter.lines.length) filter.push({ jidoka: { lineId: { in: this.filter.lines } } });
        if (this.filter.id)
            filter.push({
                or: [{ entityId: { eq: this.filter.id } }, { and: [{ entityId: { eq: null } }, { jidokaId: { eq: this.filter.id } }] }],
            });

        this.$odata
            .getList<any>('audit', {
                top,
                skip,
                func: 'Jidoka()',
                filter,
                orderBy,
                expand: {
                    user: { select: ['displayName'] },
                    jidoka: {
                        expand: {
                            line: { select: ['id', 'name'] },
                        },
                    },
                },
                count: true,
            })
            .then((d) => (this.data = d.data))
            .finally(() => {
                this.isLoading = false;
                localStorage.setItem('jidoka_log_itemsPerPage', this.options.itemsPerPage.toString());
            });
    }

    rowClick(e: any): void {
        if (!e) return;

        const oldValues = JSON.parse(e.oldValues);
        const newValues = JSON.parse(e.newValues);

        const result = {};
        Object.keys(oldValues).forEach((key) => {
            result[key] = [oldValues[key]];
        });

        Object.keys(newValues).forEach((key) => {
            if (result[key]) {
                result[key].push(newValues[key]);
            } else {
                result[key] = [null, newValues[key]];
            }
        });

        const keysForRemove: string[] = [];

        Object.keys(result).forEach((f) => {
            if (result[f][0] == result[f][1]) {
                keysForRemove.push(f);
            }
        });

        keysForRemove.forEach((f) => {
            delete result[f];
        });

        this.audit = e;
        this.auditValues = result;

        this.dialog = true;
    }
}
