














































































































import { ODataResult } from '@/models/odata.result';
import { User } from '@/models/user';
import { Group } from '@/models/group';
import { Component, Vue } from 'vue-property-decorator';
import { debounce } from 'lodash';
import { Line } from '@/models/line';
import RefresherComponent from '@/ui/components/refresher.component.vue';
import { GridOptions } from '@/models/vuetify';

@Component({
    components: {
        'c-refresher': RefresherComponent,
    },
})
export default class UserListAllowedPage extends Vue {
    firstLoad = true;
    isLoading = false;

    refreshTime = 0;

    users: ODataResult<User> | null = null;

    groups: Group[] = [];
    lines: Line[] = [];

    selected: User[] = [];
    filter: any = {
        username: '',
        lastName: '',
        firstName: '',
        active: true,
        allowed: null,
    };

    options: GridOptions = {
        page: 1,
        itemsPerPage: +(localStorage.getItem('user_itemsPerPage') || 20),
        sortBy: ['requestDeleteDate'],
        sortDesc: [false],
        multiSort: true,
    };

    headers = [
        { text: this.$t('Page.Admin.User.List.Table.UserName'), value: 'userName', class: 'light-green--text subtitle-2' },
        { text: this.$t('Page.Admin.User.List.Table.LastName'), value: 'lastName', class: 'light-green--text subtitle-2' },
        { text: this.$t('Page.Admin.User.List.Table.FirstName'), value: 'firstName', class: 'light-green--text subtitle-2' },
        { text: this.$t('Page.Admin.User.List.Table.Email'), value: 'email', class: 'light-green--text subtitle-2' },
    ];

    itemClass(item: User): string {
        const result: string[] = [];
        if (!item.isActive) {
            result.push('row-inactive');
        }

        return result.join(' ');
    }

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-account-multiple-outline');
        this.$store.commit('SetTitle', this.$t('Page.Admin.User.List.Title'));
    }

    changeDebounce!: () => void;
    created(): void {
        this.changeDebounce = debounce(() => {
            if (this.options.page != 1) {
                this.options.page = 1;
            } else {
                this.fetchData();
            }
        }, 400);
    }

    async fetchData(): Promise<void> {
        if (isNaN(+this.options.itemsPerPage) || !this.options.itemsPerPage) {
            this.options.itemsPerPage = 20;
        }

        if (this.firstLoad) {
            if (this.$route.query && Object.keys(this.$route.query).length) {
                this.filter.username = this.$route.query.userName?.toString();
                this.filter.active = this.$isBooleanTrue(this.$route.query.isActive);
                this.filter.allowed = this.$isBooleanTrue(this.$route.query.isAllowed);
            }

            this.firstLoad = false;
        }

        this.isLoading = true;
        const filter: any[] = [];

        const skip = (this.options.page - 1) * this.options.itemsPerPage;
        const top = this.options.itemsPerPage;

        filter.push({ isAllowed: { eq: false } });
        filter.push({ isAnonymized: { eq: false } });

        if (this.filter.active) {
            filter.push({ isActive: { eq: true } });
        }

        if (this.filter.username) {
            filter.push({ 'toLower(userName)': { contains: this.filter.username.toLowerCase() } });
        }

        if (this.filter.lastName) {
            filter.push({ 'toLower(lastName)': { contains: this.filter.lastName.toLowerCase() } });
        }

        if (this.filter.firstName) {
            filter.push({ 'toLower(firstName)': { contains: this.filter.firstName.toLowerCase() } });
        }

        if (this.filter.email) {
            filter.push({ 'toLower(email)': { contains: this.filter.email.toLowerCase() } });
        }

        const orderBy = this.options.sortBy.map((sort, index) => sort + (this.options.sortDesc[index] ? ' desc' : '')) as any;

        await this.$odata
            .getList<User>('user', {
                expand: { line: { select: ['name'] }, userGroups: { expand: { group: { select: ['name'] } } } },
                top: top,
                count: true,
                orderBy,
                skip,
                filter: filter,
            })
            .then((data) => (this.users = data.data))
            .finally(() => {
                this.isLoading = false;
                this.refreshTime = 0;
                this.$nextTick(() => {
                    this.refreshTime = 5 * 60;

                    localStorage.setItem('user_itemsPerPage', this.options.itemsPerPage.toString());
                });
            });
    }

    onFilterSubmit(): void {
        this.selected = [];
        this.fetchData();
    }

    rowClick(e: User): void {
        if (!e.isAnonymized) {
            this.$router.push({ name: 'user.detail', params: { id: e.id.toString() } });
        } else {
            alert('Uživatel byl anonymizován');
        }
    }
}
