import store from '@/store';

import { Snackbar } from '../models';
import { ModSnackbarMutation } from '../store';

export class SnackbarService {
    success(text: string): void {
        store.commit(ModSnackbarMutation.SHOW_SNACKBAR.toString(), { snackbar: { text, color: 'success' } });
    }

    info(text: string): void {
        store.commit(ModSnackbarMutation.SHOW_SNACKBAR.toString(), { snackbar: { text, color: 'info' } });
    }

    warn(text: string): void {
        store.commit(ModSnackbarMutation.SHOW_SNACKBAR.toString(), { snackbar: { text, color: 'warning' } });
    }

    error(text: string): void {
        store.commit(ModSnackbarMutation.SHOW_SNACKBAR.toString(), { snackbar: { text, color: 'error' } });
    }

    showSnackbar(snackbar: Snackbar): void {
        store.commit(ModSnackbarMutation.SHOW_SNACKBAR.toString(), { snackbar });
    }
}
