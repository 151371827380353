import { GetterTree } from 'vuex';

import { Modules } from '@/modules/modules';
import { AlertState } from './alert.state';

export enum AlertGetter {
    ALERT = 'ALERT',
}

export enum ModAlertGetter {
    ALERT = Modules.ALERT + '/' + AlertGetter.ALERT,
}

export const alertGetters: GetterTree<AlertState, any> = {
    [AlertGetter.ALERT]: (state: AlertState) => {
        return state.alert;
    },
};
