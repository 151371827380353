















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CmsNumberFieldComponent extends Vue {
    @Prop() value!: number;
    @Prop({ type: Boolean }) readonly!: boolean;
    @Prop({ default: true, type: Boolean }) dense!: boolean;
    @Prop({ default: true, type: Boolean }) outlined!: boolean;
    @Prop() label!: string;
    @Prop() suffix!: string;
    @Prop() prefix!: string;
    @Prop({ type: Boolean }) clearable!: boolean;
    @Prop({ type: Boolean }) required!: boolean;
    @Prop({ type: Boolean }) requiredIndicator!: boolean;
    @Prop({ default: 'red' }) requiredIndicatorColor!: string;
    @Prop({ default: false }) hideDetails!: boolean | string;
    @Prop() hint!: string;

    @Prop() placeholder!: string;

    @Prop({ default: () => [] }) rules!: any[];

    @Prop() props!: any;

    get ruleset(): any[] {
        const result = [...this.rules];

        if (this.required) result.push((v: any) => !!v || this.$t('Validation.Required'));

        return result;
    }

    get isReadonly(): boolean {
        return this.readonly;
    }

    get requiredColor(): string {
        if (!this.required && this.requiredIndicator) {
            return this.requiredIndicatorColor;
        }

        return 'red';
    }

    onChangeInput(val: string): void {
        this.$emit('input', this.getNumber(val));
    }

    get userFormat(): string | null {
        if (this.value != null && this.value != undefined && String(this.value) != '' && !isNaN(+this.value)) {
            return new Intl.NumberFormat(this.$i18n.locale).format(this.value);
        }

        return null;
    }

    reverseFormatNumber(val: string, locale: string): number | string {
        const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
        const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
        let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
        reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
        return Number.isNaN(reversedVal) ? 0 : reversedVal;
    }

    getNumber(val: string): number | null {
        if (val != null && val != undefined && val != '') {
            const number = +this.reverseFormatNumber(val, this.$i18n.locale);

            if (!isNaN(number)) {
                return number;
            }
        }

        return null;
    }
}
