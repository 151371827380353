























































































































































import { Component, Ref, Vue } from 'vue-property-decorator';
import { VForm } from '@/models/vuetify';
import authService from '@/modules/auth/services/auth.service';
import { ModAuthAction } from '@/modules/auth/store';
import { AxiosError } from 'axios';
import LanguageComponent from '@/ui/components/langugage.component.vue';
import { Group } from '@/models/group';
import HelpComponent from '@/ui/components/help/help.component.vue';

@Component({
    components: {
        'c-lang': LanguageComponent,
        'c-help': HelpComponent,
    },
})
export default class LoginPage extends Vue {
    @Ref('form') form!: VForm;
    @Ref('requestForm') requestForm!: VForm;

    userName = '';
    password = '';

    error = false;
    serverError = false;
    isLoading = false;
    valid = true;
    notAllowed = false;
    accessDisabled = false;

    blockTime: Date | null = null;

    allowedDialog = false;

    groupLoading = false;
    groups: Group[] = [];
    groupHeaders = [{ text: this.$t('Page.Auth.Login.Group'), value: 'appName' }];
    groupSelected: Group[] = [];

    gdpr = false;
    gdprAdmin = false;

    showGdrp = true;
    showGdrpAdmin = true;
    gdprAdminFile = '';
    gdprAdminFileClick = false;

    helpEmail = '';
    helpSubject = '';

    sendRequestAccessLoading = false;

    helpDialog = false;

    result: any | null = null;

    mounted(): void {
        this.$odata.getList<any>('adminSetting', { select: ['key', 'value'] }).then((settings) => {
            this.showGdrp = settings.data.value.find((x) => x.key == 'LOGIN_GDPR')?.value.toLowerCase() == 'true';
            this.showGdrpAdmin = settings.data.value.find((x) => x.key == 'LOGIN_GDPR_ADMIN')?.value.toLowerCase() == 'true';
            this.gdprAdminFile = settings.data.value.find((x) => x.key == 'LOGIN_GDPR_ADMIN_PDF')?.value;

            this.helpEmail = settings.data.value.find((x) => x.key == 'HELP_EMAIL')?.value;
            this.helpSubject = settings.data.value.find((x) => x.key == 'HELP_SUBJECT')?.value;
        });
    }

    validate(e: boolean): void {
        this.valid = e;
    }

    async submit(): Promise<void> {
        if (
            this.form.validate() &&
            (this.$store.getters.AppStage === 'PROD' ||
                confirm('Přihlašujete se na testovácí verzi.\nProdukční verze: https://nclist.by00108vmx.by.cz.conti.de/'))
        ) {
            this.error = false;
            this.serverError = false;
            this.isLoading = true;
            this.notAllowed = false;
            this.accessDisabled = false;
            this.blockTime = null;

            try {
                var result = await authService.login(this.userName, this.password);

                if (result.data.active && result.data.allowed && !result.data.blockTime) {
                    this.$store.dispatch(ModAuthAction.LOGIN.toString(), result.data);
                } else if (!result.data.active) {
                    this.accessDisabled = true;
                } else if (!result.data.allowed) {
                    this.notAllowed = true;

                    this.result = result.data;
                } else if (result.data.blockTime) {
                    this.blockTime = result.data.blockTime;
                }
            } catch (err) {
                const axiosError = err as AxiosError;

                if (axiosError?.response?.status == 401) {
                    this.error = true;
                } else {
                    this.serverError = true;
                }
            } finally {
                this.isLoading = false;
            }
        }
    }

    postMessage(): void {
        if (this.result && this.result.active && !this.result.allowed) {
            this.groupLoading = true;

            this.$odata
                .getList<Group>('group', { select: ['id', 'appName'] })
                .then((groups) => {
                    this.groups = groups.data.value;
                })
                .finally(() => (this.groupLoading = false));

            this.gdpr = false;
            this.gdprAdmin = false;
            this.gdprAdminFileClick = false;

            this.$nextTick(() => (this.allowedDialog = true));
        }
    }

    sendRequest(): void {
        if (this.requestForm.validate()) {
            if (this.groupSelected.map((m) => m.id).length > 0) {
                this.sendRequestAccessLoading = true;
                this.$odata
                    .function('user', {
                        key: this.result.userId,
                        func: { RequestAlloweAccess: { groupIds: this.groupSelected.map((m) => m.id) } },
                    })
                    .then(() => {
                        this.$snackbar.success(this.$t('Page.Auth.Login.MessageSend').toString());
                    })
                    .catch(() => {
                        this.$snackbar.error('Nastal problém při odesílání žádosti.');
                    })
                    .finally(() => {
                        this.sendRequestAccessLoading = false;

                        this.result = null;
                        this.allowedDialog = false;
                        this.notAllowed = false;

                        this.groupSelected = [];
                    });
            } else {
                this.$snackbar.error('Page.Auth.Login.RoleRequired');
            }
        }
    }

    isAdminSelected(): boolean {
        return !!this.groupSelected.find((f) => f.appName === 'ADMIN');
    }

    gdprAdminClick(): void {
        window.open(location.origin + '/download/' + this.gdprAdminFile, '_blank');
        this.gdprAdminFileClick = true;
    }
}
