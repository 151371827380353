





















import { Line } from '@/models/line';
import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import { VForm } from '@/models/vuetify';

@Component
export default class LineDetailPage extends Vue {
    line: Line = {} as Line;
    isNew = false;

    @Ref('lineParamForm') lineParamForm!: VForm;

    @Watch('$route.params.id', { immediate: true })
    onChangeUrlParam(id: number): void {
        this.isNew = id > 0 ? false : true;

        if (!this.isNew) {
            this.fetchLineParam(id);
        }
    }

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-slot-machine-outline');
        this.$store.commit('SetTitle', this.$t('Page.Admin.LineParam.Detail.Title'));
    }

    async fetchLineParam(id: number): Promise<void> {
        await this.$odata.getById<Line>('line', +id).then((data) => {
            this.line = data.data;
        });
    }

    submitForm(): void {
        if (this.isNew) {
            this.createLineParam();
        } else {
            this.updateLineParam();
        }
    }

    createLineParam(): void {
        this.$odata.create<Line>('line', this.line).then(() => {
            this.$snackbar.success(this.$t('Page.Admin.LineParam.Detail.Message.Add').toString());
            this.$router.push({ name: 'line.list' });
        });
    }

    updateLineParam(): void {
        this.$odata.update<Line>('line', this.line.id, this.line).then(() => {
            this.$snackbar.success(this.$t('Page.Admin.LineParam.Detail.Message.Update').toString());
            this.$router.push({ name: 'line.list' });
        });
    }
}
