import { AxiosResponse } from 'axios';
import { AuthResponse } from '../models';

import { BaseApiService } from '@/services/base/base-api.service';

export class AuthService extends BaseApiService {
    login(username: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return this.post<AuthResponse>('api/auth/login', {
            username,
            password,
        });
    }

    logout(token: string): Promise<AxiosResponse> {
        return this.post('api/auth/logout', {
            token,
        });
    }

    refresh(): Promise<AxiosResponse<AuthResponse>> {
        return this.get<AuthResponse>('api/auth/refresh');
    }
}

const authService = new AuthService();
export default authService;
