import { IncompleteLineProduct } from '@/models';

export function getNullProperties(ilp: IncompleteLineProduct): string[] {
    const nullProperties: string[] = [];
    if (!ilp.productSmtNumber) {
        nullProperties.push('ProductSmtNumber');
    }
    if (!ilp.lineId) {
        nullProperties.push('LineId');
    }
    if (!ilp.productName) {
        nullProperties.push('ProductName');
    }
    if (!ilp.productSide) {
        nullProperties.push('ProductSide');
    }
    if (!ilp.dpsNumber) {
        nullProperties.push('DpsNumber');
    }
    if (!ilp.productThtNumber) {
        nullProperties.push('ProductThtNumber');
    }
    if (!ilp.setupName) {
        nullProperties.push('SetupName');
    }
    if (!ilp.dekPaste) {
        nullProperties.push('DekPaste');
    }
    if (!ilp.dekSqueegee) {
        nullProperties.push('DekSqueegee');
    }
    if (!ilp.dekTemplate) {
        nullProperties.push('DekTemplate');
    }
    if (ilp.dekKohYoungSupport == null) {
        nullProperties.push('DekKohYoungSupport');
    }

    return nullProperties;
}

export function allowConvert(e: IncompleteLineProduct): boolean {
    return !getNullProperties(e).length;
}
