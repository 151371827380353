





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { LineProduct } from '@/models';

import DekHeaderComponent from '@/ui/components/operator/headers/header-dek.component.vue';
import VueI18n from 'vue-i18n';
import { DataTableHeader } from 'vuetify';

@Component({
    components: {
        'c-header-dek': DekHeaderComponent,
    },
})
export default class PdfCustomLineProductComponent extends Vue {
    @Prop({ required: true }) items!: LineProduct[];

    @Prop({ required: true }) headers!: any[];

    packs: any[] = [];

    @Watch('items', { immediate: true })
    itemsPack(): void {
        this.packs = [];
        let pack: LineProduct[] = [];
        this.items.forEach((item, index) => {
            if (index > 0 && index % 25 == 0) {
                this.packs.push(pack);
                pack = [];
            }

            pack.push(item);

            if (index == this.items.length - 1) {
                this.packs.push(pack);
            }
        });
    }

    getHeaderText(header: DataTableHeader): VueI18n.TranslateResult {
        if (header.value == 'isActive') return this.$t('Entity.LineProduct.IsActiveShort');
        if (header.value == 'isReleased') return this.$t('Entity.LineProduct.IsReleasedShort');

        return header.text;
    }

    getValue(item: LineProduct, value: string): any {
        if (value == 'isActive') return item[value] ? ' A ' : ' N ';
        if (value == 'isReleased') return item[value] ? ' U ' : ' N ';

        value.split('.').forEach((i) => (item = item && item[i] ? item[i] : null));
        return item;
    }
}
