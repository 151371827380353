


























import { LineProductAddInfo } from '@/models';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LineProductInfoComponent extends Vue {
    @Prop({ default: () => [] }) addInfos!: LineProductAddInfo[];
}
