import { RouteConfig } from 'vue-router';

import AlertListPage from '@/ui/pages/admin/alert/alert-list.page.vue';
import AlertDetailPage from '@/ui/pages/admin/alert/alert-detail.page.vue';

export const ALERT_ROUTES: RouteConfig[] = [
    {
        path: '',
        component: AlertListPage,
        name: 'alert.list',
    },
    {
        path: 'new',
        component: AlertDetailPage,
        name: 'alert.new',
    },
    {
        path: ':id',
        component: AlertDetailPage,
        name: 'alert.detail',
    },
    {
        path: '*',
        redirect: 'alert.list',
    },
];
