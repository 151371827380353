

































































































































































































































































































































































import { IncompleteLineProduct, IncompleteLineProductLocked } from '@/models';
import { VForm } from '@/models/vuetify';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import IncompleteLineProductLockedComponent from '@/ui/components/incomplete-line-product-locked.component.vue';
import axios from 'axios';
import { Line } from '@/models/line';
import { max } from 'lodash';
import { Route } from 'vue-router';
import { resizableGrid } from '@/helpers/resizable-grid';
import IlpConvertInfo from '@/ui/components/incomplete-line-product-convert-info.component.vue';
import IlpHelperInfo from '@/ui/components/incomplete-line-product-helper.component.vue';

Component.registerHooks(['beforeRouteLeave']);

@Component({
    components: {
        'c-incomplete-line-product-locked': IncompleteLineProductLockedComponent,
        'c-ilp-convert-info': IlpConvertInfo,
        'c-ilp-helper': IlpHelperInfo,
    },
})
export default class IPEditDekPage extends Vue {
    @Ref('form') form!: VForm;

    changed = false;

    fields = [
        'productSmtNumber',
        'lineId',
        'productName',
        'dpsNumber',
        'dekProgram',
        'dekPaste',
        'dekSqueegee',
        'dekTemplate',
        // 'dekTemplateThick',
        'dekTooling',
        'dekKohYoungAoi',
        'dekKohYoungSupport',
        // 'dekToolingPos',
    ];

    lastFocused = { index: 0, field: 'productSmtNumber' };

    dialogClipboard = false;
    dialogLocked = false;
    clipboardText = '';

    lines: Line[] = [];

    data: IncompleteLineProduct[] = [];
    lockedItems: IncompleteLineProductLocked[] = [];

    get clipboard(): boolean {
        return !!navigator.clipboard;
    }

    @Watch('data', { deep: true })
    onChangeData(): void {
        this.changed = true;
    }

    submit(): void {
        if (this.form.validate()) {
            this.save().then(() => {
                this.$snackbar.success('Global.DataSaved');
                this.changed = false;
            });
        }
    }

    save(): Promise<any> {
        return this.$odata.action('incompleteLineProduct', this.data, { func: 'SaveDek' });
    }

    created(): void {
        window.addEventListener('beforeunload', this.leaving);
    }

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-clipboard-edit-outline');
        this.$store.commit('SetTitle', this.$t('Global.DekKohYoung'));

        this.$odata.getList<Line>('line', { orderBy: ['name'] }).then((a) => (this.lines = a.data.value));

        const sIds = sessionStorage.getItem('ip-edit');
        if (sIds) {
            const ids = sIds
                .split(';')
                .filter((f) => !!f)
                .map((m) => +m);

            this.$odata
                .function<any>('incompleteLineProduct', { func: { StartEdit: { ids } }, expand: { locked: {} } })
                .then((res) => {
                    this.lockedItems = res.data.locked ?? [];
                    this.dialogLocked = this.lockedItems.length > 0;
                    return this.$odata.getList<IncompleteLineProduct>('incompleteLineProduct', {
                        filter: {
                            id: {
                                in: res.data.free,
                            },
                        },
                        orderBy: 'productSmtNumber',
                    });
                })
                .then((a) => {
                    this.data = a.data.value;

                    this.$nextTick(() => {
                        this.changed = false;
                        resizableGrid((this.$refs.table as any).$el);
                    });
                });
        }
    }

    async importFromClipboard(): Promise<void> {
        try {
            await navigator.clipboard.readText().then((a) => {
                if (a.trim()) {
                    this.parseFromClipboard(a);
                }
            });
        } catch {
            this.dialogClipboard = true;
            this.clipboardText = '';
        }
    }

    importovat(): void {
        this.parseFromClipboard(this.clipboardText);
        this.dialogClipboard = false;
    }

    parseFromClipboard(text: string): void {
        if (!text) {
            return;
        }

        let index = this.lastFocused.index;

        let lines = text.split('\n');
        lines = lines.filter((f, index) => index < lines.length - 1 || f);

        lines.forEach((line) => {
            var lineSplit = line.split('\t');
            const maxCols = max(lines.map((m) => m.split('\t').length));

            if (this.data.length > index) {
                let i = 0;
                const fieldIndex = this.fields.indexOf(this.lastFocused.field);

                this.fields.forEach((f) => {
                    if (fieldIndex <= this.fields.indexOf(f)) {
                        if (lineSplit.length >= i + 1) {
                            if (f === 'dekToolingPos') {
                                this.data[index][f] = isNaN(+lineSplit[i]) ? null : +lineSplit[i];
                            } else if (f === 'lineId') {
                                const lineName = lineSplit[i++].toLowerCase().trim();
                                this.data[index][f] = this.lines.find((f) => f.name.toLowerCase().trim() == lineName)?.id;
                            } else {
                                this.data[index][f] = lineSplit[i++]?.trim();
                            }
                        } else {
                            if ((maxCols || 0) > i) {
                                this.data[index][f] = null;
                                i++;
                            }
                        }
                    }
                });

                index++;
            } else {
                return;
            }
        });
    }

    loadingMesData = false;
    loadMesData(): void {
        this.loadingMesData = true;

        axios
            .get(this.$store.getters.DataUrl + '/getEvaProdInfo?' + this.data.map((m) => 'smtNum=' + m.productSmtNumber).join('&'))
            .then((a) => {
                var data = a.data;
                data.forEach((item) => {
                    const i = this.data.find((f) => f.productSmtNumber == item.lcSmtNumber);
                    if (i) {
                        if (!i.lineId) i.lineId = this.lines.find((f) => f.name.trim() == item.lcLineName?.trim())?.id;
                        if (!i.productName && item.lcProductName != 'Nelze zjistit') i.productName = item.lcProductName;
                        if (!i.dpsNumber && item.dpsNumber != 'Nelze zjistit') i.dpsNumber = item.dpsNumber;
                        if (!i.dekSqueegee && item.dekSqueegee != 'Nelze zjistit') i.dekSqueegee = item.dekSqueegee;
                        if (!i.dekProgram && item.dekProgramName != 'Nelze zjistit') i.dekProgram = item.dekProgramName;
                        if (!i.dekPaste && item.dekPaste != 'Nelze zjistit') i.dekPaste = item.dekPaste;
                        if (!i.dekTemplate && item.dekTemplate != 'Nelze zjistit') i.dekTemplate = item.dekTemplate;
                        // if (!i.dekTemplateThick && item.dekTemplateThick != 'Nelze zjistit') i.dekTemplateThick = item.dekTemplateThick;
                        if (!i.dekTooling && item.dekTooling != 'Nelze zjistit') i.dekTooling = item.dekTooling;
                        if (!i.dekKohYoungAoi && item.aoiProgramName != 'Nelze zjistit') i.dekKohYoungAoi = item.aoiProgramName;
                    }
                });

                this.$forceUpdate();
            })
            .finally(() => {
                this.loadingMesData = false;
            });
    }

    destroyed(): void {
        this.leaving();
    }

    leaving(): void {
        if (this.data) {
            const ids = this.data.map((m) => m.id);
            this.$odata.function('incompleteLineProduct', { func: { EndEdit: { ids } } });
        }
    }

    focus(index: number, field: string): void {
        this.lastFocused.index = index;
        this.lastFocused.field = field;
    }

    beforeRouteLeave(_to: Route, _from: Route, next: any): void {
        if (this.changed) {
            this.$root
                .$confirm('Global.NotSavedChanges', 'Global.NotSavedChangesConfirm', {
                    type: 'warning',
                    confirmText: 'Global.Yes',
                    confirmColor: 'red',
                    cancelText: 'Global.No',
                    cancelColor: 'primary',
                })
                .then((res) => {
                    if (res) {
                        next();
                    } else {
                        next(false);
                    }
                });

            return;
        }

        next();
    }

    convertLoading = false;
    forConvert: number[] = [];
    exists: any[] = [];
    dialogConvertValidation = false;
    convertSelected: any[] = [];

    get canConvert(): boolean {
        return this.data.filter((f) => this.allowConvert(f)).length === this.data.length;
    }

    allowConvert(e: IncompleteLineProduct): boolean {
        return !!e.lineId && !!e.productName && !!e.productSide && !!e.dpsNumber && !!e.productThtNumber && !!e.setupName;
    }

    beforeConvert(): void {
        if (this.form.validate() && confirm(this.$t('Page.Pe.Ilp.List.Alerts.Convert').toString())) {
            this.save().then(() => {
                this.$snackbar.success('Global.DataSaved');
                this.changed = false;

                this.convertLoading = true;

                this.$nextTick(() => {
                    this.$odata
                        .function<any>('incompleteLineProduct', { func: { BeforeEdit: { ids: this.data.map((m) => m.id) } } })
                        .then((data) => {
                            this.lockedItems = data.data.locked ?? [];
                            if (this.lockedItems.length) {
                                this.dialogLocked = true;
                            } else {
                                this.convertValidation();
                            }
                        });
                });
            });
        }
    }

    convertValidation(): void {
        const locketIds = this.lockedItems.map((m) => m.id);
        const ids = this.data.filter((f) => locketIds.indexOf(f.id) == -1).map((m) => m.id);

        if (ids.length) {
            this.$odata.function<any>('incompleteLineProduct', { func: { convertValidation: { ids } } }).then((d) => {
                if (d.data.exists.length) {
                    this.exists = [...d.data.exists];
                    this.convertSelected = [...d.data.exists];
                    this.forConvert = d.data.free;

                    this.dialogConvertValidation = true;
                } else {
                    this.forConvert = d.data.free;
                    this.convert();
                }
            });
        } else {
            this.forConvert = [];
            this.convert();
        }
    }

    convertConfirm(): void {
        this.convertSelected.forEach((element) => {
            this.forConvert.push(element.ilp);
        });

        this.dialogConvertValidation = false;
        this.convert();
    }

    convert(): void {
        this.$odata
            .function('incompleteLineProduct', { func: { convert: { ids: this.data.map((m) => m.id) } } })
            .then(() => {
                this.$snackbar.success('Page.Pe.Ilp.List.Messages.Converted');

                this.$nextTick(() => {
                    sessionStorage.removeItem('ip-edit');
                    this.$router.push({ name: 'ip.list', query: { tab: 'dek' } });
                });
            })
            .finally(() => {
                this.convertLoading = false;
            });
    }

    keyUp(e: KeyboardEvent, index: number, key: string): void {
        if (key && e.ctrlKey) {
            switch (e.key) {
                case 'ArrowLeft': {
                    let prev = this.fields.indexOf(key) - 1;
                    if (this.fields[prev] == 'lineId') {
                        prev--;
                    }

                    if (prev >= 0) {
                        this.focusInput(index, this.fields[prev]);
                    }
                    break;
                }

                case 'ArrowRight': {
                    let next = this.fields.indexOf(key) + 1;
                    if (this.fields[next] == 'lineId') {
                        next++;
                    }

                    if (next < this.fields.length) {
                        this.focusInput(index, this.fields[next]);
                    }
                    break;
                }
                case 'ArrowUp': {
                    const newIndex = index - 1;
                    if (newIndex >= 0) {
                        this.focusInput(newIndex, key);
                    }
                    break;
                }

                case 'ArrowDown': {
                    const newIndex = index + 1;
                    if (this.data.length > index + 1) {
                        this.focusInput(newIndex, key);
                    }
                    break;
                }
            }
        }

        // if (e.ctrlKey && e.key == 'v') {
        //     this.$nextTick(this.importFromClipboard);
        // }
    }

    focusInput(index: number, key: string): void {
        const input = this.$refs[index + '_' + key][0].$children[0];
        input.focus();

        this.$nextTick(() => {
            input.$el.querySelector('input').select();
            this.focus(index, key);
        });
    }
}
