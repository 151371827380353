import { formatDate, formatNumber, isBooleanTrue, lowerUnaccent } from '@/helpers';
import { ModAuthGetter } from '@/modules/auth/store';

import { SnackbarService } from '@/modules/snackbar/services';
import { ODataService } from '@/services/odata.service';
import store from '@/store';

const vuePrototype = {
    install: (Vue): void => {
        Vue.prototype.$snackbar = new SnackbarService();
        Vue.prototype.$odata = new ODataService();
        Vue.prototype.$formatDate = formatDate;
        Vue.prototype.$formatNumber = formatNumber;
        Vue.prototype.$lowerUnaccent = lowerUnaccent;
        Vue.prototype.$isBooleanTrue = isBooleanTrue;
        Vue.prototype.$isBoolean = (val: any) => val === true || val === false;
        Vue.prototype.$inGroup = (groups: string | string[]) => store.getters[ModAuthGetter.IN_GROUP](groups);
    },
};

export default vuePrototype;
