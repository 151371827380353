







































































import { Jidoka } from '@/models/jidoka';
import { Line } from '@/models/line';
import { ODataResult } from '@/models/odata.result';
import { Station } from '@/models/station';
import { User } from '@/models/user';
import { GridOptions } from '@/models/vuetify';
import { Modules } from '@/modules';
import { AuthGetter } from '@/modules/auth/store';
import { debounce } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import JidokaHelpDialog from '../jidoka/help.dialog.vue';
import RefresherComponent from '../refresher.component.vue';

@Component({
    computed: {
        ...mapGetters(Modules.AUTH, [AuthGetter.USER]),
    },
    components: {
        'c-refresher': RefresherComponent,
        'c-jidoka-help': JidokaHelpDialog,
    },
})
export default class OperatorJidokaTab extends Vue {
    USER!: User;
    lines: Line[] = [];
    stations: Station[] = [];

    data: ODataResult<Jidoka> = { value: [], '@odata.count': 0 };

    isLoading = false;
    filter: { lines: number[]; stations?: number[]; currentRule?: string } = { lines: [], stations: [], currentRule: '' };
    lastUpdate: Date | null = null;
    refreshTime = 0;

    headers = [
        {
            value: 'lastUpdate',
            text: this.$t('Entity.Jidoka.LastUpdate'),
            class: 'light-green--text subtitle-2',
        },
        {
            value: 'line.name',
            text: this.$t('Entity.Jidoka.Line.Name'),
            class: 'light-green--text subtitle-2',
            isLine: true,
        },
        {
            value: 'station.name',
            text: this.$t('Entity.Jidoka.Station.Name'),
            class: 'light-green--text subtitle-2',
        },
        {
            value: 'currentRule',
            text: this.$t('Entity.Jidoka.CurrentRule'),
            class: 'light-green--text subtitle-2',
        },
        {
            value: 'targetFPY',
            text: this.$t('Entity.Jidoka.TargetFPY'),
            class: 'light-green--text subtitle-2',
        },
        {
            value: 'lastUpdateUser.displayName',
            text: this.$t('Entity.Jidoka.LastUpdateUser'),
            class: 'light-green--text subtitle-2',
        },
    ];

    get currHeaders(): any[] {
        if (this.USER.lineId) {
            return this.headers.filter((f) => !f.isLine);
        }

        return this.headers;
    }

    options: GridOptions = {
        page: 1,
        itemsPerPage: +(localStorage.getItem('jidoka_itemsPerPage') || 20),
        sortBy: [],
        sortDesc: [],
        multiSort: true,
        mustSort: false,
    };

    changeDebounce!: () => void;
    created(): void {
        this.changeDebounce = debounce(() => {
            if (this.options.page != 1) {
                this.options.page = 1;
            } else {
                this.fetchData();
            }
        }, 400);
    }

    mounted(): void {
        this.$odata
            .getList<Line>('line', { orderBy: ['name'], filter: { isActive: { eq: true } } })
            .then((a) => (this.lines = a.data.value));

        this.$odata
            .getList<Station>('station', { orderBy: ['name'], filter: { isDeleted: { eq: false } } })
            .then((a) => (this.stations = a.data.value));
    }

    fetchData(): void {
        this.isLoading = true;

        if (isNaN(+this.options.itemsPerPage) || !this.options.itemsPerPage) {
            this.options.itemsPerPage = 20;
        }

        this.isLoading = true;

        if (isNaN(+this.options.itemsPerPage) || !this.options.itemsPerPage) {
            this.options.itemsPerPage = 20;
        }

        const filter: any[] = [];
        filter.push({ isDeleted: { eq: false } });
        if (this.USER.lineId) filter.push({ lineId: { eq: this.USER.lineId } });
        if (this.filter.lines && this.filter.lines.length) filter.push({ lineId: { in: this.filter.lines } });
        if (this.filter.stations && this.filter.stations.length) filter.push({ stationId: { in: this.filter.stations } });
        if (this.filter.currentRule?.trim()) filter.push({ 'toLower(currentRule)': { contains: this.filter.currentRule.toLowerCase() } });

        const top = this.options.itemsPerPage;
        const skip = (this.options.page - 1) * this.options.itemsPerPage;
        const orderBy = this.options.sortBy.map(
            (sort, index) => sort.replaceAll('.', '/') + (this.options.sortDesc[index] ? ' desc' : '')
        ) as any;

        this.$odata
            .getList<Jidoka>('jidoka', {
                top,
                skip,
                filter,
                orderBy,
                count: true,
                expand: {
                    line: { select: ['id', 'name'] },
                    station: { select: ['id', 'name'] },
                    lastUpdateUser: { select: ['id', 'displayName'] },
                },
            })
            .then((a) => {
                this.data = a.data;
            })
            .finally(() => {
                this.isLoading = false;
                this.lastUpdate = new Date();

                this.refreshTime = 0;
                this.$nextTick(() => {
                    this.refreshTime = 5 * 60;

                    localStorage.setItem('op_jidoka_itemsPerPage', this.options.itemsPerPage.toString());
                });
                this.isLoading = false;
            });
    }
}
