

























































import { LineProduct } from '@/models';
import { Jidoka } from '@/models/jidoka';
import { User } from '@/models/user';
import { Modules } from '@/modules';
import { AuthGetter } from '@/modules/auth/store';
import OperatorAsysTab from '@/ui/components/operator/asys.tab.vue';
import OperatorCustomTab from '@/ui/components/operator/custom.tab.vue';
import OperatorDekTab from '@/ui/components/operator/dek.tab.vue';
import OperatorSiplaceTab from '@/ui/components/operator/siplace.tab.vue';
import OperatorJidokaTab from '@/ui/components/operator/jidoka.tab.vue';
import { Filter } from 'odata-query';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { isSameDay } from 'date-fns';

@Component({
    computed: {
        ...mapGetters(Modules.AUTH, [AuthGetter.USER]),
    },
    components: {
        'c-tab-siplace': OperatorSiplaceTab,
        'c-tab-dek': OperatorDekTab,
        'c-tab-asys': OperatorAsysTab,
        'c-tab-custom': OperatorCustomTab,
        'c-tab-jidoka': OperatorJidokaTab,
    },
})
export default class SheetPage extends Vue {
    tab = 0;

    USER!: User;

    inFilter: any = {};
    tabNames = { [0]: 'siplace', [1]: 'dek', [2]: 'asys', [3]: 'custom', [4]: 'jidoka' };
    onlySelected: { onlySelected: boolean; selected: LineProduct[] } = { onlySelected: false, selected: [] };

    jidokaInterval?: number;
    jidokaLastUpdate: Date | string = '';

    get jidokaSameDay(): boolean {
        if (!this.jidokaLastUpdate) return false;

        return isSameDay(new Date(), new Date(this.jidokaLastUpdate));
    }

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-clipboard-list-outline');
        this.$store.commit('SetTitle', this.$t('Page.Sheets.Operator.Title'));

        this.fetchJidoka();
        this.jidokaInterval = setInterval(this.fetchJidoka, 1000 * 60 * 10);
    }

    @Watch('tab')
    onChangeTab(): void {
        if (this.$route.query.tab != this.tabNames[this.tab]) {
            this.$router.replace({ query: { ...this.$route.query, tab: this.tabNames[this.tab] } });
        }
    }

    @Watch('$route.query.tab', { immediate: true })
    onChangeQueryTab(): void {
        Object.keys(this.tabNames).forEach((key) => {
            if (this.$route.query.tab === this.tabNames[key]) {
                this.tab = +key;
                return;
            }
        });
    }

    onlySelectedChange(e: { onlySelected: boolean; selected: LineProduct[] }): void {
        this.onlySelected = e;
    }

    fetchJidoka(): void {
        const filter: Filter = [{ isDeleted: { eq: false } }];
        if (this.USER.lineId) {
            filter.push({ lineId: this.USER.lineId });
        }
        this.$odata
            .getList<Jidoka>('jidoka', {
                select: ['lastUpdate'],
                top: 1,
                orderBy: [['lastUpdate', 'desc']],
                filter: filter,
            })
            .then((res) => (this.jidokaLastUpdate = res.data.value?.[0]?.lastUpdate ?? null));
    }

    destroy(): void {
        if (this.jidokaInterval != null) {
            clearInterval(this.jidokaInterval);
        }
    }
}
