

































































































































































































































































































































































import IncompleteLineProductAddInfoComponent from '@/ui/components/incomplete-line-product-info.component.vue';
import { IncompleteLineProduct, IncompleteLineProductLocked } from '@/models';
import { VForm } from '@/models/vuetify';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import axios from 'axios';
import { Line } from '@/models/line';
import { TranslateResult } from 'vue-i18n';
import { max } from 'lodash';
import { Route } from 'vue-router';
import { resizableGrid } from '@/helpers/resizable-grid';
import IlpConvertInfo from '@/ui/components/incomplete-line-product-convert-info.component.vue';
import IlpHelperInfo from '@/ui/components/incomplete-line-product-helper.component.vue';

import { allowConvert } from '@/helpers/ilp';

Component.registerHooks(['beforeRouteLeave']);

@Component({
    components: {
        'c-incomplete-line-product-locked': IncompleteLineProductAddInfoComponent,
        'c-ilp-convert-info': IlpConvertInfo,
        'c-ilp-helper': IlpHelperInfo,
    },
})
export default class IPEditSiplacePage extends Vue {
    @Ref('form') form!: VForm;

    changed = false;

    fields = [
        'productSmtNumber',
        'lineId',
        'productName',
        'productSide',
        'dpsNumber',
        'productThtNumber',
        'setupName',
        'productLaserProg',
        'productOvenProg',
        'norm',
    ];

    dialogClipboard = false;
    dialogLocked = false;
    clipboardText = '';

    data: IncompleteLineProduct[] = [];

    item: IncompleteLineProduct | null = null;

    lockedItems: IncompleteLineProductLocked[] = [];

    lines: Line[] = [];

    lastFocused: {
        index: number;
        field: string;
    } = {
        index: 0,
        field: 'productSmtNumber',
    };

    get clipboard(): boolean {
        return !!navigator.clipboard;
    }

    productSideRules = [
        (v: string): TranslateResult | boolean => !v || v === 'Strana 1' || v === 'Strana 2' || this.$t('Validation.ProductSide'),
    ];

    @Watch('data', { deep: true })
    onChangeData(): void {
        this.changed = true;
    }

    submit(): void {
        if (this.form.validate()) {
            this.save().then(() => {
                this.$snackbar.success('Global.DataSaved');
                this.changed = false;
            });
        }
    }

    save(): Promise<any> {
        return this.$odata.action('incompleteLineProduct', this.data, { func: 'SaveSiplace' });
    }

    created(): void {
        window.addEventListener('beforeunload', this.leaving);
    }

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-clipboard-edit-outline');
        this.$store.commit('SetTitle', this.$t('Global.SiplaceRehmLaser'));

        this.$odata.getList<Line>('line', { orderBy: ['name'] }).then((a) => (this.lines = a.data.value));

        const sIds = sessionStorage.getItem('ip-edit');
        if (sIds) {
            const ids = sIds
                .split(';')
                .filter((f) => !!f)
                .map((m) => +m);

            this.$odata
                .function<any>('incompleteLineProduct', { func: { StartEdit: { ids } }, expand: { locked: {} } })
                .then((res) => {
                    this.lockedItems = res.data.locked ?? [];
                    this.dialogLocked = this.lockedItems.length > 0;

                    return this.$odata.getList<IncompleteLineProduct>('incompleteLineProduct', {
                        expand: { addInfos: {} },
                        filter: {
                            id: {
                                in: res.data.free,
                            },
                        },
                        orderBy: 'productSmtNumber',
                    });
                })
                .then((a) => {
                    this.data = a.data.value;
                    this.$nextTick(() => {
                        this.changed = false;

                        resizableGrid((this.$refs.table as any).$el);
                    });
                });
        }
    }

    async importFromClipboard(): Promise<void> {
        try {
            await navigator.clipboard.readText().then((a) => {
                if (a.trim()) {
                    this.parseFromClipboard(a);
                }
            });
        } catch {
            this.dialogClipboard = true;
            this.clipboardText = '';
        }
    }

    importovat(): void {
        this.parseFromClipboard(this.clipboardText);
        this.dialogClipboard = false;
    }

    parseFromClipboard(text: string): void {
        if (!text) {
            return;
        }

        let index = this.lastFocused.index;

        let lines = text.split('\n');
        lines = lines.filter((f, index) => index < lines.length - 1 || f);

        lines.forEach((line) => {
            var lineSplit = line.split('\t');

            const maxCols = max(lines.map((m) => m.split('\t').length));

            if (this.data.length > index) {
                let i = 0;

                const fieldIndex = this.fields.indexOf(this.lastFocused.field);
                this.fields.forEach((f) => {
                    if (fieldIndex <= this.fields.indexOf(f)) {
                        if (lineSplit.length >= i + 1) {
                            if (f === 'lineId') {
                                const lineName = lineSplit[i++].toLowerCase().trim();
                                this.data[index][f] = this.lines.find((f) => f.name.toLowerCase().trim() == lineName)?.id;
                            } else {
                                this.data[index][f] = lineSplit[i++]?.trim();
                            }
                        } else {
                            if ((maxCols || 0) > i) {
                                this.data[index][f] = null;
                                i++;
                            }
                        }
                    }
                });

                index++;
            } else {
                return;
            }
        });
    }

    loadingNorm = false;
    loadNormData(): void {
        const validData = this.data.filter((f) => f.lineId);
        if (validData.length) {
            this.loadingNorm = true;

            const lineRegexPattern = '([1-9][0-9]|[1-9])$';
            const regex = new RegExp(lineRegexPattern);

            axios
                .get(
                    this.$store.getters.DataUrl +
                        '/getProductNorm?request=' +
                        validData
                            .map((m) => {
                                const result = regex.exec(this.lines.find((f) => f.id == m.lineId)?.name || '');
                                if (result) {
                                    return 'S' + result[0] + ',' + m.productSmtNumber;
                                } else {
                                    return '';
                                }
                            })
                            .filter((f) => !!f)
                            .join(';')
                )
                .then((a) => {
                    const result: IncompleteLineProduct[] = [];

                    a.data.forEach((element) => {
                        const d = this.data.find((f) => {
                            var line = this.lines.find((l) => l.id == f.lineId);
                            if (line) {
                                var lineRegex = regex.exec(line.name || '');
                                if (lineRegex) {
                                    return f.productSmtNumber == element.object && 'S' + lineRegex[0] == element.destination;
                                }
                            }
                            return false;
                        });
                        if (d) {
                            if (element.returnValue != 'Nelze zjistit') {
                                d.norm = element.returnValue;
                                result.push(d);
                            }
                        }
                    });

                    if (result.length) {
                        this.$snackbar.success('Page.Pe.Ilp.Detail.Messages.NormDataLoaded');
                    } else {
                        this.$snackbar.error('Page.Pe.Ilp.Detail.Messages.NormDataNotLoaded');
                    }

                    this.$forceUpdate();
                })
                .finally(() => (this.loadingNorm = false));
        }
    }

    loadingSetup = false;
    loadSetupData(): void {
        const validData = this.data.filter((f) => f.lineId && f.recipePath);

        if (validData.length) {
            this.loadingSetup = true;
            axios
                .get(
                    this.$store.getters.DataUrl +
                        '/getSetupNames?request=' +
                        validData.map((m) => this.lines.find((f) => f.id == m.lineId)?.location + ',' + m.recipePath).join(';')
                )
                .then((a) => {
                    a.data.forEach((element) => {
                        const d = this.data.find(
                            (f) =>
                                f.lineId == this.lines.find((l) => l.location == element.destination)?.id && f.recipePath == element.object
                        );
                        if (d) {
                            if (element.returnValue != 'Nelze zjistit') d.setupName = element.returnValue;
                        }
                    });

                    this.$snackbar.success('Page.Pe.Ilp.Detail.Messages.SetupDataLoaded');

                    this.$forceUpdate();
                })
                .finally(() => (this.loadingSetup = false));
        }
    }

    loadingMesData = false;
    loadMesData(): void {
        this.loadingMesData = true;

        axios
            .get(this.$store.getters.DataUrl + '/getEvaProdInfo?' + this.data.map((m) => 'smtNum=' + m.productSmtNumber).join('&'))
            .then((a) => {
                var data = a.data;
                data.forEach((item) => {
                    const i = this.data.find((f) => f.productSmtNumber == item.lcSmtNumber);
                    if (i) {
                        if (!i.lineId) i.lineId = this.lines.find((f) => f.name.trim() == item.lcLineName?.trim())?.id;
                        if (!i.productName && item.lcProductName != 'Nelze zjistit') i.productName = item.lcProductName;
                        if (!i.productSide && item.lcProductSide != 'Nelze zjistit') i.productSide = item.lcProductSide;
                        if (!i.dpsNumber && item.dpsNumber != 'Nelze zjistit') i.dpsNumber = item.dpsNumber;
                        if (!i.productThtNumber && item.lcThtNumber != 'Nelze zjistit') i.productThtNumber = item.lcThtNumber;
                        if (!i.setupName && item.lcSetupName != 'Nelze zjistit') i.setupName = item.lcSetupName;
                        if (!i.productLaserProg && item.lcLaserProgram != 'Nelze zjistit') i.productLaserProg = item.lcLaserProgram;
                        if (!i.productOvenProg && item.lcOvenProgram != 'Nelze zjistit') i.productOvenProg = item.lcOvenProgram;
                        if (!i.norm && item.productHourNorm != 'Nelze zjistit') i.norm = item.productHourNorm;
                        if (!i.recipePath && item.siProRcpPath != 'Nelze zjistit') i.recipePath = item.siProRcpPath;
                    }
                });

                this.$snackbar.success('Page.Pe.Ilp.Detail.Messages.MesDataLoaded');

                this.$forceUpdate();
            })
            .finally(() => {
                this.loadingMesData = false;
            });
    }

    dialogRecipe = false;
    openRecipe(item: IncompleteLineProduct): void {
        this.item = item;
        this.dialogRecipe = true;
    }

    destroyed(): void {
        this.leaving();
    }

    leaving(): void {
        if (this.data) {
            const ids = this.data.map((m) => m.id);
            this.$odata.function('incompleteLineProduct', { func: { EndEdit: { ids } } });
        }
    }

    focus(index: number, field: string): void {
        this.lastFocused.index = index;
        this.lastFocused.field = field;
    }

    beforeRouteLeave(_to: Route, _from: Route, next: any): void {
        if (this.changed) {
            this.$root
                .$confirm('Global.NotSavedChanges', 'Global.NotSavedChangesConfirm', {
                    type: 'warning',
                    confirmText: 'Global.Yes',
                    confirmColor: 'red',
                    cancelText: 'Global.No',
                    cancelColor: 'primary',
                })
                .then((res) => {
                    if (res) {
                        next();
                    } else {
                        next(false);
                    }
                });

            return;
        }

        next();
    }

    convertLoading = false;
    forConvert: number[] = [];
    exists: any[] = [];
    dialogConvertValidation = false;
    convertSelected: any[] = [];

    get canConvert(): boolean {
        return this.data.filter((f) => allowConvert(f)).length === this.data.length;
    }

    beforeConvert(): void {
        if (this.form.validate() && confirm(this.$t('Page.Pe.Ilp.List.Alerts.Convert').toString())) {
            this.save().then(() => {
                this.$snackbar.success('Global.DataSaved');
                this.changed = false;

                this.convertLoading = true;

                this.$nextTick(() => {
                    this.$odata
                        .function<any>('incompleteLineProduct', {
                            func: { BeforeEdit: { ids: this.data.map((m) => m.id) } },
                            expand: { locked: {} },
                        })
                        .then((data) => {
                            this.lockedItems = data.data.locked ?? [];
                            if (this.lockedItems.length) {
                                this.dialogLocked = true;
                            } else {
                                this.convertValidation();
                            }
                        });
                });
            });
        }
    }

    convertValidation(): void {
        const locketIds = this.lockedItems.map((m) => m.id);
        const ids = this.data.filter((f) => locketIds.indexOf(f.id) == -1).map((m) => m.id);

        if (ids.length) {
            this.$odata.function<any>('incompleteLineProduct', { func: { convertValidation: { ids } } }).then((d) => {
                if (d.data.exists.length) {
                    this.exists = [...d.data.exists];
                    this.convertSelected = [...d.data.exists];
                    this.forConvert = d.data.free;

                    this.dialogConvertValidation = true;
                } else {
                    this.forConvert = d.data.free;
                    this.convert();
                }
            });
        } else {
            this.forConvert = [];
            this.convert();
        }
    }

    convertConfirm(): void {
        this.convertSelected.forEach((element) => {
            this.forConvert.push(element.ilp);
        });

        this.dialogConvertValidation = false;
        this.convert();
    }

    convert(): void {
        this.$odata
            .function('incompleteLineProduct', { func: { convert: { ids: this.data.map((m) => m.id) } } })
            .then(() => {
                this.$snackbar.success('Page.Pe.Ilp.List.Messages.Converted');

                this.$nextTick(() => {
                    sessionStorage.removeItem('ip-edit');
                    this.$router.push({ name: 'ip.list', query: { tab: 'siplace' } });
                });
            })
            .finally(() => {
                this.convertLoading = false;
            });
    }

    keyUp(e: KeyboardEvent, index: number, key: string): void {
        if (key && e.ctrlKey) {
            switch (e.key) {
                case 'ArrowLeft': {
                    let prev = this.fields.indexOf(key) - 1;
                    if (this.fields[prev] == 'lineId') {
                        prev--;
                    }

                    if (prev >= 0) {
                        this.focusInput(index, this.fields[prev]);
                    }
                    break;
                }

                case 'ArrowRight': {
                    let next = this.fields.indexOf(key) + 1;
                    if (this.fields[next] == 'lineId') {
                        next++;
                    }

                    if (next < this.fields.length) {
                        this.focusInput(index, this.fields[next]);
                    }
                    break;
                }
                case 'ArrowUp': {
                    const newIndex = index - 1;
                    if (newIndex >= 0) {
                        this.focusInput(newIndex, key);
                    }
                    break;
                }

                case 'ArrowDown': {
                    const newIndex = index + 1;
                    if (this.data.length > index + 1) {
                        this.focusInput(newIndex, key);
                    }
                    break;
                }
            }
        }

        // if (e.ctrlKey && e.key == 'v') {
        //     this.$nextTick(async () => await this.importFromClipboard());
        // }
    }

    focusInput(index: number, key: string): void {
        const input = this.$refs[index + '_' + key][0].$children[0];
        input.focus();

        this.$nextTick(() => {
            input.$el.querySelector('input').select();
            this.focus(index, key);
        });
    }
}
