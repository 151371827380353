

















































































import { LineProduct } from '@/models';
import { Modules } from '@/modules';
import { AuthGetter } from '@/modules/auth/store';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
    computed: {
        ...mapGetters(Modules.AUTH, [AuthGetter.USER]),
    },
})
export default class AsysHeaderComponent extends Vue {
    @Prop({ default: false, type: Boolean }) print!: boolean;
    @Prop() lastChange!: LineProduct | null;

    visible = true;
}
