


























import { Component, Vue } from 'vue-property-decorator';

import LineProductComponent from '@/ui/components/line-product.component.vue';
import { LineProduct } from '@/models';

@Component({
    components: {
        'c-line-product': LineProductComponent,
    },
})
export default class PeProductList extends Vue {
    lineProductHeaders = [
        {
            text: 'Info',
            value: 'addInfos',
            class: 'light-green--text subtitle-2',
            ignore: true,
            sortable: false,
        },
        { text: this.$t('Entity.Product.SmtNumber'), value: 'product.smtNumber', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Dps.Number'), value: 'product.dekKohYoung.dps.number', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Product.Name'), value: 'product.name', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Line.Name'), value: 'line.name', class: 'light-green--text subtitle-2', isLine: true },
        { text: this.$t('Entity.Product.OvenProg'), value: 'product.ovenProg', class: 'light-green--text subtitle-2', f: true },
        { text: this.$t('Entity.Product.Side'), value: 'product.side', class: 'light-green--text subtitle-2', productSide: true },
        { text: this.$t('Entity.Dek.Paste'), value: 'product.dekKohYoung.dek.paste', class: 'light-green--text subtitle-2', f: true },
        { text: this.$t('Entity.Dek.Template'), value: 'product.dekKohYoung.dek.template', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Product.DpsTemplate'), value: 'product.dpsTemplate', class: 'light-green--text subtitle-2' },
        {
            text: this.$t('Entity.LineProduct.IsReleased'),
            value: 'isReleased',
            class: 'light-green--text subtitle-2',
            isReleased: true,
            data: (row: any) => (row.isReleased ? this.$t('Enums.ReleaseState.Released') : this.$t('Enums.ReleaseState.UnReleased')),
        },
        {
            text: this.$t('Entity.LineProduct.UpdatorUser'),
            value: 'updatorUser.displayName',
            class: 'light-green--text subtitle-2',
            user: true,
        },
        { text: this.$t('Entity.LineProduct.LastUpdate'), value: 'lastUpdate', class: 'light-green--text subtitle-2', date: true },
    ];

    refresh = false;

    lineProductSelected: LineProduct[] = [];

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-clipboard-text-outline');
        this.$store.commit('SetTitle', this.$t('SideMenu.PE.ProductsList'));
    }

    editLineProducts(): void {
        if (this.lineProductSelected.length && confirm(this.$t('Page.Pe.Ilp.List.Alerts.CreateFromLineProduct').toString())) {
            this.$odata
                .function<any>('incompleteLineProduct', { func: { CreateLineProduct: { ids: this.lineProductSelected.map((m) => m.id) } } })
                .then((d) => {
                    this.$snackbar.success('Převedeno');
                    sessionStorage.setItem('ilp-created', JSON.stringify(d.data.created));
                    this.$router.push({ name: 'ip.list', query: { tab: 'siplace' } });
                });
        }
    }

    removeLineProducts(): void {
        if (this.lineProductSelected.length && confirm(this.$t('Page.Pe.Ilp.List.Alerts.DeleteLineProduct').toString())) {
            this.$odata.function('lineProduct', { func: { Remove: { ids: this.lineProductSelected.map((m) => m.id) } } }).then(() => {
                this.lineProductSelected = [];

                this.$snackbar.success('Page.Pe.Ilp.List.Messages.DeletedLineProduct');
                this.refresh = true;

                this.$nextTick(() => {
                    this.refresh = false;
                });
            });
        }
    }
}
