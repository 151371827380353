





















































import { LineProduct } from '@/models';
import { User } from '@/models/user';
import { Modules } from '@/modules';
import { AuthGetter } from '@/modules/auth/store';

import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import PdfAsysLineProductComponent from '../exports/pdf/line-product/pdf-asys.component.vue';
import LineProductComponent from '../line-product.component.vue';
import AsysHeaderComponent from './headers/header-asys.component.vue';
import VueHtml2pdf from 'vue-html2pdf';
import { generateExcel } from '@/helpers/sheets/generate-excel';

@Component({
    computed: {
        ...mapGetters(Modules.AUTH, [AuthGetter.USER]),
    },
    components: {
        'c-line-product': LineProductComponent,
        'c-header-asys': AsysHeaderComponent,
        'c-pdf-asys': PdfAsysLineProductComponent,
        VueHtml2pdf,
    },
})
export default class OperatorAsysTab extends Vue {
    @Ref('html2Pdf') html2Pdf!: any;

    @Prop() inFilter!: any;
    @Prop() onlySelectedSetup!: any;

    get isActive(): boolean {
        return this.$route.query.tab === 'asys';
    }

    USER!: User;

    lastChange: LineProduct | null = null;

    dataForExport: LineProduct[] = [];

    headers = [
        {
            text: 'Info',
            value: 'addInfos',
            class: 'light-green--text subtitle-2',
            ignore: true,
            sortable: false,
        },
        { text: this.$t('Entity.Product.SmtNumber'), value: 'product.smtNumber', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Line.Name'), value: 'line.name', class: 'light-green--text subtitle-2', isLine: true },
        { text: this.$t('Entity.Product.Name'), value: 'product.name', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Product.Side'), value: 'product.side', class: 'light-green--text subtitle-2', productSide: true },
        { text: this.$t('Entity.Dps.Number'), value: 'product.dekKohYoung.dps.number', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Dps.MagNumber'), value: 'product.dekKohYoung.dps.magNumber', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Asys.Front'), value: 'asys.front', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Asys.End'), value: 'asys.end', class: 'light-green--text subtitle-2' },
        {
            text: this.$t('Entity.LineProduct.IsReleased'),
            value: 'isReleased',
            class: 'light-green--text subtitle-2',
            isReleased: true,
            data: (row: any) => (row.isReleased ? this.$t('Enums.ReleaseState.Released') : this.$t('Enums.ReleaseState.UnReleased')),
        },
    ];

    htmlToPdfOptions = {
        margin: [5, 0, 10, 2],
        html2canvas: {
            y: 0,
            scrollY: 0,
        },
        filename: `asys.pdf`,
        image: {
            type: 'jpeg',
            quality: 0.98,
        },

        jsPDF: {
            format: 'a4',
            orientation: 'landscape',
        },
    };

    mounted(): void {
        this.$odata
            .getList<LineProduct>('lineProduct', {
                orderBy: ['lastUpdate desc'] as any,
                top: 1,
                filter: { lastUpdate: { ne: null }, helpAsys: true },
                expand: { updatorUser: { select: ['id', 'displayName'] } },
            })
            .then((a) => {
                if (a.data.value.length) {
                    this.lastChange = a.data.value[0];
                }
            });
    }

    exportDialog = false;

    generateReport(data: LineProduct[]): void {
        this.dataForExport = [...data];

        if (this.dataForExport.length) {
            this.exportDialog = true;
        } else {
            this.$snackbar.error('Global.NoDataSelected');
        }
    }

    generatePdf(): void {
        this.$nextTick(() => {
            this.html2Pdf.generatePdf();

            this.exportDialog = false;
        });
    }

    generateExcel(): void {
        generateExcel(this.$t('Global.Asys').toString(), this.headers, this.dataForExport);

        this.exportDialog = false;
    }
}
