



















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LanguageComponent extends Vue {
    languages = [
        {
            country: 'cze',
            code: 'cs',
        },
        {
            country: 'gb',
            code: 'en',
        },
    ];

    get currentLang(): any {
        return this.languages.find((f) => f.code == this.$root.$i18n.locale);
    }

    setLanguage(code: string): void {
        localStorage.setItem('lang', code);
        location.reload();
    }
}
