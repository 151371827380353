







import axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import { ModAlertAction } from './modules/alert/store/alert.action';

import SnackbarComponent from './modules/snackbar/components/snackbar.component.vue';

@Component({
    components: {
        'c-snackbar': SnackbarComponent,
    },
})
export default class App extends Vue {
    interval: number | null = null;

    mounted(): void {
        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', this.$i18n.locale);
        }

        axios.get('/api/config').then((a) => {
            this.$store.commit('SetDataUrl', a.data.dataUrl);
            this.$store.commit('SetAppStage', a.data.appStage);
        });

        this.$store.dispatch(ModAlertAction.INIT_ALERT.toString());

        this.interval = setInterval(() => {
            this.$store.dispatch(ModAlertAction.INIT_ALERT.toString());
        }, 1 * 1000 * 60 * 10);
    }

    destroy(): void {
        if (this.interval != null) {
            clearInterval(this.interval);
        }
    }
}
