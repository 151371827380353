import { Modules } from '@/modules/modules';
import { MutationTree } from 'vuex';
import { Alert } from '../models';
import { AlertState } from './alert.state';

export enum AlertMutation {
    SHOW_ALERT = 'SNOW_ALERT',
}

export enum ModAlertMutation {
    SHOW_ALERT = Modules.ALERT + '/' + AlertMutation.SHOW_ALERT,
}

export const alertMutations: MutationTree<AlertState> = {
    [AlertMutation.SHOW_ALERT]: (state: AlertState, payload: Alert | null) => {
        state.alert = payload;
    },
};
