import { Modules } from '@/modules/modules';
import { MutationTree } from 'vuex';
import { Snackbar } from '../models';
import { SnackbarState } from './snackbar.state';

export enum SnackbarMutation {
    SHOW_SNACKBAR = 'SNOW_SNACKBAR',
    HIDE_SNACKBAR = 'HIDE_SNACKBAR',
}

export enum ModSnackbarMutation {
    SHOW_SNACKBAR = Modules.SNACKBAR + '/' + SnackbarMutation.SHOW_SNACKBAR,
    HIDE_SNACKBAR = Modules.SNACKBAR + '/' + SnackbarMutation.HIDE_SNACKBAR,
}

export const snackbarMutations: MutationTree<SnackbarState> = {
    [SnackbarMutation.SHOW_SNACKBAR]: (state: SnackbarState, payload: { snackbar: Snackbar | null }) => {
        state.snackbar = payload.snackbar;
    },

    [SnackbarMutation.HIDE_SNACKBAR]: (state: SnackbarState) => {
        state.snackbar = null;
    },
};
