






























































































import { LineProduct } from '@/models';
import { Line } from '@/models/line';
import { ODataResult } from '@/models/odata.result';
import { GridOptions } from '@/models/vuetify';
import RefresherComponent from '@/ui/components/refresher.component.vue';
import { debounce } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        'c-refresher': RefresherComponent,
    },
})
export default class MpNplPage extends Vue {
    filter = { product: '', lines: [], tht: '', smt: '', active: null };
    lines: Line[] = [];

    lastUpdate: Date | null = null;

    isLoading = false;

    data: ODataResult<any> = { value: [], '@odata.count': 0 };

    activeState = [
        { value: null, text: this.$t('Page.Sheets.Filter.ActiveState.All') },
        { value: true, text: this.$t('Page.Sheets.Filter.ActiveState.Active') },
        { value: false, text: this.$t('Page.Sheets.Filter.ActiveState.NotActive') },
    ];

    options: GridOptions = {
        page: 1,
        itemsPerPage: +(localStorage.getItem('mpnpl_itemsPerPage') || 20),
        sortBy: [],
        sortDesc: [],
        multiSort: true,
        mustSort: false,
    };

    headers = [
        {
            value: 'line.name',
            text: this.$t('Entity.Line.Name'),
            class: 'light-green--text subtitle-2',
        },
        {
            value: 'product.name',
            text: this.$t('Entity.Product.Name'),
            class: 'light-green--text subtitle-2',
        },
        {
            value: 'product.thtNumber',
            text: this.$t('Entity.Product.ThtNumber'),
            class: 'light-green--text subtitle-2',
        },
        {
            value: 'product.smtNumber',
            text: this.$t('Entity.Product.SmtNumber'),
            class: 'light-green--text subtitle-2',
        },
        {
            value: 'isActive',
            text: this.$t('Entity.LineProduct.IsActive'),
            class: 'light-green--text subtitle-2',
        },
    ];

    changeDebounce!: () => void;
    created(): void {
        this.changeDebounce = debounce(() => {
            if (this.options.page != 1) {
                this.options.page = 1;
            } else {
                this.fetchData();
            }
        }, 400);
    }

    itemClass(item: LineProduct): string {
        return !item.isActive ? 'row-inactive' : '';
    }

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-head-check-outline');
        this.$store.commit('SetTitle', this.$t('Page.Sheets.MpNpl.Title'));

        this.$odata
            .getList<Line>('line', { orderBy: ['name'], filter: [{ isActive: { eq: true } }] })
            .then((a) => (this.lines = a.data.value));
    }

    fetchData(): void {
        this.isLoading = true;

        if (isNaN(+this.options.itemsPerPage) || !this.options.itemsPerPage) {
            this.options.itemsPerPage = 20;
        }

        const filter: any[] = [];

        const top = this.options.itemsPerPage;
        const skip = (this.options.page - 1) * this.options.itemsPerPage;
        const orderBy = this.options.sortBy.map(
            (sort, index) => sort.replaceAll('.', '/') + (this.options.sortDesc[index] ? ' desc' : '')
        ) as any;

        if (this.filter.lines && this.filter.lines.length) filter.push({ lineId: { in: this.filter.lines } });
        if (this.filter.active !== null) filter.push({ isActive: Boolean(this.filter.active) });
        if (this.filter.product) filter.push({ product: { 'toLower(name)': { contains: this.filter.product.toLowerCase() } } });
        if (this.filter.tht) filter.push({ product: { 'toLower(thtNumber)': { contains: this.filter.tht.toLowerCase() } } });
        if (this.filter.smt) filter.push({ product: { 'toLower(smtNumber)': { contains: this.filter.smt.toLowerCase() } } });

        this.$odata
            .getList<LineProduct>('lineProduct', {
                top,
                skip,
                filter,
                orderBy,
                count: true,
                expand: { line: { select: ['id', 'name'] }, product: {} },
            })
            .then((a) => (this.data = a.data))
            .finally(() => {
                this.isLoading = false;
                this.lastUpdate = new Date();

                localStorage.setItem('mpnpl_itemsPerPage', this.options.itemsPerPage.toString());
            });
    }

    changeActive(item: LineProduct, val: boolean): void {
        this.$odata.function('lineProduct', { key: item.id, func: { setActiveState: { state: val } } }).then(() => {
            this.$snackbar.success('Page.Sheets.MpNpl.SnackBar.StateChangeActive');

            this.fetchData();
        });
    }
}
