



































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TextFieldComponent extends Vue {
    @Prop({ type: Boolean }) readonly!: boolean;
    @Prop({ type: Boolean }) disabled!: boolean;
    @Prop({ default: true, type: Boolean }) dense!: boolean;
    @Prop({ default: true, type: Boolean }) outlined!: boolean;
    @Prop() value!: string;
    @Prop() label!: string;
    @Prop() suffix!: string;
    @Prop() prefix!: string;
    @Prop({ type: Boolean }) clearable!: boolean;
    @Prop({ type: Boolean }) required!: boolean;
    @Prop({ type: Boolean }) requiredIndicator!: boolean;
    @Prop({ default: 'red' }) requiredIndicatorColor!: string;
    @Prop() prependIcon!: string;
    @Prop() prependInnerIcon!: string;
    @Prop() appendIcon!: string;
    @Prop() appendOuterIcon!: string;
    @Prop() minLength!: number;
    @Prop() maxLength!: number;
    @Prop({ type: Boolean }) counter!: boolean;
    @Prop() placeholder!: string;
    @Prop({ default: false }) hideDetails!: boolean | string;
    @Prop() hint!: string;

    @Prop({ default: () => [] }) rules!: any[];

    @Prop() props!: any;

    get isReadonly(): boolean {
        return this.readonly;
    }

    get ruleset(): any[] {
        const result = [...this.rules];
        if (this.required) result.push((v: any) => !!v || this.$t('Validation.Required'));

        if (this.minLength)
            result.push(
                (v: any) => (!!v && v.length > this.minLength - 1) || this.$t('Validation.MinLength', { minLength: this.minLength })
            );
        if (this.maxLength)
            result.push(
                (v: any) => (!!v && v.length < this.maxLength + 1) || this.$t('Validation.MaxLength', { maxLength: this.maxLength })
            );

        return result;
    }

    get requiredColor(): string {
        if (!this.required && this.requiredIndicator) {
            return this.requiredIndicatorColor;
        }

        return 'red';
    }
}
