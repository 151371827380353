import { RouteConfig } from 'vue-router';

import UserListPage from '@/ui/pages/admin/user/user-list.page.vue';
import UserListDeletePage from '@/ui/pages/admin/user/user-list-delete.page.vue';
import UserListGdprPage from '@/ui/pages/admin/user/user-list-gdpr.page.vue';
import UserListAllowedPage from '@/ui/pages/admin/user/user-list-allowed.page.vue';
import UserDetailPage from '@/ui/pages/admin/user/user-detail.page.vue';

export const USER_ROUTES: RouteConfig[] = [
    {
        path: '',
        component: UserListPage,
        name: 'user.list',
    },
    {
        path: 'new',
        component: UserDetailPage,
        name: 'user.new',
    },
    {
        path: 'delete',
        component: UserListDeletePage,
        name: 'user.delete',
    },
    {
        path: 'gdpr',
        component: UserListGdprPage,
        name: 'user.gdpr',
    },
    {
        path: 'allowed',
        component: UserListAllowedPage,
        name: 'user.allowed',
    },
    {
        path: ':id',
        component: UserDetailPage,
        name: 'user.detail',
    },
    {
        path: '*',
        redirect: 'user.list',
    },
];
