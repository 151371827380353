



































import { Group } from '@/models/group';
import { VForm } from '@/models/vuetify';
import { Component, Vue, Ref } from 'vue-property-decorator';

@Component
export default class GroupPage extends Vue {
    groups: Group[] | null = null;
    isLoading = false;

    @Ref('groupsForm') groupsForm!: VForm;

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-account-group-outline');
        this.$store.commit('SetTitle', this.$t('Page.Admin.Group.Title'));

        this.fetchGroups();
    }

    fetchGroups(): void {
        this.isLoading = true;

        this.$odata
            .getList<Group>('group')
            .then((data) => {
                this.groups = data.data.value;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    submitForm(): void {
        if (this.groupsForm.validate()) {
            this.$odata
                .action(
                    'group',
                    {
                        data: this.groups?.map((x) => {
                            return { id: x.id, sessionExp: x.sessionExp };
                        }),
                    },
                    { func: 'SetExpirationSession' }
                )
                .then(() => {
                    this.$snackbar.success(this.$t('Page.Admin.Group.Message.Update').toString());
                });
        }
    }
}
