











































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { format, formatISO, parse, parseISO } from 'date-fns';

const COMPUTER_DATE_FORMAT = 'yyyy-MM-dd';
const COMPUTER_TIME_FORMAT = 'HH:mm:ss';

const DEFAULT_DATE = '';
const DEFAULT_TIME = '00:00:00';
const DEFAULT_DATE_FORMAT = 'dd.MM.yyy';
const DEFAULT_TIME_FORMAT = 'HH:mm';
const DEFAULT_DIALOG_WIDTH = 340;
const DEFAULT_CLEAR_TEXT = 'CLEAR';
const DEFAULT_OK_TEXT = 'OK';

@Component
export default class DatetimePickerComponent extends Vue {
    @Prop() value!: string | Date;

    @Prop({ default: DEFAULT_DATE_FORMAT }) dateFormat!: string;
    @Prop({ default: DEFAULT_TIME_FORMAT }) timeFormat!: string;
    @Prop() label!: string;
    @Prop({ default: true, type: Boolean }) dense!: boolean;
    @Prop({ default: true, type: Boolean }) outlined!: boolean;

    @Prop({ type: Boolean }) disabled!: boolean;
    @Prop() loading!: boolean;
    @Prop({ default: DEFAULT_DIALOG_WIDTH }) dialogWidth!: number;

    @Prop({ default: DEFAULT_CLEAR_TEXT }) clearText!: string;
    @Prop({ default: DEFAULT_OK_TEXT }) okText!: string;

    @Prop() textFieldProps!: any;
    @Prop() datePickerProps!: any;
    @Prop() timePickerProps!: any;

    @Prop({ default: () => [] }) rules!: any[];

    @Prop({ type: Boolean }) required!: boolean;
    @Prop({ type: Boolean }) requiredIndicator!: boolean;

    @Prop({ type: Boolean }) readonly!: boolean;
    @Prop({ type: Boolean }) cv!: boolean;

    get isReadonly(): boolean {
        return this.readonly;
    }

    get ruleset(): any[] {
        const result = [...this.rules];

        if (this.required) result.push((v: any) => !!v || this.$t('Validation.Required'));

        return result;
    }

    display = false;
    activeTab = 0;
    date = DEFAULT_DATE;
    time = DEFAULT_TIME;

    get dateTimeFormat(): string {
        return this.dateFormat + ' ' + this.timeFormat;
    }

    get computerDateTimeFormat(): string {
        return COMPUTER_DATE_FORMAT + ' ' + COMPUTER_TIME_FORMAT;
    }

    get formattedDatetime(): string {
        return this.selectedDatetime ? format(this.selectedDatetime, this.dateTimeFormat) : '';
    }

    get selectedDatetime(): Date | null {
        if (this.date && this.time) {
            let datetimeString = this.date + ' ' + this.time;
            if (this.time.length === 5) {
                datetimeString += ':00';
            }
            return parse(datetimeString, this.computerDateTimeFormat, new Date());
        } else {
            return null;
        }
    }

    get dateSelected(): boolean {
        return !this.date;
    }

    mounted(): void {
        this.init();
    }

    init(): void {
        if (!this.value) {
            return;
        }

        const initDateTime = parseISO(new Date(this.value).toISOString());

        this.date = format(initDateTime, COMPUTER_DATE_FORMAT);
        this.time = format(initDateTime, COMPUTER_TIME_FORMAT);
    }

    okHandler(): void {
        this.resetPicker();
        this.$emit('input', this.selectedDatetime ? formatISO(this.selectedDatetime) : null);
    }

    clearHandler(): void {
        this.resetPicker();
        this.date = DEFAULT_DATE;
        this.time = DEFAULT_TIME;
        this.$emit('input', null);
    }

    resetPicker(): void {
        this.display = false;
        this.activeTab = 0;
        if (this.$refs.timer) {
            (this.$refs.timer as any).selectingHour = true;
        }
    }

    showTimePicker(): void {
        this.activeTab = 1;
    }

    @Watch('input', { immediate: true })
    inputWatch(): void {
        this.init();
    }
}
