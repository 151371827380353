export interface Group {
    id: number;
    name: string;
    appName: string;
    sessionExp: number;
}

export enum eGroup {
    ADMIN = 'ADMIN',
    PE = 'PE',
    OPERATOR = 'OPERATOR',
    MPNPL = 'MPNPL',
    LOGISTIKA = 'LOGISTIKA',
    KVALITA = 'KVALITA',
}

export interface GroupUpdate {
    id: number;
    sessionExp: number;
}
