





































import { Component, Vue, Watch } from 'vue-property-decorator';

import KvalitaAllTab from '@/ui/components/kvalita/all.tab.vue';
import KvalitaOtherTab from '@/ui/components/kvalita/other.tab.vue';
import KvalitaLogTab from '@/ui/components/kvalita/log.tab.vue';
import JidokaAllTab from '@/ui/components/jidoka/all.tab.vue';
import JidokaLogTab from '@/ui/components/jidoka/log.tab.vue';

@Component({
    components: {
        'c-tab-all': KvalitaAllTab,
        'c-tab-other': KvalitaOtherTab,
        'c-tab-log': KvalitaLogTab,
        'c-tab-jidoka': JidokaAllTab,
        'c-tab-jidoka-log': JidokaLogTab,
    },
})
export default class KvalitaPage extends Vue {
    tab = 0;
    tabs = ['all', 'new', 'old', 'log', 'jidoka', 'jidoka-log'];

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-star-outline');
        this.$store.commit('SetTitle', this.$t('Page.Sheets.Quality.Title'));

        this.$store.dispatch('InitQualityCount');
    }

    @Watch('tab')
    onTabChange(): void {
        this.$nextTick(() => {
            if (this.tab !== this.tabs.indexOf(this.$route.query.tab?.toString() ?? 'all')) {
                this.$router.replace({ query: { tab: this.tabs[this.tab] } });
            }
        });
    }

    @Watch('$route.query.tab', { immediate: true })
    onRouteTabChange(): void {
        this.tab = this.tabs.indexOf(this.$route.query.tab?.toString() ?? 'all');
    }
}
