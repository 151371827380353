import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import cs from 'vuetify/src/locale/cs';
import en from 'vuetify/src/locale/en';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: {
                primary: '#FFA500',
            },
        },
    },
    lang: {
        locales: { cs, en },
        current: localStorage.getItem('lang') ?? 'cs',
    },
});
