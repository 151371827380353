import { LineProduct } from '@/models';
import { get } from 'lodash';

export const properties = [
    {
        prop: 'product.smtNumber',
        critical: true,
        xLine: true,
    },
    {
        prop: 'line.name',
        critical: true,
        xLine: true,
    },
    { prop: 'isReleased', critical: true },
    { prop: 'product.name', critical: true, xLine: true },
    { prop: 'product.thtNumber', critical: true, xLine: true },
    { prop: 'product.dekKohYoung.dps.number', critical: true, xLine: true },
    { prop: 'product.ovenProg', critical: true },
    { prop: 'product.dekKohYoung.dek.paste', critical: true },
    { prop: 'product.dekKohYoung.dek.squeegee', critical: true },
    { prop: 'product.dekKohYoung.dek.program', critical: true },
    { prop: 'product.dekKohYoung.dek.tooling', critical: true },
    { prop: 'product.dekKohYoung.dek.template', critical: true },
    { prop: 'product.dekKohYoung.dps.magNumber', critical: true, xLine: true },
    { prop: 'product.dekKohYoung.aoi', critical: true },
    { prop: 'product.dekKohYoung.support', critical: true },
    { prop: 'product.side', critical: true, xLine: true },
    { prop: 'product.ovenProg', critical: true },
    { prop: 'asys.front', critical: true, xLine: true },
    { prop: 'asys.end', critical: true, xLine: true },
    { prop: 'norm' },
    { prop: 'product.dpsTemplate' },
];

export function getAllNullProperties(e: LineProduct) {
    if (e.line.name?.toUpperCase()?.startsWith('X')) {
        return getXLineNullProperties(e);
    } else {
        return getNullProperties(e);
    }
}

export function getNullProperties(e: LineProduct): string[] {
    return properties.filter((f) => f.critical && get(e, f.prop) == null).map((m) => m.prop);
}

export function getXLineNullProperties(e: LineProduct): string[] {
    return properties.filter((f) => f.xLine && get(e, f.prop) == null).map((m) => m.prop);
}

export function allowConvert(e: LineProduct): boolean {
    if (e.line.name?.toUpperCase()?.startsWith('X')) {
        return !getXLineNullProperties(e).length;
    } else {
        return !getNullProperties(e).length;
    }
}

export function getTranslateKey(key: string): string {
    return (
        'Entity.LineProduct.' +
        key
            .split('.')
            .map((m) => m[0].toUpperCase() + m.slice(1))
            .join('.')
    );
}
