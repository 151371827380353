













































































import { LineProduct } from '@/models';
import { User } from '@/models/user';
import { Modules } from '@/modules';
import { AuthGetter } from '@/modules/auth/store';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import VueHtml2pdf from 'vue-html2pdf';
import PdfCustomLineProductComponent from '../exports/pdf/line-product/pdf-custom.component.vue';
import LineProductComponent from '../line-product.component.vue';
import { generateExcel } from '@/helpers/sheets/generate-excel';

@Component({
    computed: {
        ...mapGetters(Modules.AUTH, [AuthGetter.USER]),
    },
    components: {
        'c-line-product': LineProductComponent,
        'c-pdf-custom': PdfCustomLineProductComponent,
        VueHtml2pdf,
    },
})
export default class OperatorCustomTab extends Vue {
    @Ref('html2Pdf') html2Pdf!: any;

    @Prop() inFilter!: any;
    @Prop() onlySelectedSetup!: any;

    get isActive(): boolean {
        return this.$route.query.tab === 'custom';
    }

    USER!: User;

    selected: any[] = [];
    dataForExport: LineProduct[] = [];

    htmlToPdfOptions = {
        margin: [5, 0, 10, 2],
        html2canvas: {
            y: 0,
            scrollY: 0,
        },
        filename: `custom.pdf`,
        image: {
            type: 'jpeg',
            quality: 0.98,
        },

        jsPDF: {
            format: 'a4',
            orientation: 'landscape',
        },
    };

    get icon(): string {
        if (this.selected.length === this.headers.length) return 'mdi-checkbox-outline';
        if (this.selected.length) return 'mdi-minus-box-outline';
        return 'mdi-checkbox-blank-outline';
    }

    onChangeSelected(): void {
        localStorage.setItem('custom-selected', JSON.stringify(this.selected.map((m) => m.value)));
    }

    mounted(): void {
        const ls = localStorage.getItem('custom-selected');
        if (ls) {
            try {
                const values = JSON.parse(ls) as string[];
                this.selected = this.items.filter((f) => values.find((a) => a == f.value));
            } catch {
                this.selected = [];
            }
        }
    }

    items: any[] = [
        // { text: this.$t('Entity.LineProduct.IsActive'), value: 'isActive', class: 'light-green--text subtitle-2', isActive: true },
        { text: this.$t('Entity.Product.SmtNumber'), value: 'product.smtNumber', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Line.Name'), value: 'line.name', class: 'light-green--text subtitle-2', isLine: true },
        { text: this.$t('Entity.Product.Name'), value: 'product.name', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Product.Side'), value: 'product.side', class: 'light-green--text subtitle-2', productSide: true },
        { text: this.$t('Entity.Dps.Number'), value: 'product.dekKohYoung.dps.number', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Product.ThtNumber'), value: 'product.thtNumber', class: 'light-green--text subtitle-2' },

        { text: this.$t('Entity.Product.DpsTemplate'), value: 'product.dpsTemplate', class: 'light-green--text subtitle-2' },

        { text: this.$t('Entity.Dek.Program'), value: 'product.dekKohYoung.dek.program', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.DekKohYoung.Aoi'), value: 'product.dekKohYoung.aoi', class: 'light-green--text subtitle-2' },
        {
            text: this.$t('Entity.DekKohYoung.Support'),
            value: 'product.dekKohYoung.support',
            class: 'light-green--text subtitle-2',
            isBoolean: true,
            data: (row: any) =>
                this.$isBoolean(row.product.dekKohYoung.support)
                    ? row.product.dekKohYoung.support
                        ? this.$t('Global.Yes')
                        : this.$t('Global.No')
                    : '',
        },
        { text: this.$t('Entity.Dek.Paste'), value: 'product.dekKohYoung.dek.paste', class: 'light-green--text subtitle-2', f: true },
        { text: this.$t('Entity.Dek.Squeegee'), value: 'product.dekKohYoung.dek.squeegee', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Dek.Template'), value: 'product.dekKohYoung.dek.template', class: 'light-green--text subtitle-2' },
        // {
        //     text: this.$t('Entity.Dek.TemplateThick'),
        //     value: 'product.dekKohYoung.dek.templateThick',
        //     class: 'light-green--text subtitle-2',
        // },
        { text: this.$t('Entity.Dek.Tooling'), value: 'product.dekKohYoung.dek.tooling', class: 'light-green--text subtitle-2' },
        // {
        //     text: this.$t('Entity.Dek.ToolingPos'),
        //     value: 'product.dekKohYoung.dek.toolingPos',
        //     class: 'light-green--text subtitle-2',
        //     number: true,
        // },

        { text: this.$t('Entity.Setup.Name'), value: 'setup.name', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Product.LaserProg'), value: 'product.laserProg', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Product.OvenProg'), value: 'product.ovenProg', class: 'light-green--text subtitle-2', f: true },
        { text: this.$t('Entity.LineProduct.Norm'), value: 'norm', class: 'light-green--text subtitle-2' },

        { text: this.$t('Entity.Dps.MagNumber'), value: 'product.dekKohYoung.dps.magNumber', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Asys.Front'), value: 'asys.front', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Asys.End'), value: 'asys.end', class: 'light-green--text subtitle-2' },
        {
            text: this.$t('Entity.LineProduct.IsReleased'),
            value: 'isReleased',
            class: 'light-green--text subtitle-2',
            isReleased: true,
            data: (row: any) => (row.isReleased ? this.$t('Enums.ReleaseState.Released') : this.$t('Enums.ReleaseState.UnReleased')),
        },
        {
            text: 'Info',
            value: 'addInfos',
            class: 'light-green--text subtitle-2',
            ignore: true,
            sortable: false,
        },
    ];

    get headers(): any[] {
        if (this.USER.lineId) {
            return this.items.filter((f) => !f.isLine);
        }
        return this.items;
    }

    exportDialog = false;

    generateReport(data: LineProduct[]): void {
        this.dataForExport = [...data];

        if (this.dataForExport.length) {
            this.exportDialog = true;
        } else {
            this.$snackbar.error('Global.NoDataSelected');
        }
    }

    generatePdf(): void {
        this.$nextTick(() => {
            this.html2Pdf.generatePdf();

            this.exportDialog = false;
        });
    }

    generateExcel(): void {
        generateExcel(this.$t('Global.Custom').toString(), this.selected, this.dataForExport);

        this.exportDialog = false;
    }

    toggle(): void {
        this.$nextTick(() => {
            if (this.selected.length === this.headers.length) {
                this.selected = [];
            } else {
                this.selected = this.headers.slice();
            }
        });
    }
}
