

















import { Snackbar } from '@/modules/snackbar/models';
import { SnackbarGetter, ModSnackbarMutation } from '@/modules/snackbar/store';
import { Modules } from '@/modules';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
    computed: {
        ...mapGetters(Modules.SNACKBAR, [SnackbarGetter.SNACKBAR]),
    },
})
export default class SnackbarComponent extends Vue {
    SNACKBAR!: Snackbar;

    get snackbar(): boolean {
        return !!this.SNACKBAR;
    }

    onClose(e: boolean): void {
        if (!e) {
            this.$store.commit(ModSnackbarMutation.HIDE_SNACKBAR.toString());
        }
    }
}
