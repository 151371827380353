












































import { IncompleteLineProductLocked } from '@/models/incompleteLineProduct';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class IncompleteLineProductLockedComponent extends Vue {
    @Prop() value!: IncompleteLineProductLocked[];
    @Prop({ default: 'detail' }) type!: string;
}
