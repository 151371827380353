




import { eGroup } from '@/models/group';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class HomePage extends Vue {
    mounted(): void {
        if (this.$inGroup(eGroup.OPERATOR)) {
            this.$router.replace({ name: 'operator.list' });
        } else if (this.$inGroup(eGroup.PE)) {
            this.$router.replace({ name: 'ip.list' });
        } else if (this.$inGroup(eGroup.MPNPL)) {
            this.$router.replace({ name: 'mpnpl.list' });
        } else if (this.$inGroup(eGroup.LOGISTIKA)) {
            this.$router.replace({ name: 'logistika.list' });
        } else if (this.$inGroup(eGroup.KVALITA)) {
            this.$router.replace({ name: 'kvalita.list' });
        } else if (this.$inGroup(eGroup.ADMIN)) {
            this.$router.replace({ name: 'user.list' });
        }
    }
}
