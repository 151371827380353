import store from '@/store';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { BaseService } from './base.service';

import { ModAuthGetter } from '@/modules/auth/store';

export class BaseApiService extends BaseService {
    protected async get<T>(url: string, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<T>> {
        return super.get<T>('/' + url, {
            ...config,
            headers: {
                ...config?.headers,
                Authorization: store.getters[ModAuthGetter.AUTH_HEADER],
            },
        });
    }

    protected async post<T>(url: string, data?: unknown, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<T>> {
        return super.post<T>('/' + url, data, {
            ...config,
            headers: {
                ...config?.headers,
                Authorization: store.getters[ModAuthGetter.AUTH_HEADER],
            },
        });
    }

    protected async patch<T>(url: string, data?: unknown, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<T>> {
        return super.patch<T>('/' + url, data, {
            ...config,
            headers: {
                ...config?.headers,
                Authorization: store.getters[ModAuthGetter.AUTH_HEADER],
            },
        });
    }

    protected async delete(url: string, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse> {
        return super.delete('/' + url, {
            ...config,
            headers: {
                ...config?.headers,
                Authorization: store.getters[ModAuthGetter.AUTH_HEADER],
            },
        });
    }
}
