import { RouteConfig } from 'vue-router';

import StationListPage from '@/ui/pages/list/station/station-list.page.vue';
import StationDetailPage from '@/ui/pages/list/station/station-detail.page.vue';

export const STATION_ROUTES: RouteConfig[] = [
    {
        path: '',
        component: StationListPage,
        name: 'station.list',
    },
    {
        path: ':id',
        component: StationDetailPage,
        name: 'station.detail',
    },
    {
        path: 'new',
        component: StationDetailPage,
        name: 'station.new',
    },
    {
        path: '*',
        redirect: 'station.list',
    },
];
