







import { Modules } from '@/modules';
import AlertComponent from '@/modules/alert/components/alert.component.vue';
import { AlertGetter } from '@/modules/alert/store/alert.getter';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
    components: {
        'c-alert': AlertComponent,
    },
    computed: {
        ...mapGetters(Modules.ALERT, [AlertGetter.ALERT]),
    },
})
export default class AlertView extends Vue {}
