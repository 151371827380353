























































import { Line } from '@/models/line';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LinesSelectComponent extends Vue {
    itemValue = 'id';
    itemText = 'name';

    @Prop() value!: any;
    @Prop() label!: string;
    @Prop() hint!: string;

    @Prop({ default: false, type: Boolean }) multiple!: boolean;
    @Prop({ default: 3 }) maxDisplay!: number | string;

    @Prop({ default: false, type: Boolean }) onlyActive!: boolean;

    @Prop({ type: Boolean, default: true }) dense!: boolean;
    @Prop({ default: true, type: Boolean }) outlined!: boolean;
    @Prop({ type: Boolean }) readonly!: boolean;
    @Prop({ type: Boolean }) required!: boolean;
    @Prop({ type: Boolean }) requiredIndicator!: boolean;
    @Prop({ default: 'red' }) requiredIndicatorColor!: string;
    @Prop({ type: Boolean }) clearable!: boolean;

    @Prop({ default: false }) hideDetails!: boolean | string;

    @Prop({ default: () => [] }) rules!: any[];

    @Prop() props!: any;
    @Prop() lines!: Line[];

    data: Line[] = [];

    get getMaxDisplay(): number {
        return +this.maxDisplay;
    }

    get isReadonly(): boolean {
        return this.readonly;
    }

    get ruleset(): any[] {
        const result = [...this.rules];

        if (this.required) result.push((v: any) => !!v || this.$t('Validation.Required'));

        return result;
    }

    get requiredColor(): string {
        if (!this.required && this.requiredIndicator) {
            return this.requiredIndicatorColor;
        }

        return 'red';
    }

    mounted(): void {
        if (this.lines) {
            this.setData(this.lines);
        } else {
            const filter: any[] = [];
            if (this.onlyActive) {
                filter.push({ isActive: { eq: true } });
            }

            this.$odata.getList<Line>('line', { orderBy: ['isActive desc', 'name'] as any, filter }).then((a) => {
                this.setData(a.data.value);
            });
        }
    }

    setData(lines: Line[]): void {
        this.data.push(...lines.filter((f) => f.isActive));

        if (!this.onlyActive) {
            this.data.push({ header: this.$t('Global.NotActive') } as any);
            this.data.push({ divider: true } as any);

            this.data.push(...lines.filter((f) => !f.isActive));
        }
    }
}
