







































































































































































import { debounce } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AdminSettingPage extends Vue {
    adminSettings: any[] | null = null;
    isLoading = false;

    files: any[] | null = null;

    debounceChange!: (id: number, value: string) => void;

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-cog-outline');
        this.$store.commit('SetTitle', this.$t('Page.Admin.AdminSetting.Title'));

        this.debounceChange = debounce(this.change, 2000);

        this.fetchSettings();
        this.fetchFiles();
    }

    fetchSettings(): void {
        this.isLoading = true;

        this.$odata
            .getList<any>('adminSetting')
            .then((data) => {
                this.adminSettings = data.data.value.map((m) => {
                    if (m.value) {
                        if (m.value.toLowerCase() === 'true') {
                            m.value = true;
                        } else if (m.value.toLowerCase() === 'false') {
                            m.value = false;
                        }
                    }

                    return m;
                });
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    fetchFiles(): void {
        this.isLoading = true;

        this.$odata
            .getList<any>('helpFile')
            .then((data) => {
                this.files = data.data.value;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    change(id: number, value: boolean | string): void {
        this.$odata.update('adminSetting', id, { id: id, value: value.toString() }).then(() => this.$snackbar.success('Global.DataSaved'));
    }

    fileUpload(id: number, file: any): void {
        const formData = new FormData();
        formData.append('file', file);
        this.$odata.action('adminSetting', formData, { key: id, func: 'UploadAdminGdprPdf' }).then(() => {
            this.fetchSettings();

            this.$snackbar.success('Upload success');
        });
    }

    helpFilesUpload(files: any[]): void {
        if (files.length === 0) {
            return;
        }

        const formData = new FormData();
        files.forEach((file) => {
            formData.append('files', file);
        });

        this.$odata.create('helpFile', formData).then(() => {
            this.fetchFiles();

            this.$snackbar.success('Upload success');
        });
    }

    removeFile(id: number): void {
        this.$odata.remove('helpFile', id).then(() => {
            this.fetchFiles();

            this.$snackbar.success('Delete success');
        });
    }
}
