
















































































































































import { Line } from '@/models/line';
import { ODataResult } from '@/models/odata.result';
import { GridOptions } from '@/models/vuetify';
import { debounce } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class KvalitaLogTab extends Vue {
    dialog = false;
    isLoading = false;
    data: ODataResult<any> = { value: [], '@odata.count': 0 };

    filter: any = {};

    lines: Line[] = [];

    audit: any | null = null;
    auditValues: any | null = null;

    options: GridOptions = {
        page: 1,
        itemsPerPage: +(localStorage.getItem('kvalita_itemsPerPage') || 20),
        sortBy: ['timestamp'],
        sortDesc: [true],
        multiSort: true,
        mustSort: false,
    };

    headers = [
        { text: this.$t('Audit.Header.Action'), value: 'action', width: '200', class: 'light-green--text subtitle-2' },
        { text: 'ID', value: 'lineProduct.id', width: 150, class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Product.SmtNumber'), value: 'lineProduct.product.smtNumber', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Line.Name'), value: 'lineProduct.line.name', class: 'light-green--text subtitle-2' },
        {
            text: this.$t('Entity.Audit.User'),
            value: 'user.displayName',
            width: '200',
            class: 'light-green--text subtitle-2',
        },
        { text: this.$t('Audit.Header.Date'), value: 'timestamp', width: '150', class: 'light-green--text subtitle-2' },
    ];

    actions = [
        { text: this.$t('Audit.Action.Create'), value: 'Create' },
        { text: this.$t('Audit.Action.Update'), value: 'Update' },
        { text: this.$t('Audit.Action.Delete'), value: 'Delete' },
        { text: this.$t('Audit.Action.Convert'), value: 'Convert' },
    ];

    getLine(id: number): string {
        return this.lines.find((f) => f.id == id)?.name ?? '';
    }

    changeDebounce!: () => void;
    created(): void {
        this.changeDebounce = debounce(() => {
            if (this.options.page != 1) {
                this.options.page = 1;
            } else {
                this.fetchData();
            }
        }, 400);
    }

    mounted(): void {
        this.$odata
            .getList<Line>('line', { orderBy: ['name'], select: ['id', 'name', 'isActive'] })
            .then((a) => (this.lines = a.data.value));
    }

    fetchData(): void {
        this.isLoading = true;

        if (isNaN(+this.options.itemsPerPage) || !this.options.itemsPerPage) {
            this.options.itemsPerPage = 20;
        }

        const filter: any[] = [];

        const orderBy = this.options.sortBy.map(
            (sort, index) => sort.replaceAll('.', '/') + (this.options.sortDesc[index] ? ' desc' : '')
        ) as any;

        if (this.filter.action) filter.push({ action: { eq: this.filter.action } });
        // if (this.filter.users && this.filter.users.length) filter.push({ userId: { in: this.filter.users } });
        if (this.filter.lines && this.filter.lines.length) filter.push({ lineProduct: { lineId: { in: this.filter.lines } } });
        if (this.filter.smtNumber)
            filter.push({ lineProduct: { product: { 'toLower(smtNumber)': { contains: this.filter.smtNumber.toLowerCase() } } } });
        if (this.filter.id)
            filter.push({
                or: [
                    { entityId: { eq: this.filter.id } },
                    { and: [{ entityId: { eq: null } }, { lineProductId: { eq: this.filter.id } }] },
                ],
            });

        this.$odata
            .getList<any>('audit', {
                top: this.options.itemsPerPage,
                func: 'Quality()',
                filter,
                orderBy,
                expand: {
                    lineProduct: {
                        expand: { product: { select: ['smtNumber'] }, line: { select: ['name'] } },
                    },
                    user: { select: ['displayName'] },
                },
            })
            .then((d) => (this.data = d.data))
            .finally(() => {
                this.isLoading = false;
                localStorage.setItem('kvalita_itemsPerPage', this.options.itemsPerPage.toString());
            });
    }

    rowClick(e: any): void {
        if (!e) return;

        const oldValues = JSON.parse(e.oldValues);
        const newValues = JSON.parse(e.newValues);

        const result = {};
        Object.keys(oldValues).forEach((key) => {
            result[key] = [oldValues[key]];
        });

        Object.keys(newValues).forEach((key) => {
            if (result[key]) {
                result[key].push(newValues[key]);
            } else {
                result[key] = [null, newValues[key]];
            }
        });

        const keysForRemove: string[] = [];

        Object.keys(result).forEach((f) => {
            if (result[f][0] == result[f][1]) {
                keysForRemove.push(f);
            }
        });

        keysForRemove.forEach((f) => {
            delete result[f];
        });

        this.audit = e;
        this.auditValues = result;

        this.dialog = true;
    }
}
