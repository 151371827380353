export function resizableGrid(table: HTMLElement): void {
    const row = table.getElementsByTagName('tr')[0];
    const cols = row ? row.children : undefined;
    if (!cols) return;

    table.style.overflow = 'hidden';

    const tableHeight = table.offsetHeight;

    for (let i = 0; i < cols.length; i++) {
        const div = createDiv(tableHeight);
        cols[i].appendChild(div);
        (cols[i] as HTMLElement).style.position = 'relative';
        setListeners(div);
    }
}

function setListeners(div: HTMLElement): void {
    let pageX: number | undefined;
    let curCol: HTMLElement | null | undefined;
    let nxtCol: HTMLElement | null | undefined;
    let curColWidth: number | undefined;
    let nxtColWidth: number | undefined;

    div.addEventListener('mousedown', function (e) {
        curCol = (e.target as any as HTMLElement)?.parentElement;
        if (curCol) {
            nxtCol = curCol.nextElementSibling as HTMLElement;

            pageX = e.pageX;

            const padding = paddingDiff(curCol);

            curColWidth = curCol.offsetWidth - padding;
            if (nxtCol) {
                nxtColWidth = nxtCol.offsetWidth - padding;
            }
        }
    });

    div.addEventListener('mouseover', (e) => {
        (e.target as any as HTMLElement).style.borderRight = '2px solid #ffa500';
    });

    div.addEventListener('mouseout', (e) => {
        (e.target as any as HTMLElement).style.borderRight = '';
    });

    document.addEventListener('mousemove', (e) => {
        if (curCol && pageX && curColWidth) {
            const diffX = e.pageX - pageX;
            curCol.style.width = curColWidth + diffX + 'px';

            if (nxtCol && nxtColWidth) {
                nxtCol.style.width = nxtColWidth - diffX + 'px';
            }
        }
    });

    document.addEventListener('mouseup', () => {
        curCol = undefined;
        nxtCol = undefined;
        pageX = undefined;
        curColWidth = undefined;
        nxtColWidth = undefined;
    });
}

function createDiv(height: number): HTMLElement {
    const div = document.createElement('div');
    div.style.top = '0';
    div.style.right = '0';
    div.style.width = '3px';
    div.style.position = 'absolute';
    div.style.cursor = 'col-resize';
    div.style.userSelect = 'none';
    div.style.height = height + 'px';
    return div;
}

function paddingDiff(col: Element): number {
    if (getStyleVal(col, 'box-sizing') == 'border-box') {
        return 0;
    }

    const padLeft = getStyleVal(col, 'padding-left');
    const padRight = getStyleVal(col, 'padding-right');

    return parseInt(padLeft) + parseInt(padRight);
}

function getStyleVal(elm: Element, css: string) {
    return window.getComputedStyle(elm, null).getPropertyValue(css);
}
