import { RouteConfig } from 'vue-router';

import IpView from '@/ui/pages/pe/incomplete-product/ip.view.vue';

import IPListPage from '@/ui/pages/pe/incomplete-product/list.page.vue';
import IPCreatePage from '@/ui/pages/pe/incomplete-product/create.page.vue';
import IPEditSiplacePage from '@/ui/pages/pe/incomplete-product/edit/siplace.page.vue';
import IPEditAsysPage from '@/ui/pages/pe/incomplete-product/edit/asys.page.vue';
import IPEditDekPage from '@/ui/pages/pe/incomplete-product/edit/dek.page.vue';

import AuditPage from '@/ui/pages/admin/audit.page.vue';
import IncompleteLineProductLockedListPage from '@/ui/pages/admin/incomplete-line-product-locked-list.page.vue';
import PeProductList from '@/ui/pages/pe/product.list.vue';

export const PE_ROUTES: RouteConfig[] = [
    {
        path: 'audit',
        name: 'pe.audit.list',
        component: AuditPage,
    },
    {
        path: 'products',
        component: PeProductList,
        name: 'pe.products.list',
    },
    {
        path: 'products-locked',
        component: IncompleteLineProductLockedListPage,
        name: 'pe.products-locked.list',
    },
    {
        path: 'incomplete-product',
        component: IpView,
        children: [
            {
                path: '',
                component: IPListPage,
                name: 'ip.list',
            },
            {
                path: 'create',
                name: 'ip.create',
                component: IPCreatePage,
            },
            {
                path: 'edit/siplace',
                name: 'ip.siplace.edit',
                component: IPEditSiplacePage,
            },
            {
                path: 'edit/asys',
                name: 'ip.asys.edit',
                component: IPEditAsysPage,
            },
            {
                path: 'edit/dek',
                name: 'ip.dek.edit',
                component: IPEditDekPage,
            },
        ],
    },
];
