


















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IncompleteLineProduct, IncompleteLineProductAddInfo } from '@/models/incompleteLineProduct';

@Component
export default class IncompleteLineProductAddInfoComponent extends Vue {
    @Prop() value!: IncompleteLineProduct;
    @Prop() open!: boolean;

    data: IncompleteLineProduct = {} as IncompleteLineProduct;

    items: IncompleteLineProductAddInfo[] = [];

    rules = {
        required: (value: string) => !!value || this.$t('Validation.Required'),
    };

    isLoading = false;

    @Watch('value', { immediate: true })
    fetchData(): void {
        this.isLoading = true;
        this.$odata
            .getById<IncompleteLineProduct>('incompleteLineProduct', this.value.id, { expand: { addInfos: {} } })
            .then((data) => {
                this.items = [...data.data.addInfos] as IncompleteLineProductAddInfo[];
                this.data = data.data;
            })
            .finally(() => (this.isLoading = false));
    }

    addField(): void {
        const index = this.items.length - 1;

        if (this.items.length && (this.items[index].key == null || this.items[index].value == null)) {
            //focusout from element => element validation
            (this.$refs[`key${index}`] as any)[0].blur();

            // jump next element, focus to element and focusout from element => element validation
            this.$nextTick(() => {
                const theField = this.$refs[`value${index}`] as any;
                theField[0].focus();
                (this.$refs[`value${index}`] as any)[0].blur();
            });
            return;
        }

        //new item
        const item = {} as IncompleteLineProductAddInfo;
        this.items.push(item);

        // focus to element new item
        this.$nextTick(() => {
            const theField = this.$refs[`key${index + 1}`] as any;
            theField[0].focus();
        });
    }

    get validItems(): any[] {
        return this.items.filter((f) => !!f.key && !!f.value);
    }

    saveInfo(): void {
        this.$odata.action('incompleteLineProduct', this.validItems, { key: this.data.id, func: 'SaveAddInfos' }).then(() => {
            this.$snackbar.success(this.$t('Page.IncompleteLineProduct.Dialog.NextProperty.Message.Success').toString());
            this.close();
        });
    }

    clearField(index: number): void {
        this.items.splice(index, 1);
    }

    close(): void {
        this.$emit('afterSaved');
    }
}
