
















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { LineProduct } from '@/models';

import SiplaceHeaderComponent from '@/ui/components/operator/headers/header-siplace.component.vue';

@Component({
    components: {
        'c-header-siplace': SiplaceHeaderComponent,
    },
})
export default class PdfSiplaceLineProductComponent extends Vue {
    @Prop({ required: true }) items!: LineProduct[];
    @Prop() lastChange!: LineProduct | null;

    packs: any[] = [];

    @Watch('items', { immediate: true })
    itemsPack() {
        this.packs = [];
        let pack: LineProduct[] = [];
        this.items.forEach((item, index) => {
            if (index > 0 && index % 15 == 0) {
                this.packs.push(pack);
                pack = [];
            }

            pack.push(item);

            if (index == this.items.length - 1) {
                this.packs.push(pack);
            }
        });
    }
}
