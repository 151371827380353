import { Module } from 'vuex';
import { alertActions } from './alert.action';
import { alertGetters } from './alert.getter';
import { alertMutations } from './alert.mutation';

import { AlertState } from './alert.state';

const alertStore: Module<AlertState, any> = {
    namespaced: true,
    state: {
        alert: null,
    },
    getters: alertGetters,
    mutations: alertMutations,
    actions: alertActions,
};

export default alertStore;
