










































































































import { Component, Vue } from 'vue-property-decorator';
import { Line } from '@/models/line';
import { ODataResult } from '@/models/odata.result';
import { debounce } from 'lodash';
import { User } from '@/models/user';
import RefresherComponent from '@/ui/components/refresher.component.vue';
import { GridOptions } from '@/models/vuetify';

@Component({
    components: {
        'c-refresher': RefresherComponent,
    },
})
export default class LineListPage extends Vue {
    firstLoad = true;
    isLoading = false;
    lines: ODataResult<Line> | null = null;
    selected: Line[] = [];
    dialogUser = false;
    users: User[] = [];

    refreshTime = 0;

    filter = {
        name: '',
        location: '',
    };

    headers = [
        { text: this.$t('Page.Admin.LineParam.List.Table.Name'), value: 'name', class: 'light-green--text subtitle-2' },
        { text: this.$t('Page.Admin.LineParam.List.Table.Location'), value: 'location', class: 'light-green--text subtitle-2' },
    ];

    options: GridOptions = {
        page: 1,
        itemsPerPage: +(localStorage.getItem('line_itemsPerPage') || 20),
        sortBy: ['name'],
        sortDesc: [false],
        multiSort: true,
    };

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-slot-machine-outline');
        this.$store.commit('SetTitle', this.$t('Page.Admin.LineParam.List.Title'));
    }

    changeDebounce!: () => void;
    created(): void {
        this.changeDebounce = debounce(() => {
            if (this.options.page != 1) {
                this.options.page = 1;
            } else {
                this.fetchData();
            }
        }, 400);
    }

    async fetchData(): Promise<void> {
        if (isNaN(+this.options.itemsPerPage) || !this.options.itemsPerPage) {
            this.options.itemsPerPage = 20;
        }

        if (this.firstLoad) {
            if (this.$route.query && Object.keys(this.$route.query).length) {
                this.filter.name = this.$route.query.name?.toString();
            }

            this.firstLoad = false;
        }

        this.isLoading = true;
        const filter: any[] = [];
        filter.push({ isActive: { eq: true } });
        const skip = (this.options.page - 1) * this.options.itemsPerPage;
        const top = this.options.itemsPerPage;

        if (this.filter.name) {
            filter.push({ 'toLower(name)': { contains: this.filter.name.toLowerCase() } });
        }

        if (this.filter.location) {
            filter.push({ 'toLower(location)': { contains: this.filter.location.toLowerCase() } });
        }

        const orderBy = this.options.sortBy.map((sort, index) => sort + (this.options.sortDesc[index] ? ' desc' : '')) as any;

        await this.$odata
            .getList<Line>('line', { top, skip, count: true, filter, orderBy })
            .then((data) => {
                this.lines = data.data;
            })
            .finally(() => {
                this.isLoading = false;

                this.refreshTime = 0;
                this.$nextTick(() => {
                    this.refreshTime = 5 * 60;

                    localStorage.setItem('line_itemsPerPage', this.options.itemsPerPage.toString());
                });
            });
    }

    rowClick(e: Line): void {
        this.$router.push({ name: 'line.detail', params: { id: e.id.toString() } });
    }

    onFilterSubmit(): void {
        this.fetchData();
    }

    beforeDeactive(): void {
        const ids = this.selected.map((s) => s.id);
        const filter: any[] = [];

        filter.push({ lineId: { in: ids } });

        this.$odata.getList<User>('user', { expand: { line: {} }, filter: filter }).then((data) => {
            this.users = data.data.value;
            if (data.data.value.length > 0) {
                this.dialogUser = true;
            } else {
                this.deactive();
            }
        });
    }

    deactive(): void {
        this.dialogUser = false;
        const ids = this.selected.map((s) => s.id);
        this.$odata.function('line', { func: { SetActiveState: { ids } } }).then(() => {
            this.fetchData();
            this.$snackbar.success(this.$t('Page.Admin.Line.Message.Remove').toString());
        });
    }
}
