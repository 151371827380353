import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import AppView from '@/ui/views/app.view.vue';
import AuthView from '@/ui/views/auth.view.vue';
import LayoutView from '@/ui/views/layout.view.vue';
import PeView from '@/ui/pages/pe/pe.view.vue';
import AdminView from '@/ui/pages/admin/admin.view.vue';
import ListView from '@/ui/views/list.view.vue';
import StationView from '@/ui/views/station.view.vue';

import HomePage from '@/ui/pages/home.page.vue';
import SheetPage from '@/ui/pages/sheets/sheet.page.vue';
import MpNplPage from '@/ui/pages/sheets/mpnpl.page.vue';
import KvalitaPage from '@/ui/pages/sheets/kvalita.page.vue';
import LogistikaPage from '@/ui/pages/sheets/logistika.page.vue';
import JidokaPage from '@/ui/pages/sheets/jidoka.page.vue';

/* ROUTES */
import { AUTH_ROUTES } from './auth.routes';
import { isAuth, isInGroup } from './guards/isAuth.guard';

import { PE_ROUTES } from './pe.routes';
import { ADMIN_ROUTES } from './admin.routes';
import { eGroup } from '@/models/group';

import { STATION_ROUTES } from './station.routes';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        component: LayoutView,
        children: [
            {
                path: '',
                name: 'home',
                component: HomePage,
            },
            {
                path: '/admin',
                component: AdminView,
                children: ADMIN_ROUTES,
                meta: {
                    groups: [eGroup.ADMIN],
                },
            },
            {
                path: '/list',
                component: ListView,
                children: [
                    {
                        path: 'station',
                        component: StationView,
                        children: STATION_ROUTES,
                    },
                ],
                meta: {
                    groups: [eGroup.KVALITA, eGroup.ADMIN],
                },
            },
            {
                path: '/pe',
                component: PeView,
                children: PE_ROUTES,
                meta: {
                    groups: [eGroup.PE],
                },
            },
            {
                path: '/app',
                component: AppView,
                children: [
                    {
                        path: 'sheet',
                        component: SheetPage,
                        name: 'operator.list',
                        meta: {
                            groups: [eGroup.OPERATOR],
                        },
                    },
                    {
                        path: 'mpnpl',
                        component: MpNplPage,
                        name: 'mpnpl.list',
                        meta: {
                            groups: [eGroup.MPNPL],
                        },
                    },
                    {
                        path: 'kvalita',
                        component: KvalitaPage,
                        name: 'kvalita.list',
                        meta: {
                            groups: [eGroup.KVALITA],
                        },
                    },
                    {
                        path: 'jidoka',
                        component: JidokaPage,
                        name: 'jidoka.list',
                        meta: {
                            groups: [eGroup.KVALITA],
                        },
                    },
                    {
                        path: 'logistika',
                        component: LogistikaPage,
                        name: 'logistika.list',
                        meta: {
                            groups: [eGroup.LOGISTIKA],
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/auth',
        component: AuthView,
        children: AUTH_ROUTES,
    },
    {
        path: '*',
        redirect: { name: 'home' },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    isAuth(to, from, next);
    isInGroup(to, from, next);

    next();
});

export default router;
