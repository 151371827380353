





































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class JidokaHelpDialog extends Vue {
    dialog = false;

    rules = Array.from({ length: 10 }).map((_, i) => {
        return { id: i + 1, text: this.$t('Jidoka.Help.Rule' + (i + 1).toString().padStart(2, '0')) };
    });
}
