import { Module } from 'vuex';
import { snackbarGetters } from './snackbar.getter';
import { snackbarMutations } from './snackbar.mutation';

import { SnackbarState } from './snackbar.state';

const snackbarStore: Module<SnackbarState, any> = {
    namespaced: true,
    state: {
        snackbar: null,
    },
    getters: snackbarGetters,
    mutations: snackbarMutations,
};

export default snackbarStore;
