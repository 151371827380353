




















































































import { LineProduct } from '@/models';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { allowConvert, properties } from '@/helpers/line-product';

import { get } from 'lodash';

@Component
export default class KvalitaDataDialog extends Vue {
    @Prop() item!: LineProduct;

    properties = properties;

    prop1 = properties.slice(0, Math.ceil(properties.length / 2));
    prop2 = properties.slice(Math.ceil(properties.length / 2));

    allowConvert = allowConvert;

    showRequired(prop: any) {
        if (this.item.line.name?.toUpperCase()?.startsWith('X')) {
            return !!prop.xLine;
        } else {
            return !!prop.critical;
        }
    }

    getValue(key: string): string {
        return get(this.item, key);
    }

    getTranslateKey(key: string): string {
        return (
            'Entity.LineProduct.' +
            key
                .split('.')
                .map((m) => m[0].toUpperCase() + m.slice(1))
                .join('.')
        );
    }
}
