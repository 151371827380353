import { format } from 'date-fns';

export function formatDate(date: string | Date, stringFormat = 'dd.MM.yyyy'): string | null {
    if (!date) {
        return null;
    }

    if (typeof date == 'string') {
        return format(new Date(date), stringFormat);
    }

    return format(date, stringFormat);
}
