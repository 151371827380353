





































































































































































































import { Jidoka } from '@/models/jidoka';
import { Line } from '@/models/line';
import { ODataResult } from '@/models/odata.result';
import { Station } from '@/models/station';
import { User } from '@/models/user';
import RefresherComponent from '@/ui/components/refresher.component.vue';
import { debounce } from 'lodash';
import { Vue, Component } from 'vue-property-decorator';

@Component({
    components: {
        'c-refresher': RefresherComponent,
    },
})
export default class AuditPage extends Vue {
    tab = 0;

    isLoading = false;
    dialog = false;

    refreshTime = 0;

    data: ODataResult<any> | null = null;
    audit: any | null = null;
    auditValues: any | null = null;

    filter: any = {};

    options = {
        page: 1,
        itemsPerPage: +(localStorage.getItem('audit_itemsPerPage') || 20),
        sortBy: ['timestamp'],
        sortDesc: [true],
        multiSort: true,
    };

    get tabHeaders(): any[] {
        if (this.tab) {
            return this.headers.filter((f) => !f.value.includes('lineProduct.product'));
        } else {
            return this.headers.filter((f) => f.value != 'group');
        }
    }

    headers = [
        { text: this.$t('Audit.Header.Action'), value: 'action', width: '200', class: 'light-green--text subtitle-2' },
        { text: this.$t('Audit.Header.Group'), value: 'group', width: '250', class: 'light-green--text subtitle-2' },
        { text: 'ID', value: 'entityId', width: 150, class: 'light-green--text subtitle-2' },
        { text: this.$t('Audit.Header.Entity'), value: 'entityName', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Product.SmtNumber'), value: 'lineProduct.product.smtNumber', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Line.Name'), value: 'lineProduct.line.name', class: 'light-green--text subtitle-2' },
        {
            text: this.$t('Entity.Audit.User'),
            value: 'user.displayName',
            width: '200',
            class: 'light-green--text subtitle-2',
        },
        { text: this.$t('Audit.Header.Date'), value: 'timestamp', width: '150', class: 'light-green--text subtitle-2' },
        { text: '', value: 'open', width: '100', align: 'center' },
    ];

    actions = [
        { text: this.$t('Audit.Action.Create'), value: 'Create' },
        { text: this.$t('Audit.Action.Update'), value: 'Update' },
        { text: this.$t('Audit.Action.Delete'), value: 'Delete' },
        { text: this.$t('Audit.Action.Convert'), value: 'Convert' },
    ];

    groups = [
        // { text: this.$t('Audit.Group.PE'), value: 'PE' },
        { text: this.$t('Audit.Group.MpNpl'), value: 'MpNpl' },
        { text: this.$t('Audit.Group.Quality'), value: 'Quality' },
        { text: this.$t('Audit.Group.Logistics'), value: 'Logistics' },
        { text: this.$t('Audit.Group.Admin'), value: 'Admin' },
    ];

    entities = [
        { text: this.$t('Audit.Entity.LineProduct'), value: 'LineProduct', pe: true },
        { text: this.$t('Audit.Entity.IncompleteLineProduct'), value: 'IncompleteLineProduct', pe: true, onlyPe: true },
        { text: this.$t('Audit.Entity.Line'), value: 'Line' },
        { text: this.$t('Audit.Entity.User'), value: 'User' },
        { text: this.$t('Audit.Entity.Group'), value: 'Group' },
        { text: this.$t('Audit.Entity.AdminSetting'), value: 'AdminSetting' },
        { text: this.$t('Audit.Entity.Alert'), value: 'Alert' },
    ];

    get filterEntities(): any[] {
        if (this.tab) {
            return this.entities.filter((f) => !f.onlyPe);
        }
        return this.entities.filter((f) => f.pe);
    }

    users: User[] = [];
    lines: Line[] = [];
    stations: Station[] = [];

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-history');
        this.$store.commit('SetTitle', this.$t('Page.Admin.Audit.Title'));

        this.$odata
            .getList<User>('user', { orderBy: ['displayName'], select: ['id', 'userName', 'displayName'] })
            .then((a) => (this.users = a.data.value));
        this.$odata
            .getList<Line>('line', { orderBy: ['name'], select: ['id', 'name', 'isActive'] })
            .then((a) => (this.lines = a.data.value));
        this.$odata.getList<Station>('station', { orderBy: ['name'], select: ['id', 'name'] }).then((a) => (this.stations = a.data.value));
    }

    changeDebounce!: () => void;
    created(): void {
        this.changeDebounce = debounce(() => {
            if (this.options.page != 1) {
                this.options.page = 1;
            } else {
                this.fetchData();
            }
        }, 400);
    }

    getLine(id: number): Line | undefined {
        return this.lines.find((f) => f.id == id);
    }

    getStation(id: number): string {
        return this.stations.find((f) => f.id == id)?.name ?? '';
    }

    fetchData(): void {
        this.isLoading = true;

        if (isNaN(+this.options.itemsPerPage) || !this.options.itemsPerPage) {
            this.options.itemsPerPage = 20;
        }

        const filter: any[] = [];

        const orderBy = this.options.sortBy.map(
            (sort, index) => sort.replaceAll('.', '/') + (this.options.sortDesc[index] ? ' desc' : '')
        ) as any;

        if (this.filter.action) filter.push({ action: { eq: this.filter.action } });
        if (!this.tab) filter.push({ group: { eq: 'PE' } });
        else filter.push({ group: { ne: 'PE' } });
        if (this.tab && this.filter.groups && this.filter.groups.length) filter.push({ group: { in: this.filter.groups } });
        if (this.filter.entities && this.filter.entities.length) filter.push({ entityName: { in: this.filter.entities } });

        if (this.filter.users && this.filter.users.length) filter.push({ userId: { in: this.filter.users } });
        if (this.filter.lines && this.filter.lines.length) filter.push({ lineProduct: { lineId: { in: this.filter.lines } } });
        if (this.filter.smtNumber)
            filter.push({ lineProduct: { product: { 'toLower(smtNumber)': { contains: this.filter.smtNumber.toLowerCase() } } } });
        if (this.filter.id)
            filter.push({
                or: [
                    { entityId: { eq: this.filter.id } },
                    { and: [{ entityId: { eq: null } }, { lineProductId: { eq: this.filter.id } }] },
                ],
            });

        this.$odata
            .getList<any>('audit', {
                expand: {
                    user: { select: ['id', 'displayName'] },
                    lineProduct: { expand: { product: { select: ['smtNumber'] }, line: { select: ['name'] } } },
                },
                filter,
                top: this.options.itemsPerPage,
                skip: this.options.itemsPerPage * (this.options.page - 1),
                orderBy,
                count: true,
            })
            .then((a) => (this.data = a.data))
            .finally(() => {
                this.isLoading = false;
                this.refreshTime = 0;
                this.$nextTick(() => {
                    this.refreshTime = 2 * 60;

                    localStorage.setItem('audit_itemsPerPage', this.options.itemsPerPage.toString());
                });
            });
    }

    showOpen(audit: any): boolean {
        if (audit.action == 'Delete') return false;

        if (audit.entityName == 'IncompleteLineProduct' && audit.action == 'Convert') {
            return false;
        }

        if (audit.entityName == 'Line' && !this.getLine(audit.entityId)?.isActive) {
            return false;
        }

        if (audit.entityName == 'Alert') {
            return false;
        }

        return true;
    }

    open(audit: any): void {
        if (audit.lineProduct) {
            this.$router.push({
                name: 'operator.list',
                query: {
                    productSmtNumber: audit.lineProduct.product.smtNumber,
                    line: audit.lineProduct.lineId,
                    isActive: audit.lineProduct.isActive,
                    isReleased: audit.lineProduct.isReleased,
                },
            });
        }

        if (audit.entityName == 'User') {
            this.$odata.getById<User>('user', audit.entityId).then((a) => {
                if (a.data) {
                    this.$router.push({
                        name: 'user.list',
                        query: {
                            userName: a.data.userName,
                            isActive: a.data.isActive as any,
                            isAllowed: a.data.isAllowed as any,
                        },
                    });
                }
            });
        }

        if (audit.entityName == 'Jidoka') {
            this.$odata.getById<Jidoka>('jidoka', audit.entityId).then((a) => {
                if (a.data) {
                    this.$router.push({
                        name: 'kvalita.list',
                        query: {
                            tab: 'jidoka',
                        },
                    });
                }
            });
        }

        if (audit.entityName == 'Station') {
            this.$odata.getById<Station>('station', audit.entityId).then((a) => {
                if (a.data) {
                    this.$router.push({
                        name: 'station.list',
                        query: {
                            name: a.data.name,
                        },
                    });
                }
            });
        }

        if (audit.entityName == 'Line') {
            this.$odata.getById<Line>('line', audit.entityId).then((a) => {
                if (a.data) {
                    this.$router.push({ name: 'line.list', query: { name: a.data.name } });
                }
            });
        }

        if (audit.entityName == 'IncompleteLineProduct') {
            this.$router.push({ name: 'ip.list', query: { id: audit.entityId } });
        }

        if (audit.entityName == 'Group') {
            this.$router.push({ name: 'group.list' });
        }
    }

    rowClick(e: any): void {
        if (!e) return;

        const oldValues = JSON.parse(e.oldValues);
        const newValues = JSON.parse(e.newValues);

        const result = {};
        Object.keys(oldValues).forEach((key) => {
            result[key] = [oldValues[key]];
        });

        Object.keys(newValues).forEach((key) => {
            if (result[key]) {
                result[key].push(newValues[key]);
            } else {
                result[key] = [null, newValues[key]];
            }
        });

        const keysForRemove: string[] = [];

        Object.keys(result).forEach((f) => {
            if (result[f][0] == result[f][1]) {
                keysForRemove.push(f);
            }
        });

        keysForRemove.forEach((f) => {
            delete result[f];
        });

        this.audit = e;
        this.auditValues = result;

        this.dialog = true;
    }
}
