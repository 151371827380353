





























import { LineProduct } from '@/models';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { allowConvert, getAllNullProperties, getTranslateKey } from '@/helpers/line-product';

@Component
export default class LineProductReleaseInfo extends Vue {
    @Prop({ required: true }) lineProduct!: LineProduct;

    get allowConvert(): boolean {
        return allowConvert(this.lineProduct);
    }

    getNullProperties(): string[] {
        return getAllNullProperties(this.lineProduct);
    }

    getTranslateKey = getTranslateKey;
}
