




















import { Station } from '@/models/station';
import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import { VForm } from '@/models/vuetify';

@Component
export default class StationDetailPage extends Vue {
    station: Station = {} as Station;
    isNew = false;

    @Ref('stationForm') stationForm!: VForm;

    @Watch('$route.params.id', { immediate: true })
    onChangeUrlParam(id: number): void {
        this.isNew = id > 0 ? false : true;

        if (!this.isNew) {
            this.fetchStation(id);
        }
    }

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-domain');
        this.$store.commit('SetTitle', this.$t('Page.Admin.Station.Detail.Title'));
    }

    async fetchStation(id: number): Promise<void> {
        await this.$odata.getById<Station>('station', +id).then((data) => {
            this.station = data.data;
        });
    }

    submitForm(): void {
        if (this.isNew) {
            this.createStation();
        } else {
            this.updateStation();
        }
    }

    createStation(): void {
        this.$odata.create<Station>('station', this.station).then(() => {
            this.$snackbar.success(this.$t('Page.Admin.Station.Detail.Message.Add').toString());
            this.$router.push({ name: 'station.list' });
        });
    }

    updateStation(): void {
        this.$odata.update<Station>('station', this.station.id, this.station).then(() => {
            this.$snackbar.success(this.$t('Page.Admin.Station.Detail.Message.Update').toString());
            this.$router.push({ name: 'station.list' });
        });
    }
}
