






























































































import { Component, Vue } from 'vue-property-decorator';
import { ODataResult } from '@/models/odata.result';
import { debounce } from 'lodash';
import { User } from '@/models/user';
import { Station } from '@/models/station';
import RefresherComponent from '@/ui/components/refresher.component.vue';
import { GridOptions } from '@/models/vuetify';

@Component({
    components: {
        'c-refresher': RefresherComponent,
    },
})
export default class StationListPage extends Vue {
    firstLoad = true;
    isLoading = false;
    stations: ODataResult<Station> | null = null;
    selected: Station[] = [];
    dialogUser = false;
    users: User[] = [];

    refreshTime = 0;

    filter = {
        name: '',
    };

    headers = [{ text: this.$t('Page.Admin.Station.List.Table.Name'), value: 'name', class: 'light-green--text subtitle-2' }];

    options: GridOptions = {
        page: 1,
        itemsPerPage: +(localStorage.getItem('station_itemsPerPage') || 20),
        sortBy: ['name'],
        sortDesc: [false],
        multiSort: true,
    };

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-domain');
        this.$store.commit('SetTitle', this.$t('Page.Admin.Station.List.Title'));
    }

    changeDebounce!: () => void;
    created(): void {
        this.changeDebounce = debounce(() => {
            if (this.options.page != 1) {
                this.options.page = 1;
            } else {
                this.fetchData();
            }
        }, 400);
    }

    async fetchData(): Promise<void> {
        if (isNaN(+this.options.itemsPerPage) || !this.options.itemsPerPage) {
            this.options.itemsPerPage = 20;
        }

        if (this.firstLoad) {
            if (this.$route.query && Object.keys(this.$route.query).length) {
                this.filter.name = this.$route.query.name?.toString();
            }

            this.firstLoad = false;
        }

        this.isLoading = true;
        const filter: any[] = [];
        // filter.push({ isActive: { eq: true } });
        const skip = (this.options.page - 1) * this.options.itemsPerPage;
        const top = this.options.itemsPerPage;

        filter.push({ isDeleted: { eq: false } });

        if (this.filter.name) {
            filter.push({ 'toLower(name)': { contains: this.filter.name.toLowerCase() } });
        }

        const orderBy = this.options.sortBy.map((sort, index) => sort + (this.options.sortDesc[index] ? ' desc' : '')) as any;

        await this.$odata
            .getList<Station>('station', { top, skip, count: true, filter, orderBy })
            .then((data) => {
                this.stations = data.data;
            })
            .finally(() => {
                this.isLoading = false;

                this.refreshTime = 0;
                this.$nextTick(() => {
                    this.refreshTime = 5 * 60;

                    localStorage.setItem('station_itemsPerPage', this.options.itemsPerPage.toString());
                });
            });
    }

    rowClick(e: Station): void {
        this.$router.push({ name: 'station.detail', params: { id: e.id.toString() } });
    }

    onFilterSubmit(): void {
        this.fetchData();
    }

    deactive(): void {
        this.dialogUser = false;

        Promise.all(this.selected.map((s) => this.$odata.remove('station', s.id))).then(() => {
            this.fetchData();
            this.$snackbar.success(this.$t('Page.Admin.Station.List.Message.Remove').toString());
        });
    }
}
