var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('c-confirm',{ref:"confirm"}),(_vm.USER)?_c('div',[(_vm.USER.userGroups.length > 1 || _vm.$inGroup([_vm.eGroup.ADMIN, _vm.eGroup.PE]))?_c('v-navigation-drawer',{attrs:{"mini-variant":_vm.isMini,"app":""},on:{"update:miniVariant":function($event){_vm.isMini=$event},"update:mini-variant":function($event){_vm.isMini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"height":"64"}},[_c('v-list-item',[(!_vm.isMini)?_c('v-img',{attrs:{"src":require("@/assets/continental_logo_yellow.svg"),"width":"160","max-height":"54"}}):_c('v-img',{attrs:{"max-width":"26","height":"30","src":require("@/assets/continental_logo_mini_yellow.svg")}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.isMini = !_vm.isMini}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)],1)],1),_c('v-divider'),_c('v-divider'),_c('v-list',{attrs:{"nav":"","dense":""}},[(_vm.$inGroup([_vm.eGroup.OPERATOR, _vm.eGroup.MPNPL, _vm.eGroup.KVALITA, _vm.eGroup.LOGISTIKA]))?_c('v-subheader',[_vm._v(" "+_vm._s(_vm.isMini ? _vm.$t('SideMenu.SubHeader.SheetsShort') : _vm.$t('SideMenu.SubHeader.Sheets'))+" ")]):_vm._e(),(_vm.$inGroup([_vm.eGroup.OPERATOR, _vm.eGroup.MPNPL, _vm.eGroup.KVALITA, _vm.eGroup.LOGISTIKA]))?_c('v-list-item-group',{attrs:{"active-class":"text--accent-4"}},[_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.isMini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$inGroup(_vm.eGroup.OPERATOR))?_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":{ name: 'operator.list' }}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clipboard-list-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('SideMenu.Sheets.Operator')))])],1):_vm._e()]}}],null,false,979013014)},[_c('span',[_vm._v(_vm._s(_vm.$t('SideMenu.Sheets.Operator')))])]),_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.isMini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$inGroup(_vm.eGroup.MPNPL))?_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":{ name: 'mpnpl.list' }}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-head-check-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('SideMenu.Sheets.MpNpl')))])],1):_vm._e()]}}],null,false,2994805433)},[_c('span',[_vm._v(_vm._s(_vm.$t('SideMenu.Sheets.MpNpl')))])]),_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.isMini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$inGroup(_vm.eGroup.KVALITA))?_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":{ name: 'kvalita.list' }}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-star-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('SideMenu.Sheets.Quality')))]),_vm._m(0),(_vm.$store.getters['QualityCount'])?_c('v-list-item-action',[_c('v-badge',{attrs:{"inline":"","content":_vm.$store.getters['QualityCount']}})],1):_vm._e()],1):_vm._e()]}}],null,false,3401799921)},[_c('span',[_vm._v(_vm._s(_vm.$t('SideMenu.Sheets.Quality')))])]),_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.isMini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$inGroup(_vm.eGroup.KVALITA, _vm.eGroup.ADMIN))?_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":{ name: 'station.list' }}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-domain")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('SideMenu.Admin.Stations')))])],1):_vm._e()]}}],null,false,3122829923)},[_c('span',[_vm._v(_vm._s(_vm.$t('SideMenu.Admin.Stations')))])]),(false)?_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.isMini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$inGroup(_vm.eGroup.KVALITA))?_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":{ name: 'jidoka.list' }}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-star-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('SideMenu.Sheets.Jidoka')))])],1):_vm._e()]}}],null,false,2021794157)},[_c('span',[_vm._v(_vm._s(_vm.$t('SideMenu.Sheets.Jidoka')))])]):_vm._e(),_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.isMini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$inGroup(_vm.eGroup.LOGISTIKA))?_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":{ name: 'logistika.list' }}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-truck-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('SideMenu.Sheets.Logistics')))])],1):_vm._e()]}}],null,false,1613162397)},[_c('span',[_vm._v(_vm._s(_vm.$t('SideMenu.Sheets.Logistics')))])])],1):_vm._e(),(_vm.$inGroup(_vm.eGroup.PE))?_c('v-subheader',[_vm._v("PE")]):_vm._e(),(_vm.$inGroup(_vm.eGroup.PE))?_c('v-list-item-group',{attrs:{"active-class":"text--accent-4"}},[_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.isMini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":{ name: 'ip.list' }}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clipboard-edit-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('SideMenu.PE.IncompleteProductsList')))])],1)]}}],null,false,1000787066)},[_c('span',[_vm._v(_vm._s(_vm.$t('SideMenu.PE.IncompleteProductsList')))])]),_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.isMini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":{ name: 'pe.products.list' }}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clipboard-text-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('SideMenu.PE.ProductsList')))])],1)]}}],null,false,852933551)},[_c('span',[_vm._v(_vm._s(_vm.$t('SideMenu.PE.ProductsList')))])]),_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.isMini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":{ name: 'pe.audit.list' }}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-history")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('SideMenu.Admin.Audit')))])],1)]}}],null,false,2226959684)},[_c('span',[_vm._v(_vm._s(_vm.$t('SideMenu.Admin.Audit')))])]),_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.isMini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":{ name: 'pe.products-locked.list' }}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-lock-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('SideMenu.Admin.ProductsLocked')))]),_vm._m(1),(_vm.$store.getters['LockedProductCount'])?_c('v-list-item-action',[_c('v-badge',{attrs:{"inline":"","content":_vm.$store.getters['LockedProductCount']}})],1):_vm._e()],1)]}}],null,false,3598380145)},[_c('span',[_vm._v(_vm._s(_vm.$t('SideMenu.Admin.ProductsLocked')))])])],1):_vm._e(),(_vm.$inGroup(_vm.eGroup.ADMIN))?_c('v-subheader',[_vm._v(" "+_vm._s(_vm.isMini ? _vm.$t('SideMenu.SubHeader.AdminShort') : _vm.$t('SideMenu.SubHeader.Admin'))+" ")]):_vm._e(),(_vm.$inGroup(_vm.eGroup.ADMIN))?_c('v-list-item-group',{attrs:{"active-class":"text--accent-4"}},[_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.isMini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":{ name: 'user.list' }}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-multiple-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('SideMenu.Admin.User')))]),_vm._m(2),(_vm.$store.getters['RequestCount'])?_c('v-list-item-action',[_c('v-badge',{attrs:{"inline":"","content":_vm.$store.getters['RequestCount']}})],1):_vm._e()],1)]}}],null,false,1060996755)},[_c('span',[_vm._v(_vm._s(_vm.$t('SideMenu.Admin.User')))])]),_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.isMini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":{ name: 'line.list' }}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-slot-machine-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('SideMenu.Admin.Lines')))])],1)]}}],null,false,983368367)},[_c('span',[_vm._v(_vm._s(_vm.$t('SideMenu.Admin.Lines')))])]),_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.isMini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":{ name: 'group.list' }}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-group-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('SideMenu.Admin.Groups')))])],1)]}}],null,false,3327302832)},[_c('span',[_vm._v(_vm._s(_vm.$t('SideMenu.Admin.Groups')))])]),_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.isMini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":{ name: 'admin.products-locked.list' }}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-lock-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('SideMenu.Admin.ProductsLocked')))]),_vm._m(3),(_vm.$store.getters['LockedProductCount'])?_c('v-list-item-action',[_c('v-badge',{attrs:{"inline":"","content":_vm.$store.getters['LockedProductCount']}})],1):_vm._e()],1)]}}],null,false,495290153)},[_c('span',[_vm._v(_vm._s(_vm.$t('SideMenu.Admin.ProductsLocked')))])]),_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.isMini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":{ name: 'alert.list' }}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-car-brake-alert")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('SideMenu.Admin.Alert')))])],1)]}}],null,false,640611676)},[_c('span',[_vm._v(_vm._s(_vm.$t('SideMenu.Admin.Alert')))])]),_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.isMini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":{ name: 'admin.audit.list' }}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-history")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('SideMenu.Admin.Audit')))])],1)]}}],null,false,4153919038)},[_c('span',[_vm._v(_vm._s(_vm.$t('SideMenu.Admin.Audit')))])]),_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.isMini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":{ name: 'setting.list' }}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cog-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('SideMenu.Admin.Setting')))])],1)]}}],null,false,2789222491)},[_c('span',[_vm._v(_vm._s(_vm.$t('SideMenu.Admin.Setting')))])])],1):_vm._e()],1)],1):_vm._e(),(_vm.ALERT)?_c('v-system-bar',{attrs:{"app":"","height":"50"}},[_c('c-alert')],1):_vm._e(),_c('v-app-bar',{attrs:{"app":""}},[(!_vm.drawer && (_vm.USER.userGroups.length > 1 || _vm.$inGroup([_vm.eGroup.ADMIN], _vm.eGroup.PE)))?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),(_vm.USER.userGroups.length < 2 && !_vm.$inGroup([_vm.eGroup.ADMIN]))?_c('v-img',{attrs:{"src":require("@/assets/continental_logo_yellow.svg"),"max-width":"171","max-height":"54"}}):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-toolbar-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$store.getters.Icon))]),_vm._v(" "+_vm._s(_vm.$store.getters.Title))],1):_vm._e(),_c('v-spacer'),_c('v-list',{staticClass:"py-0",attrs:{"color":"#1e1e1e"}},[(_vm.USER)?_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"medium":"","color":"#ffa500"}},[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(_vm.USER.displayName)+" ")]),_c('v-list-item-subtitle',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.$t('Global.LogoutAfter'))+": "+_vm._s(_vm.countDown)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"font-size":"16px","color":"gray"},on:{"click":_vm.REFRESH}},'v-icon',attrs,false),on),[_vm._v(" mdi-refresh ")])]}}],null,false,2580041356)},[_c('span',[_vm._v(_vm._s(_vm.$t('Global.RefreshSession')))])])],1)],1),_c('v-list-item-action',[_c('v-row',[_c('c-lang'),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"info"},on:{"click":function($event){_vm.helpDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help")])],1)]}}],null,false,632782571)},[_c('span',[_vm._v(_vm._s(_vm.$t('Global.Help')))])]):_vm._e(),(_vm.USER.isLdap && _vm.$vuetify.breakpoint.mdAndUp)?[_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"attrs":attrs,"icon":"","color":"error"},on:{"click":_vm.deleteAccount}},on),[_c('v-icon',[_vm._v("mdi-account-off")])],1)]}}],null,false,748939408)},[_c('span',[_vm._v(_vm._s(_vm.$t('Global.DeleteAccount')))])]),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}})]:_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.LOGOUT}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-export")])],1)]}}],null,false,2658551054)},[_c('span',[_vm._v(_vm._s(_vm.$t('Global.Logout')))])])],2)],1)],1):_vm._e()],1)],1),_c('v-footer',{attrs:{"app":"","inset":""}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Admin: "),_c('a',{attrs:{"href":"mailto:ekaterina.koshkina@continental.com"}},[_vm._v("ekaterina.koshkina@continental.com")])]),_c('v-spacer'),_c('span',{staticClass:"mr-1 lighten-3",class:{
                    'red--text': _vm.$store.getters.AppStage === 'TEST',
                    'primary--text': _vm.$store.getters.AppStage === 'PROD',
                }},[_vm._v(_vm._s(_vm.$store.getters.AppStage))]),_c('span',[_vm._v("v. "+_vm._s(_vm.$store.getters.AppVersion))])],1),_c('v-main',[(_vm.USER)?[_c('router-view')]:_vm._e()],2),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.requestDeleteDialog),callback:function ($$v) {_vm.requestDeleteDialog=$$v},expression:"requestDeleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('Global.DeleteAccountConfirm'))+" ")]),_c('v-form',{ref:"form",staticClass:"pa-2",attrs:{"lazy-validation":""}},[_c('c-text-area',{attrs:{"label":_vm.$t('Page.Admin.User.List.Table.Reason'),"required":""},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('c-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":_vm.deleteAccountConfirm}},[_vm._v(_vm._s(_vm.$t('Global.Send')))]),_c('c-btn',{attrs:{"color":"error"},on:{"click":_vm.deleteAccountCancel}},[_vm._v(_vm._s(_vm.$t('Global.Cancel')))])],1)],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.helpDialog),callback:function ($$v) {_vm.helpDialog=$$v},expression:"helpDialog"}},[_c('c-help',{on:{"close":function($event){_vm.helpDialog = false}}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v(_vm._s(_vm.initQualityCount()))])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v(_vm._s(_vm.initLockedProductCount()))])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v(_vm._s(_vm.initRequestsCount()))])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v(_vm._s(_vm.initLockedProductCount()))])}]

export { render, staticRenderFns }