






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class YesNoComponent extends Vue {
    @Prop({ type: Boolean }) value?: boolean;
}
