


































































































































































































































































import { IncompleteLineProduct, IncompleteLineProductLocked } from '@/models';
import { ODataResult } from '@/models/odata.result';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { debounce } from 'lodash';
import { User } from '@/models/user';

import IncompleteLineProductAddInfoComponent from '@/ui/components/incomplete-line-product-info.component.vue';
import IncompleteLineProductLockedComponent from '@/ui/components/incomplete-line-product-locked.component.vue';
import RefresherComponent from '@/ui/components/refresher.component.vue';
import IlpConvertInfo from '@/ui/components/incomplete-line-product-convert-info.component.vue';
import IlpHelperInfo from '@/ui/components/incomplete-line-product-helper.component.vue';
import { allowConvert } from '@/helpers/ilp';

@Component({
    components: {
        'c-incomplete-line-product-add-info': IncompleteLineProductAddInfoComponent,
        'c-incomplete-line-product-locked': IncompleteLineProductLockedComponent,
        'c-refresher': RefresherComponent,
        'c-ilp-convert-info': IlpConvertInfo,
        'c-ilp-helper': IlpHelperInfo,
    },
})
export default class IPListPage extends Vue {
    tabNames = { [0]: 'new', [1]: 'siplace', [2]: 'dek', [3]: 'asys' };
    filter: any = {};

    action: null | 'edit' | 'delete' | 'convert' = null;

    firstLoad = true;
    isLoading = false;
    onlySelected = false;
    tab = 0;
    refreshTime = 0;

    dialogInfo = false;
    dialogLocked = false;
    item: IncompleteLineProduct | null = null;
    editType = '';

    selected: IncompleteLineProduct[] = [];

    lockedItems: IncompleteLineProductLocked[] = [];

    search = '';

    get canConvert(): boolean {
        return !!this.selected.length && this.selected.filter((f) => allowConvert(f)).length === this.selected.length;
    }

    data: ODataResult<IncompleteLineProduct> = { value: [], '@odata.count': 0 };

    users: User[] = [];

    options = {
        page: 1,
        itemsPerPage: +(localStorage.getItem('pe_list_itemsPerPage') || 20),
        sortBy: ['id'],
        sortDesc: [true],
        multiSort: true,
        mustSort: false,
    };

    get headers(): any[] {
        return [
            ...(this.tab > 0 ? this.baseHeaders : []),
            ...this.typeHeaders[this.tab],
            { value: 'actions', sortable: false, width: '100', align: 'center' },
        ];
    }

    baseHeaders = [
        {
            text: 'ID',
            value: 'id',
            class: 'light-green--text subtitle-2',
            width: '100',
        },
        {
            text: this.$t('Page.Pe.Ilp.List.Columns.CanConvert'),
            value: 'canConvert',
            class: 'light-green--text subtitle-2',
            sortable: false,
            width: '50',
        },
        {
            text: this.$t('Entity.IncompleteLineProduct.ProductSmtNumber'),
            value: 'productSmtNumber',
            class: 'light-green--text subtitle-2',
        },
        {
            text: this.$t('Entity.IncompleteLineProduct.ProductName'),
            value: 'productName',
            class: 'light-green--text subtitle-2',
        },
        {
            text: this.$t('Entity.IncompleteLineProduct.LineName'),
            value: 'line.name',
            class: 'light-green--text subtitle-2',
        },
    ];

    typeHeaders = {
        0: [
            {
                text: 'ID',
                value: 'id',
                class: 'light-green--text subtitle-2',
                width: '150',
            },
            {
                text: this.$t('Page.Pe.Ilp.List.Columns.CanConvert'),
                value: 'canConvert',
                class: 'light-green--text subtitle-2',
                sortable: false,
                width: '50',
            },
            {
                text: this.$t('Entity.IncompleteLineProduct.ProductSmtNumber'),
                value: 'productSmtNumber',
                class: 'light-green--text subtitle-2',
            },
            {
                value: 'creatorUser.displayName',
                text: this.$t('Entity.IncompleteLineProduct.CreatorUser'),
                class: 'light-green--text subtitle-2',
            },
        ],
        1: [
            {
                text: this.$t('Entity.IncompleteLineProduct.ProductSide'),
                value: 'productSide',
                class: 'light-green--text subtitle-2',
            },
            {
                text: this.$t('Entity.IncompleteLineProduct.DpsNumber'),
                value: 'dpsNumber',
                class: 'light-green--text subtitle-2',
            },
            {
                text: this.$t('Entity.IncompleteLineProduct.ProductThtNumber'),
                value: 'productThtNumber',
                class: 'light-green--text subtitle-2',
            },
            {
                text: this.$t('Entity.IncompleteLineProduct.SetupName'),
                value: 'setupName',
                class: 'light-green--text subtitle-2',
            },
            {
                text: this.$t('Entity.IncompleteLineProduct.ProductLaserProg'),
                value: 'productLaserProg',
                class: 'light-green--text subtitle-2',
            },
            {
                text: this.$t('Entity.IncompleteLineProduct.ProductOvenProg'),
                value: 'productOvenProg',
                class: 'light-green--text subtitle-2',
                f: true,
            },
            {
                text: this.$t('Entity.IncompleteLineProduct.Norm'),
                value: 'norm',
                class: 'light-green--text subtitle-2',
            },
            {
                value: 'lastUpdateUser.displayName',
                text: this.$t('Entity.IncompleteLineProduct.UpdatorUser'),
                class: 'light-green--text subtitle-2',
            },
        ],
        2: [
            {
                text: this.$t('Entity.IncompleteLineProduct.DpsNumber'),
                value: 'dpsNumber',
                class: 'light-green--text subtitle-2',
            },
            { text: this.$t('Entity.IncompleteLineProduct.DekProgram'), value: 'dekProgram', class: 'light-green--text subtitle-2' },
            { text: this.$t('Entity.IncompleteLineProduct.DekPaste'), value: 'dekPaste', class: 'light-green--text subtitle-2', f: true },
            { text: this.$t('Entity.IncompleteLineProduct.DekSqueegee'), value: 'dekSqueegee', class: 'light-green--text subtitle-2' },
            { text: this.$t('Entity.IncompleteLineProduct.DekTemplate'), value: 'dekTemplate', class: 'light-green--text subtitle-2' },
            // {
            //     text: this.$t('Entity.IncompleteLineProduct.DekTemplateThick'),
            //     value: 'dekTemplateThick',
            //     class: 'light-green--text subtitle-2',
            // },
            { text: this.$t('Entity.IncompleteLineProduct.DekTooling'), value: 'dekTooling', class: 'light-green--text subtitle-2' },
            // { text: this.$t('Entity.IncompleteLineProduct.DekToolingPos'), value: 'dekToolingPos', class: 'light-green--text subtitle-2' },
            {
                text: this.$t('Entity.IncompleteLineProduct.DekKohYoungAoi'),
                value: 'dekKohYoungAoi',
                class: 'light-green--text subtitle-2',
            },
            {
                text: this.$t('Entity.IncompleteLineProduct.DekKohYoungSupport'),
                value: 'dekKohYoungSupport',
                class: 'light-green--text subittle-2',
            },
            {
                value: 'updatorUser.displayName',
                text: this.$t('Entity.IncompleteLineProduct.UpdatorUser'),
                class: 'light-green--text subtitle-2',
            },
        ],
        3: [
            {
                text: this.$t('Entity.IncompleteLineProduct.ProductSide'),
                value: 'productSide',
                class: 'light-green--text subtitle-2',
            },
            {
                text: this.$t('Entity.IncompleteLineProduct.DpsNumber'),
                value: 'dpsNumber',
                class: 'light-green--text subtitle-2',
            },
            {
                text: this.$t('Entity.IncompleteLineProduct.DpsMagNumber'),
                value: 'dpsMagNumber',
                class: 'light-green--text subtitle-2',
            },
            { text: this.$t('Entity.IncompleteLineProduct.AsysFront'), value: 'asysFront', class: 'light-green--text subtitle-2' },
            { text: this.$t('Entity.IncompleteLineProduct.AsysEnd'), value: 'asysEnd', class: 'light-green--text subtitle-2' },
            {
                value: 'updatorUser.displayName',
                text: this.$t('Entity.IncompleteLineProduct.UpdatorUser'),
                class: 'light-green--text subtitle-2',
            },
        ],
    };

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-clipboard-edit-outline');
        this.$store.commit('SetTitle', this.$t('Page.Pe.Ilp.List.Title'));

        this.$odata
            .getList<User>('user', {
                select: ['id', 'displayName'],
                filter: {
                    userGroups: { any: { group: { appName: { eq: 'PE' } } } },
                },
                orderBy: ['displayName'],
            })
            .then((data) => {
                this.users = data.data.value;
            });

        var created = sessionStorage.getItem('ilp-created');
        if (created) {
            try {
                var data = JSON.parse(created);
                this.selected = [
                    ...data.map((m) => {
                        return {
                            id: m,
                        };
                    }),
                ];
            } catch (e) {
                console.log(e);
            }

            sessionStorage.removeItem('ilp-created');
        }
    }

    @Watch('$route.query.tab', { immediate: true })
    onChangeQueryTab(): void {
        Object.keys(this.tabNames).forEach((key) => {
            if (this.$route.query.tab === this.tabNames[key]) {
                this.tab = +key;
                return;
            }
        });
    }

    @Watch('tab')
    onChangeTab(newValue: number, oldValue: number): void {
        this.$nextTick(() => {
            this.onlySelected = false;

            this.$router.replace({ query: { tab: this.tabNames[this.tab] } });

            if (!newValue || (!oldValue && newValue)) {
                this.selected = [];
                if (this.options.page !== 1) {
                    this.options.page = 1;
                    return;
                }
            }

            this.fetchData();
        });
    }

    changeDebounce!: () => void;
    created(): void {
        this.changeDebounce = debounce(() => {
            if (this.options.page != 1) {
                this.options.page = 1;
            } else {
                this.fetchData();
            }
        }, 400);
    }

    fetchData(): void {
        if (isNaN(+this.options.itemsPerPage) || !this.options.itemsPerPage) {
            this.options.itemsPerPage = 20;
        }

        if (this.firstLoad) {
            if (this.$route.query.id) this.filter.id = +this.$route.query.id;
            if (this.$route.query.productSmtNumber) this.filter.productSmtNumber = this.$route.query.productSmtNumber;

            this.firstLoad = false;
        }

        this.isLoading = true;

        const skip = (this.options.page - 1) * this.options.itemsPerPage;
        const orderBy = this.options.sortBy.map(
            (sort, index) => sort.replaceAll('.', '/') + (this.options.sortDesc[index] ? ' desc' : '')
        ) as any;

        const filter: any[] = [];

        if (!this.tab) {
            filter.push({ helpSiplace: false });
            filter.push({ helpDek: false });
            filter.push({ helpAsys: false });
        } else {
            filter.push({
                or: [{ helpSiplace: true }, { helpDek: true }, { helpAsys: true }],
            });
        }

        if (this.filter.id) filter.push({ id: { eq: +this.filter.id } });
        if (this.filter.productSmtNumber)
            filter.push({ 'toLower(productSmtNumber)': { contains: this.filter.productSmtNumber.toLowerCase() } });

        if (this.tab) {
            if (this.filter.lines && this.filter.lines.length) filter.push({ lineId: { in: this.filter.lines } });
        }

        if (this.filter.creatorUsers && this.filter.creatorUsers.length) {
            filter.push({ creatorUserId: { in: this.filter.creatorUsers } });
        }

        if (this.tab) {
            if (this.filter.updatorUsers && this.filter.updatorUsers) {
                filter.push({ lastUpdateUserId: { in: this.filter.updatorUsers } });
            }
        }

        const finalFilter: any[] = [];
        if (this.onlySelected) {
            finalFilter.push({ id: { in: [0, ...this.selected.map((m) => m.id)] } });
        } else {
            if (this.selected.length && filter.length) {
                finalFilter.push({ or: [filter, [{ id: { in: this.selected.map((m) => m.id) } }]] });
            } else {
                finalFilter.push(...filter);
            }
        }

        this.$odata
            .getList<IncompleteLineProduct>('incompleteLineProduct', {
                top: this.options.itemsPerPage > 0 ? this.options.itemsPerPage : 100,
                count: true,
                filter: finalFilter,
                expand: {
                    line: { select: ['id', 'name'] },
                    creatorUser: { select: ['id', 'displayName'] },
                    lastUpdateUser: { select: ['id', 'displayName'] },
                    updatorUser: { select: ['id', 'displayName'] },
                    addInfos: { select: ['id'] },
                },
                skip,
                orderBy,
                search: this.search ? '"' + this.search + '"' : undefined,
            })
            .then((a) => {
                this.data = a.data;

                this.$nextTick(() => {
                    const sIds = sessionStorage.getItem('ip-edit');
                    if (sIds) {
                        const ids = sIds
                            .split(';')
                            .map((m) => +m)
                            .filter((f) => !isNaN(f));
                        this.selected = this.data.value.filter((f) => ids.includes(f.id));
                        sessionStorage.removeItem('ip-edit');
                    }
                });
            })
            .finally(() => {
                this.isLoading = false;
                this.refreshTime = 0;

                this.$nextTick(() => {
                    this.refreshTime = 5 * 60;

                    this.$store.dispatch('InitLockedProductCount');
                });

                localStorage.setItem('pe_list_itemsPerPage', this.options.itemsPerPage.toString());
            });
    }

    setColors(className: string): void {
        var items = document.getElementsByClassName(className);

        for (var i = 0; i < items.length; i++) {
            const parent = items[i].parentElement;
            if (parent) {
                parent.className = 'cell-' + className.replaceAll('tmp-', '');
            }
        }
    }

    confirmLocked(): void {
        switch (this.action) {
            case 'edit':
                this.edit();
                break;
            case 'delete':
                this.remove();
                break;
            case 'convert':
                this.convert();
                break;
        }
    }

    editBefore(edit: string): void {
        this.editType = edit;
        this.action = 'edit';
        if (this.selected.length) {
            this.$odata
                .function<any>('incompleteLineProduct', {
                    func: { BeforeEdit: { ids: this.selected.map((m) => m.id) } },
                    expand: { locked: {} },
                })
                .then((data) => {
                    this.lockedItems = data.data.locked ?? [];
                    if (this.lockedItems.length) {
                        this.dialogLocked = true;
                    } else {
                        this.edit();
                    }
                });
        }
    }

    edit(): void {
        const lockedIds = this.lockedItems.map((m) => m.id);
        const data = this.selected
            .filter((f) => lockedIds.indexOf(f.id) == -1)
            .map((m) => m.id)
            .join(';');

        if (data.length) {
            sessionStorage.setItem('ip-edit', data);
            this.$router.push({ name: `ip.${this.editType}.edit` });
        } else {
            this.dialogLocked = false;
            this.$nextTick(() => {
                this.$snackbar.error('Page.Pe.Ilp.List.Messages.NoDataForEdit');
            });

            this.action = null;
        }
    }

    beforeConvert(): void {
        if (this.selected.length && confirm(this.$t('Page.Pe.Ilp.List.Alerts.Convert').toString())) {
            this.action = 'convert';

            this.$odata
                .function<any>('incompleteLineProduct', {
                    func: { BeforeEdit: { ids: this.selected.map((m) => m.id) } },
                    expand: { locked: {} },
                })
                .then((data) => {
                    this.lockedItems = data.data.locked ?? [];
                    if (this.lockedItems.length) {
                        this.dialogLocked = true;
                    } else {
                        this.convertValidation();
                    }
                });
        }
    }

    forConvert: number[] = [];
    exists: any[] = [];
    dialogConvertValidation = false;
    convertSelected: any[] = [];

    convertValidation(): void {
        const lockedIds = this.lockedItems.map((m) => m.id);
        const ids = this.selected.filter((f) => lockedIds.indexOf(f.id) == -1).map((m) => m.id);

        if (ids.length) {
            this.$odata.function<any>('incompleteLineProduct', { func: { convertValidation: { ids } } }).then((d) => {
                if (d.data.exists.length) {
                    this.exists = [...d.data.exists];
                    this.convertSelected = [...d.data.exists];
                    this.forConvert = d.data.free;

                    this.dialogConvertValidation = true;
                } else {
                    this.forConvert = d.data.free;
                    this.convert();
                }
            });
        } else {
            this.forConvert = [];
            this.convert();
        }
    }

    convertConfirm(): void {
        this.convertSelected.forEach((element) => {
            this.forConvert.push(element.ilp);
        });

        this.dialogConvertValidation = false;
        this.convert();
    }

    convert(): void {
        if (this.forConvert.length) {
            this.$odata.function('incompleteLineProduct', { func: { convert: { ids: this.forConvert } } }).then(() => {
                this.$snackbar.success('Page.Pe.Ilp.List.Messages.Converted');

                this.$nextTick(() => {
                    this.selected = [];
                    this.fetchData();
                });
            });
        } else {
            this.dialogLocked = false;
            this.action = null;
            this.$nextTick(() => {
                this.$snackbar.error('Page.Pe.Ilp.List.Messages.NoDataForConvert');
            });
        }
    }

    beforeRemove(): void {
        if (this.selected.length && confirm(this.$t('Page.Pe.Ilp.List.Alerts.Remove').toString())) {
            this.action = 'delete';
            this.$odata
                .function<any>('incompleteLineProduct', {
                    func: { BeforeEdit: { ids: this.selected.map((m) => m.id) } },
                    expand: { locked: {} },
                })
                .then((data) => {
                    this.lockedItems = data.data.locked ?? [];
                    if (this.lockedItems.length) {
                        this.dialogLocked = true;
                    } else {
                        this.remove();
                    }
                });
        }
    }

    remove(): void {
        const lockedIds = this.lockedItems.map((m) => m.id);
        const data = this.selected.filter((f) => lockedIds.indexOf(f.id) == -1).map((m) => m.id);

        if (data.length) {
            this.$odata.function('incompleteLineProduct', { func: { remove: { ids: data } } }).then(() => {
                this.$snackbar.success('Page.Pe.Ilp.List.Messages.Deleted');

                this.$nextTick(() => {
                    this.selected = [];
                    this.fetchData();
                });
            });
        } else {
            this.dialogLocked = false;
            this.action = null;
            this.$nextTick(() => {
                this.$snackbar.error('Page.Pe.Ilp.List.Messages.NoDataForDelete');
            });
        }
    }

    showDialog(item: IncompleteLineProduct): void {
        this.item = null;

        this.$nextTick(() => {
            this.item = item;
            this.dialogInfo = true;
        });
    }

    onlySelectedClick(): void {
        this.onlySelected = !this.onlySelected;
        this.$nextTick(this.fetchData);
    }
}
