
























































































































































import { IncompleteLineProduct, LineProduct } from '@/models';
import { flatMap } from 'lodash';
import { TranslateResult } from 'vue-i18n';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class IPCreatePage extends Vue {
    dataExcel = '';
    isSaving = false;
    dialog = false;
    notFoundDialog = false;
    notFoundList: any[] = [];

    loadingExists = false;

    existIncompleteLineProduct: IncompleteLineProduct[] = [];
    existLineProduct: LineProduct[] = [];

    clipboardError = false;

    get validItems(): any[] {
        return this.items.filter((f) => !!f.smt);
    }

    get clipboard(): boolean {
        return !!navigator.clipboard;
    }

    items: { smt: string; delete: boolean }[] = [{ smt: '', delete: false }];

    rules = {
        required: (value: string): boolean | TranslateResult => !!value || this.$t('Validation.Required'),
    };

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-wrench-outline');
        this.$store.commit('SetTitle', 'Přidání produktů'); // ToDo: translate
    }

    addField(index: number): void {
        (this.$refs[`inputField${index}`] as any)[0].blur();

        const value = (this.$refs[`inputField${index}`] as any)[0].value;

        if (value && value.trim().length > 0) {
            this.items.push({ smt: '', delete: false });
            this.$nextTick(() => {
                const theField = this.$refs[`inputField${index + 1}`] as any;
                theField[0].focus();
            });
        }
    }

    clearField(index: number): void {
        this.items.splice(index, 1);

        if (!this.items.length) {
            this.items = [{ smt: '', delete: false }];
        }
    }

    fetchExists(): void {
        this.existIncompleteLineProduct = [];
        this.existLineProduct = [];

        this.loadingExists = true;

        Promise.all([
            this.$odata.getList<IncompleteLineProduct>('IncompleteLineProduct', {
                select: ['id', 'productSmtNumber', 'line'],
                expand: { line: { select: ['name'] } },
                filter: [{ productSmtNumber: { in: this.items.filter((f) => !f.delete).map((m) => m.smt) } }],
            }),
            this.$odata.getList<LineProduct>('lineProduct', {
                select: ['id', 'product', 'line'],
                expand: { line: { select: ['name'] }, product: { select: ['smtNumber'] } },
                filter: [{ product: { smtNumber: { in: this.items.filter((f) => !f.delete).map((m) => m.smt) } } }],
            }),
        ])
            .then(([ilp, lp]) => {
                this.existIncompleteLineProduct = ilp.data.value;
                this.existLineProduct = lp.data.value;
            })
            .finally(() => {
                this.loadingExists = false;
            });
    }

    clearFields(): void {
        this.items = this.items.filter((el) => {
            return el.delete == false;
        });

        if (!this.items.length) {
            this.items = [{ smt: '', delete: false }];
        }
    }

    async importFromClipboard(): Promise<void> {
        try {
            await navigator.clipboard.readText().then((a) => {
                this.parseFromClipboard(a);
            });
        } catch {
            this.clipboardError = true;
            this.dataExcel = '';
        }
    }

    importExcel(): void {
        if (this.dataExcel) {
            this.parseFromClipboard(this.dataExcel);
            this.dataExcel = '';
        }
    }

    parseFromClipboard(text: string): void {
        var res = flatMap(
            text
                .split('\n')
                .map((m) => m.trim())
                .filter((f) => !!f)
                .map((m) => m.split('\t'))
                .filter((f) => !!f)
        ).map((m) => {
            return { smt: m, delete: false };
        });

        if (res.length) {
            this.items = [...this.items.filter((f) => !!f.smt), ...res, { smt: '', delete: false }];
        }
    }

    openDialog(): void {
        if (this.validItems.length) {
            this.fetchExists();

            this.dialog = true;
        }
    }

    // 1: manual
    // 2: external
    // 3: ncList
    create(type: number): void {
        const types = {
            1: { CreateManual: { smts: this.validItems.map((m) => m.smt) } },
            2: { CreateExternal: { smts: this.validItems.map((m) => m.smt) } },
            3: { CreateNcList: { smts: this.validItems.map((m) => m.smt) } },
        };

        if (this.validItems.length) {
            this.dialog = false;

            this.isSaving = true;

            this.$odata
                .function<any>('IncompleteLineProduct', {
                    func: types[type],
                })
                .then((d) => {
                    if (d.data) {
                        if (d.data.notFound.length === this.validItems.length) {
                            this.notFoundList = d.data.notFound;
                            this.$nextTick(() => (this.notFoundDialog = true));
                        } else if (d.data.notFound.length) {
                            this.$snackbar.success('Page.Pe.Ilp.Create.Messages.Saved');

                            this.items = this.items.filter((f) => d.data.notFound.indexOf(f.smt) > -1);
                            this.notFoundList = d.data.notFound;
                            this.$nextTick(() => (this.notFoundDialog = true));
                        } else {
                            this.$snackbar.success('Page.Pe.Ilp.Create.Messages.Saved');

                            sessionStorage.setItem('ilp-created', JSON.stringify(d.data.created));
                            this.$router.push({ name: 'ip.list', query: { tab: type === 1 ? null : 'siplace' } });
                        }
                    }
                })
                .catch((err) => {
                    this.$snackbar.error(err.message);
                })
                .finally(() => {
                    this.isSaving = false;
                });
        }
    }
}
