














































































































































































import { ODataResult } from '@/models/odata.result';
import { User } from '@/models/user';
import { Group } from '@/models/group';
import { Component, Vue } from 'vue-property-decorator';
import { debounce } from 'lodash';
import { Line } from '@/models/line';
import RefresherComponent from '@/ui/components/refresher.component.vue';
import { generateExcel } from '@/helpers/sheets/generate-excel';
import { GridOptions } from '@/models/vuetify';

@Component({
    components: {
        'c-refresher': RefresherComponent,
    },
})
export default class UserListPage extends Vue {
    firstLoad = true;
    isLoading = false;
    isExport = false;

    allowedCount: number | null = null;
    requestDeleteCount: number | null = null;

    refreshTime = 0;

    users: ODataResult<User> | null = null;

    groups: Group[] = [];
    lines: Line[] = [];

    selected: User[] = [];
    filter: any = {
        group: [],
        line: [],
        username: '',
        lastName: '',
        firstName: '',
        active: true,
        allowed: null,
    };

    options: GridOptions = {
        page: 1,
        itemsPerPage: +(localStorage.getItem('user_itemsPerPage') || 20),
        sortBy: ['id'],
        sortDesc: [true],
        multiSort: true,
    };

    activeFilterItems = [
        { value: null, text: this.$t('Page.Admin.User.List.Filter.ActiveItems.All') },
        { value: true, text: this.$t('Page.Admin.User.List.Filter.ActiveItems.Active') },
        { value: false, text: this.$t('Page.Admin.User.List.Filter.ActiveItems.NotActive') },
    ];

    allowedFilterItems = [
        { value: null, text: this.$t('Page.Admin.User.List.Filter.AllowedItems.All') },
        { value: true, text: this.$t('Page.Admin.User.List.Filter.AllowedItems.Allowed') },
        { value: false, text: this.$t('Page.Admin.User.List.Filter.AllowedItems.NotAllowed') },
    ];

    headers = [
        { text: 'ID', value: 'id', class: 'light-green--text subtitle-2', width: '125px' },
        { text: this.$t('Page.Admin.User.List.Table.UserName'), value: 'userName', class: 'light-green--text subtitle-2' },
        { text: this.$t('Page.Admin.User.List.Table.LastName'), value: 'lastName', class: 'light-green--text subtitle-2' },
        { text: this.$t('Page.Admin.User.List.Table.FirstName'), value: 'firstName', class: 'light-green--text subtitle-2' },
        { text: this.$t('Page.Admin.User.List.Table.Email'), value: 'email', class: 'light-green--text subtitle-2' },
        { text: this.$t('Page.Admin.User.List.Table.Line'), value: 'line.name', class: 'light-green--text subtitle-2' },
        {
            text: this.$t('Page.Admin.User.List.Table.Group'),
            value: 'userGroups',
            class: 'light-green--text subtitle-2',
            sortable: false,
            data: (row: User): string => row.userGroups.map((s) => this.$t('Group.' + s.group.appName)).join(', '),
        },
        // { text: this.$t('Page.Admin.User.List.Table.Allowed'), value: 'isAllowed', class: 'light-green--text subtitle-2', bool: true },
        // { text: this.$t('Page.Admin.User.List.Table.Active'), value: 'isActive', class: 'light-green--text subtitle-2', bool: true },
    ];

    changeDebounce!: () => void;
    created(): void {
        this.changeDebounce = debounce(() => {
            if (this.options.page != 1) {
                this.options.page = 1;
            } else {
                this.fetchData();
            }
        }, 400);
    }

    async fetchData(): Promise<void> {
        if (isNaN(+this.options.itemsPerPage) || !this.options.itemsPerPage) {
            this.options.itemsPerPage = 20;
        }

        if (this.firstLoad) {
            if (this.$route.query && Object.keys(this.$route.query).length) {
                this.filter.username = this.$route.query.userName?.toString();
            }

            this.firstLoad = false;
        }

        this.isLoading = true;
        const filter: any[] = [];

        const skip = (this.options.page - 1) * this.options.itemsPerPage;
        const top = this.options.itemsPerPage;

        if (this.filter.id) {
            filter.push({ id: { eq: +this.filter.id } });
        }

        if (this.filter.username) {
            filter.push({ 'toLower(userName)': { contains: this.filter.username.toLowerCase() } });
        }

        if (this.filter.lastName) {
            filter.push({ 'toLower(lastName)': { contains: this.filter.lastName.toLowerCase() } });
        }

        if (this.filter.firstName) {
            filter.push({ 'toLower(firstName)': { contains: this.filter.firstName.toLowerCase() } });
        }

        if (this.filter.email) {
            filter.push({ 'toLower(email)': { contains: this.filter.email.toLowerCase() } });
        }

        if (this.filter.group.length > 0) {
            filter.push({ userGroups: { any: { groupId: { in: this.filter.group } } } });
        }

        if (this.filter.line.length) {
            filter.push({ lineId: { in: this.filter.line } });
        }

        if (this.filter.active) {
            filter.push({ isActive: { eq: true } });
        }

        filter.push({ isAllowed: { eq: true } });

        filter.push({ isAnonymized: { eq: false } });

        const orderBy = this.options.sortBy.map((sort, index) => sort + (this.options.sortDesc[index] ? ' desc' : '')) as any;

        await this.$odata
            .getList<User>('user', {
                expand: { line: { select: ['name'] }, userGroups: { expand: { group: { select: ['appName'] } } } },
                top: top,
                count: true,
                orderBy,
                skip,
                filter: filter,
            })
            .then((data) => (this.users = data.data))
            .finally(() => {
                this.isLoading = false;
                this.refreshTime = 0;
                this.$nextTick(() => {
                    this.refreshTime = 5 * 60;

                    localStorage.setItem('user_itemsPerPage', this.options.itemsPerPage.toString());
                });
            });
    }

    fetchDataFilter(): void {
        this.$odata.getList<Group>('group').then((data) => (this.groups = data.data.value));
        this.$odata.getList<Line>('line', { orderBy: ['name'] }).then((data) => (this.lines = data.data.value));
    }

    fetchBadgeData(): void {
        this.$odata
            .getList<User>('user', {
                select: ['id'],
                filter: { isActive: { eq: true }, isAllowed: { eq: false } },
                top: 0,
                count: true,
            })
            .then((d) => {
                this.allowedCount = d.data['@odata.count'];
            });

        this.$odata
            .getList<User>('user', {
                select: ['id'],
                filter: { requestDelete: { eq: true } },
                count: true,
                top: 0,
            })
            .then((d) => {
                this.requestDeleteCount = d.data['@odata.count'];
            });
    }

    itemClass(item: User): string {
        const result: string[] = [];
        if (!item.isActive) {
            result.push('row-inactive');
        }

        return result.join(' ');
    }

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-account-multiple-outline');
        this.$store.commit('SetTitle', this.$t('Page.Admin.User.List.Title'));

        this.fetchDataFilter();
        this.fetchBadgeData();
    }

    onFilterSubmit(): void {
        this.selected = [];
        this.fetchData();
    }

    rowClick(e: User): void {
        if (!e.isAnonymized) {
            this.$router.push({ name: 'user.detail', params: { id: e.id.toString() } });
        } else {
            alert('Uživatel byl anonymizován');
        }
    }

    exportData(): void {
        this.isExport = true;

        const filter: any[] = [];

        if (this.filter.username) {
            filter.push({ 'toLower(userName)': { contains: this.filter.username.toLowerCase() } });
        }

        if (this.filter.lastName) {
            filter.push({ 'toLower(lastName)': { contains: this.filter.lastName.toLowerCase() } });
        }

        if (this.filter.firstName) {
            filter.push({ 'toLower(firstName)': { contains: this.filter.firstName.toLowerCase() } });
        }

        if (this.filter.group.length > 0) {
            filter.push({ userGroups: { any: { groupId: { in: this.filter.group } } } });
        }

        if (this.filter.line.length) {
            filter.push({ lineId: { in: this.filter.line } });
        }

        if (this.filter.active !== null) {
            filter.push({ isActive: { eq: this.filter.active } });
        }

        if (this.filter.allowed !== null) {
            filter.push({ isAllowed: { eq: this.filter.allowed } });
        }

        const orderBy = this.options.sortBy.map((sort, index) => sort + (this.options.sortDesc[index] ? ' desc' : '')) as any;

        this.$odata
            .getList<User>('user', {
                expand: { line: { select: ['name'] }, userGroups: { expand: { group: { select: ['name', 'appName'] } } } },
                count: true,
                orderBy,
                filter: filter,
            })
            .then((data) => generateExcel('UserList', this.headers, data.data.value))
            .finally(() => {
                this.isExport = false;
            });
    }
}
