





































import { IncompleteLineProduct } from '@/models';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { allowConvert, getNullProperties } from '@/helpers/ilp';

@Component
export default class IlpConvertInfo extends Vue {
    @Prop({ required: true }) ilp!: IncompleteLineProduct;

    get allowConvert(): boolean {
        return allowConvert(this.ilp);
    }

    getNullProperties(): string[] {
        return getNullProperties(this.ilp);
    }
}
