import { GetterTree } from 'vuex';

import { Modules } from '@/modules/modules';
import { SnackbarState } from './snackbar.state';

export enum SnackbarGetter {
    SNACKBAR = 'SNACKBAR',
}

export enum ModSnackbarGetter {
    SNACKBAR = Modules.SNACKBAR + '/' + SnackbarGetter.SNACKBAR,
}

export const snackbarGetters: GetterTree<SnackbarState, any> = {
    [SnackbarGetter.SNACKBAR]: (state: SnackbarState) => {
        return state.snackbar;
    },
};
