


















































import { ODataResult } from '@/models/odata.result';
import { GridOptions } from '@/models/vuetify';
import { Alert } from '@/modules/alert/models';
import { debounce } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';

import RefresherComponent from '@/ui/components/refresher.component.vue';
import { ModAlertAction } from '@/modules/alert/store/alert.action';

@Component({
    components: {
        'c-refresher': RefresherComponent,
    },
})
export default class AlertListPage extends Vue {
    refreshTime = 0;

    filter: any = { active: true };

    isLoading = false;
    alerts: ODataResult<Alert> | null = null;

    selected: Alert[] = [];

    headers = [
        { text: this.$t('Entity.Alert.Message'), value: 'message', class: 'light-green--text subtitle-2', sortable: false },
        { text: this.$t('Entity.Alert.InfoDate'), value: 'infoDate', class: 'light-green--text subtitle-2', width: '200' },
        { text: this.$t('Entity.Alert.Action'), value: 'action', class: 'light-green--text subtitle-2', width: '200' },
    ];
    options: GridOptions = {
        page: 1,
        itemsPerPage: +(localStorage.getItem('alert_itemsPerPage') || 20),
        sortBy: ['action'],
        sortDesc: [false],
        multiSort: true,
        mustSort: false,
    };

    changeDebounce!: () => void;
    created(): void {
        this.changeDebounce = debounce(() => {
            if (this.options.page != 1) {
                this.options.page = 1;
            } else {
                this.fetchData();
            }
        }, 500);
    }

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-car-brake-alert');
        this.$store.commit('SetTitle', this.$t('Page.Admin.Alert.List.Title'));
    }

    fetchData(): void {
        this.isLoading = true;

        if (isNaN(+this.options.itemsPerPage) || !this.options.itemsPerPage) {
            this.options.itemsPerPage = 20;
        }

        const filter: any = [];
        if (this.filter.active) {
            filter.push('action gt now()');
        }

        const orderBy = this.options.sortBy.map((sort, index) => sort + (this.options.sortDesc[index] ? ' desc' : '')) as any;

        const skip = (this.options.page - 1) * this.options.itemsPerPage;
        const top = this.options.itemsPerPage;

        this.$odata
            .getList<Alert>('alert', {
                orderBy,
                filter,
                top,
                skip,
                count: true,
            })
            .then((a) => (this.alerts = a.data))
            .finally(() => {
                this.isLoading = false;

                this.refreshTime = 0;
                this.$nextTick(() => {
                    this.refreshTime = 5 * 60;

                    localStorage.setItem('alert_itemsPerPage', this.options.itemsPerPage.toString());
                });
            });
    }

    rowClick(alert: Alert): void {
        this.$router.push({ name: 'alert.detail', params: { id: alert.id.toString() } });
    }

    itemClass(item: Alert): string {
        const result: string[] = [];
        if (item.action < new Date()) {
            result.push('row-inactive');
        }

        result.push(item.type + '--text');

        return result.join(' ');
    }

    deleteAlerts(): void {
        if (this.selected.length) {
            this.$odata.function('alert', { func: { Remove: { ids: this.selected.map((m) => m.id) } } }).then(() => {
                this.fetchData();

                this.$store.dispatch(ModAlertAction.INIT_ALERT.toString());
            });
        }
    }
}
