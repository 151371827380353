















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class RefresherComponent extends Vue {
    @Prop() isLoading!: boolean;
    @Prop() refreshTime!: number;
    @Prop() lastUpdate!: Date;

    @Watch('refreshTime', { immediate: true })
    onChangeValue(): void {
        this.countDownDate = new Date().setSeconds(new Date().getSeconds() + this.refreshTime);
    }

    countDownDate = 0;
    interval1 = 0;
    interval2 = 0;
    countdown = '';
    lastUpdateText = '';

    get countDown(): string {
        return this.countdown;
    }

    mounted(): void {
        this.interval1 = setInterval(this.setCountDown, 1000);
        this.interval2 = setInterval(this.setLastUpdate, 1000);
    }

    destroyed(): void {
        clearInterval(this.interval1);
        clearInterval(this.interval2);
    }

    setCountDown(): void {
        if (this.refreshTime) {
            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = this.countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            this.countdown = minutes + 'm ' + seconds + 's ';

            if (distance < 0) {
                this.$emit('refresh');
            }
        }
    }

    setLastUpdate(): void {
        if (this.lastUpdate) {
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = now - this.lastUpdate.getTime();

            // Time calculations for days, hours, minutes and seconds
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            this.lastUpdateText = minutes + 'm ' + seconds + 's ';
        }
    }
}
