import { AuthState } from './auth.state';
import { GetterTree } from 'vuex';
import { Modules } from '@/modules';

export enum AuthGetter {
    USER = 'USER',
    GROUPS = 'GROUPS',

    IN_GROUP = 'IN_GROUP',

    IS_LOGGED_IN = 'IS_LOGGED_IN',
    TOKEN = 'TOKEN',
    AUTH_HEADER = 'AUTH_HEADER',
    EXPIRATION = 'EXPIRATION',
}

export enum ModAuthGetter {
    USER = Modules.AUTH + '/' + AuthGetter.USER,
    GROUPS = Modules.AUTH + '/' + AuthGetter.GROUPS,

    IN_GROUP = Modules.AUTH + '/' + AuthGetter.IN_GROUP,

    IS_LOGGED_IN = Modules.AUTH + '/' + AuthGetter.IS_LOGGED_IN,
    TOKEN = Modules.AUTH + '/' + AuthGetter.TOKEN,
    AUTH_HEADER = Modules.AUTH + '/' + AuthGetter.AUTH_HEADER,
    EXPIRATION = Modules.AUTH + '/' + AuthGetter.EXPIRATION,
}

export const authGetters: GetterTree<AuthState, any> = {
    [AuthGetter.IN_GROUP]: (state) => (groups: string | string[]) => {
        let inGroup = false;

        state.groups.forEach((f) => {
            if (groups == f || (Array.isArray(groups) && groups.includes(f))) {
                inGroup = true;
            }
        });

        return inGroup;
    },

    [AuthGetter.USER]: (state) => {
        return state.user;
    },

    [AuthGetter.GROUPS]: (state) => {
        return state.groups;
    },

    [AuthGetter.IS_LOGGED_IN]: (state) => {
        return !!state.token && !!state.expiration && new Date() < state.expiration;
    },

    [AuthGetter.TOKEN]: (state) => {
        return state.token;
    },

    [AuthGetter.AUTH_HEADER]: (state) => {
        return state.type + ' ' + state.token;
    },

    [AuthGetter.EXPIRATION]: (state) => {
        return state.expiration;
    },
};
