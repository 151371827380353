

















































































































































































































































import { LineProduct } from '@/models';
import { Line } from '@/models/line';
import { ODataResult } from '@/models/odata.result';
import { GridOptions } from '@/models/vuetify';
import { debounce } from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';

import RefresherComponent from '@/ui/components/refresher.component.vue';
import KvalitaHistoryDialog from './history.dialog.vue';
import KvalitaDataDialog from './data.dialog.vue';
import LineProductReleaseInfo from './line-product-release-info.component.vue';
import LineProductReleaseHelper from './line-product-release-helper.component.vue';

@Component({
    components: {
        'c-refresher': RefresherComponent,
        'c-history': KvalitaHistoryDialog,
        'c-data': KvalitaDataDialog,
        'c-line-product-release-info': LineProductReleaseInfo,
        'c-line-produkt-release-helper': LineProductReleaseHelper,
    },
})
export default class KvalitaAllTab extends Vue {
    filter = {
        active: true,
        product: '',
        lines: [],
        tht: '',
        smt: '',
        dpsTemplate: '',
        dpsNumber: '',
        ovenProg: '',
        paste: '',
        squeegee: '',
        dekProgram: '',
        aoi: '',
        support: null,
        release: null,
    };
    lines: Line[] = [];

    isLoading = false;
    lastUpdate: Date | null = null;

    data: ODataResult<any> = { value: [], '@odata.count': 0 };

    dialog = false;
    dataDialog = false;
    lineProductId: number | null = null;
    lineProduct: LineProduct | null = null;

    releaseState = [
        { value: null, text: this.$t('Page.Sheets.Filter.ReleaseState.All') },
        { value: true, text: this.$t('Page.Sheets.Filter.ReleaseState.Released') },
        { value: false, text: this.$t('Page.Sheets.Filter.ReleaseState.UnReleased') },
    ];

    options: GridOptions = {
        page: 1,
        itemsPerPage: +(localStorage.getItem('kvalita_itemsPerPage') || 20),
        sortBy: ['lastUpdate'],
        sortDesc: [true],
        multiSort: true,
        mustSort: false,
    };

    headers = [
        {
            value: 'canRelease',
            sortable: false,
        },
        {
            value: 'lastUpdate',
            text: this.$t('Entity.LineProduct.LastUpdate'),
            class: 'light-green--text subtitle-2',
        },
        {
            value: 'product.smtNumber',
            text: this.$t('Entity.Product.SmtNumber'),
            class: 'light-green--text subtitle-2',
        },
        {
            value: 'line.name',
            text: this.$t('Entity.Line.Name'),
            class: 'light-green--text subtitle-2',
        },
        {
            value: 'product.name',
            text: this.$t('Entity.Product.Name'),
            class: 'light-green--text subtitle-2',
        },
        {
            value: 'product.thtNumber',
            text: this.$t('Entity.Product.ThtNumber'),
            class: 'light-green--text subtitle-2',
        },
        {
            text: this.$t('Entity.Dps.Number'),
            value: 'product.dekKohYoung.dps.number',
            class: 'light-green--text subtitle-2',
        },
        { text: this.$t('Entity.Product.OvenProg'), value: 'product.ovenProg', class: 'light-green--text subtitle-2', f: true },
        { text: this.$t('Entity.Dek.Paste'), value: 'product.dekKohYoung.dek.paste', class: 'light-green--text subtitle-2', f: true },
        { text: this.$t('Entity.Dek.Squeegee'), value: 'product.dekKohYoung.dek.squeegee', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Dek.Program'), value: 'product.dekKohYoung.dek.program', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.DekKohYoung.Aoi'), value: 'product.dekKohYoung.aoi', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.DekKohYoung.Support'), value: 'product.dekKohYoung.support', class: 'light-green--text subtitle-2' },
        {
            value: 'product.dpsTemplate',
            text: this.$t('Entity.Product.DpsTemplate'),
            class: 'light-green--text subtitle-2',
        },
        {
            value: 'isReleased',
            text: this.$t('Entity.LineProduct.IsReleased'),
            class: 'light-green--text subtitle-2',
        },
    ];

    dpsTemplateChanges = {};

    @Watch('dialog')
    onChangeDialog(): void {
        if (!this.dialog) {
            this.lineProductId = null;
        }
    }

    changeDebounce!: () => void;
    created(): void {
        this.changeDebounce = debounce(() => {
            if (this.options.page != 1) {
                this.options.page = 1;
            } else {
                this.fetchData();
            }
        }, 400);
    }

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-star-outline');
        this.$store.commit('SetTitle', this.$t('Page.Sheets.Quality.Title'));

        this.$odata
            .getList<Line>('line', { orderBy: ['name'], filter: [{ isActive: { eq: true } }] })
            .then((a) => (this.lines = a.data.value));
    }

    changeProductDpsTemplate(item: LineProduct): void {
        this.$odata.update('product', item.product.id, { id: item.product.id, dpsTemplate: item.product.dpsTemplate }).then(() => {
            this.$snackbar.success('Uloženo');
            this.dpsTemplateChanges[item.id] = false;
            this.$nextTick(() => this.$forceUpdate());
        });
    }

    openDataDialog(item: LineProduct): void {
        this.lineProduct = item;

        this.$nextTick(() => {
            this.dataDialog = true;
        });
    }

    dpsTemplateChange(id: number): void {
        this.dpsTemplateChanges[id] = true;
        this.$nextTick(() => this.$forceUpdate());
    }

    itemClass(item: LineProduct): string {
        return item.isReleased ? 'row-released' : 'row-unreleased';
    }

    fetchData(): void {
        this.isLoading = true;

        if (isNaN(+this.options.itemsPerPage) || !this.options.itemsPerPage) {
            this.options.itemsPerPage = 20;
        }

        const filter: any[] = [];

        const top = this.options.itemsPerPage;
        const skip = (this.options.page - 1) * this.options.itemsPerPage;
        const orderBy = this.options.sortBy.map(
            (sort, index) => sort.replaceAll('.', '/') + (this.options.sortDesc[index] ? ' desc' : '')
        ) as any;

        if (this.filter.active) filter.push({ isActive: true });
        if (this.filter.lines && this.filter.lines.length) filter.push({ lineId: { in: this.filter.lines } });
        if (this.filter.release !== null) filter.push({ isReleased: Boolean(this.filter.release) });
        if (this.filter.product) filter.push({ product: { 'toLower(name)': { contains: this.filter.product.toLowerCase() } } });
        if (this.filter.tht) filter.push({ product: { 'toLower(thtNumber)': { contains: this.filter.tht.toLowerCase() } } });
        if (this.filter.smt) filter.push({ product: { 'toLower(smtNumber)': { contains: this.filter.smt.toLowerCase() } } });
        if (this.filter.dpsNumber)
            filter.push({ product: { dekKohYoung: { dps: { 'toLower(number)': { contains: this.filter.dpsNumber.toLowerCase() } } } } });
        if (this.filter.dpsTemplate)
            filter.push({ product: { 'toLower(dpsTemplate)': { contains: this.filter.dpsTemplate.toLowerCase() } } });
        if (this.filter.dekProgram)
            filter.push({ product: { dekKohYoung: { dek: { 'toLower(program)': { contains: this.filter.dekProgram.toLowerCase() } } } } });
        if (this.filter.aoi) filter.push({ product: { dekKohYoung: { 'toLower(aoi)': { contains: this.filter.aoi.toLowerCase() } } } });
        if (this.filter.support != null) filter.push({ product: { dekKohYoung: { support: { eq: this.filter.support } } } });
        if (this.filter.ovenProg) filter.push({ product: { 'toLower(ovenProg)': { contains: this.filter.ovenProg.toLowerCase() } } });
        if (this.filter.paste) filter.push({ product: { dekKohYoung: { dek: { 'toLower(paste)': { contains: this.filter.paste } } } } });
        if (this.filter.squeegee)
            filter.push({ product: { dekKohYoung: { dek: { 'toLower(squeegee)': { contains: this.filter.squeegee } } } } });

        this.$odata
            .getList<LineProduct>('lineProduct', {
                top,
                skip,
                filter,
                orderBy,
                count: true,
                expand: {
                    addInfos: {},
                    product: {
                        expand: {
                            dekKohYoung: {
                                expand: {
                                    dps: {},
                                    dek: {},
                                },
                            },
                        },
                    },
                    line: { select: ['id', 'name'] },
                    asys: {},
                    setup: { select: ['id', 'name'] },
                },
            })
            .then((a) => (this.data = a.data))
            .finally(() => {
                this.isLoading = false;
                this.lastUpdate = new Date();

                localStorage.setItem('kvalita_itemsPerPage', this.options.itemsPerPage.toString());
            });
    }

    changeReleaseStatus(): void {
        if (this.lineProduct) {
            this.dataDialog = false;

            this.$odata
                .function('lineProduct', { key: this.lineProduct.id, func: { setReleaseState: { state: !this.lineProduct.isReleased } } })
                .then(() => {
                    this.$snackbar.success('Page.Sheets.Quality.SnackBar.StateChangeRelease');
                    this.$store.dispatch('InitQualityCount');
                    this.fetchData();
                })
                .finally(() => {
                    this.lineProduct = null;
                });
        }
    }

    rowClick(e: LineProduct): void {
        this.lineProductId = e.id;
        this.dialog = true;
    }
}
