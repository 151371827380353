






















































































import { User, UserCreate, UserUpdate } from '@/models/user';
import { eGroup, Group } from '@/models/group';
import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import { VForm } from '@/models/vuetify';
import { debounce } from 'lodash';

@Component
export default class UserDeatilPage extends Vue {
    user: User = { isActive: true } as User;
    groups: Group[] = [];
    requestAccessGroups: number[] = [];
    userGroups: any = {};
    isNew = false;

    userNameExists = false;
    userNameRules: any[] = [];

    @Ref('userForm') userForm!: VForm;

    @Watch('$route.params.id', { immediate: true })
    onChangeUrlParam(id: number): void {
        this.isNew = id > 0 ? false : true;
        this.fetchLists();

        if (!this.isNew) {
            this.fetchUser(id);
            this.fetchRequestAccess(id);
        }
    }

    userNameChangedDebounced!: () => void;
    created(): void {
        this.userNameRules.push(() => !this.userNameExists || this.$t('Validation.UserNameExists'));

        this.userNameChangedDebounced = debounce(() => {
            this.$odata
                .getList<User>('user', {
                    top: 0,
                    count: true,
                    filter: { 'toLower(userName)': { eq: this.user.userName.toLowerCase() }, id: { ne: this.user.id ?? 0 } },
                })
                .then((res) => {
                    this.userNameExists = res.data['@odata.count'] > 0;
                });
        }, 500);
    }

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-account-outline');
        this.$store.commit('SetTitle', this.$t('Page.Admin.User.Detail.Title'));
    }

    lineChange(e: number | null): void {
        if (e) {
            var operator = this.groups.find((f) => f.appName == eGroup.OPERATOR);

            if (operator) {
                this.userGroups = {};
                this.userGroups[operator.id] = true;

                this.groups
                    .filter((f) => f.id != operator?.id)
                    .map((m) => {
                        return { [m.id]: false };
                    })
                    .forEach((i) => Object.assign(this.userGroups, i));
            }
        }
    }

    fetchUser(id: number): void {
        this.$odata.getById<User>('user', +id, { expand: { userGroups: {} } }).then((data) => {
            this.user = data.data;
            this.user.userGroups
                .map((m) => {
                    return { [m.groupId]: true };
                })
                .forEach((i) => Object.assign(this.userGroups, i));
        });
    }

    fetchRequestAccess(id: number): void {
        this.$odata.getList<any>('userRequestAccess', { filter: { userId: { eq: +id } } }).then((a) => {
            if (a.data.value.length > 0) {
                this.requestAccessGroups = a.data.value[0].groups.split(';').map((m: string) => +m);
            }
        });
    }

    fetchLists(): void {
        this.$odata.getList<Group>('group').then((data) => (this.groups = data.data.value));
    }

    submitForm(): void {
        if (this.userForm.validate()) {
            if (this.isNew) {
                this.createUser();
            } else {
                this.updateUser();
            }
        } else {
            console.log(this.userForm);
        }
    }

    createUser(): void {
        let groups: number[] = [];
        groups = Object.keys(this.userGroups)
            .filter((f) => this.userGroups[f])
            .map((key) => Number(key));

        const userCreate: UserCreate = {
            userName: this.user.userName,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            isActive: this.user.isActive,
            password: this.user.password,
            email: this.user.email,
            lineId: this.user.lineId,
            groupIds: groups,
        };

        this.$odata.create<User>('user', userCreate).then(() => {
            this.$snackbar.success('Page.Admin.User.Detail.Message.Add');
            this.$router.push({ name: 'user.list' });
        });
    }

    updateUser(): void {
        let groups: number[] = [];
        groups = Object.keys(this.userGroups)
            .filter((f) => this.userGroups[f])
            .map((key) => Number(key));

        const userUpdate: UserUpdate = {
            id: this.user.id,
            userName: this.user.userName,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            isActive: this.user.isActive,
            isAllowed: this.user.isAllowed,
            password: this.user.password,
            email: this.user.email,
            lineId: this.user.lineId,
            groupIds: groups,
        };
        this.$odata.update<User>('user', this.user.id, userUpdate).then(() => {
            this.$snackbar.success('Page.Admin.User.Detail.Message.Update');
            this.$store.dispatch('InitRequestCount');
            this.$router.push({ name: 'user.list' });
        });
    }

    setAnonymousState(): void {
        if (confirm(this.$t('Page.Admin.User.Detail.Alerts.SetAnonymous').toString())) {
            this.$odata.function('user', { key: this.user.id, func: 'SetAnonymousState()' }).then(() => {
                this.$snackbar.success('Uživatel byl anonymizován');
                this.$router.back();
            });
        }
    }
}
