














































































































































































import { Jidoka } from '@/models/jidoka';
import { Line } from '@/models/line';
import { ODataResult } from '@/models/odata.result';
import { GridOptions, VForm } from '@/models/vuetify';
import { debounce } from 'lodash';
import { Component, Ref, Vue } from 'vue-property-decorator';

import RefresherComponent from '@/ui/components/refresher.component.vue';
import { Station } from '@/models/station';
import { TranslateResult } from 'vue-i18n';
import JidokaHelpDialog from './help.dialog.vue';

@Component({
    components: {
        'c-refresher': RefresherComponent,
        'c-jidoka-help': JidokaHelpDialog,
    },
})
export default class JidokaAllTab extends Vue {
    @Ref('editForm') form!: VForm;

    filter = {
        lines: [],
        stations: [],
        currentRule: '',
    };
    lines: Line[] = [];
    stations: Station[] = [];

    isLoading = false;
    lastUpdate: Date | null = null;

    data: ODataResult<Jidoka> | null = null;

    jidokaId: number | null = null;
    jidoka: Jidoka | null = null;

    editDialog = false;
    editItem: Jidoka = {} as Jidoka;
    saving = false;

    rulesStation = [(v: Station | string): boolean | TranslateResult => !!v || this.$t('Validation.Required')];

    options: GridOptions = {
        page: 1,
        itemsPerPage: +(localStorage.getItem('jidoka_kvalita_itemsPerPage') || 20),
        sortBy: ['line.name'],
        sortDesc: [false],
        multiSort: true,
        mustSort: false,
    };

    headers = [
        // {
        //     value: 'id',
        //     text: '',
        //     width: '50px',
        // },
        {
            value: 'line.name',
            text: this.$t('Entity.Jidoka.Line.Name'),
            class: 'light-green--text subtitle-2',
        },
        {
            value: 'station.name',
            text: this.$t('Entity.Jidoka.Station.Name'),
            class: 'light-green--text subtitle-2',
        },
        {
            value: 'targetFPY',
            text: this.$t('Entity.Jidoka.TargetFPY'),
            class: 'light-green--text subtitle-2',
        },
        {
            value: 'currentRule',
            text: this.$t('Entity.Jidoka.CurrentRule'),
            class: 'light-green--text subtitle-2',
        },
        {
            value: 'description',
            text: this.$t('Entity.Jidoka.Description'),
            class: 'light-green--text subtitle-2',
        },
        {
            value: 'lastUpdate',
            text: this.$t('Entity.Jidoka.LastUpdate'),
            class: 'light-green--text subtitle-2',
        },
        {
            value: 'lastUpdateUser.displayName',
            text: this.$t('Entity.Jidoka.LastUpdateUser'),
            class: 'light-green--text subtitle-2',
        },
        {
            value: 'action',
            text: '',
            width: '70px',
            sortable: false,
        },
    ];

    changeDebounce!: () => void;
    created(): void {
        this.changeDebounce = debounce(() => {
            if (this.options.page != 1) {
                this.options.page = 1;
            } else {
                this.fetchData();
            }
        }, 400);
    }

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-star-outline');
        this.$store.commit('SetTitle', this.$t('Page.Sheets.Jidoka.Title'));

        this.fillStations();
    }

    fillStations(): void {
        this.$odata
            .getList<Station>('station', { select: ['id', 'name'], orderBy: ['name'], filter: [{ isDeleted: { eq: false } }] })
            .then((a) => (this.stations = a.data.value));
    }

    fetchData(): void {
        this.isLoading = true;

        if (isNaN(+this.options.itemsPerPage) || !this.options.itemsPerPage) {
            this.options.itemsPerPage = 20;
        }

        const filter: any[] = [];

        const top = this.options.itemsPerPage;
        const skip = (this.options.page - 1) * this.options.itemsPerPage;
        const orderBy = this.options.sortBy.map(
            (sort, index) => sort.replaceAll('.', '/') + (this.options.sortDesc[index] ? ' desc' : '')
        ) as any;

        filter.push({ isDeleted: { eq: false } });

        if (this.filter.lines && this.filter.lines.length) filter.push({ lineId: { in: this.filter.lines } });
        if (this.filter.stations && this.filter.stations.length) filter.push({ stationId: { in: this.filter.stations } });
        if (this.filter.currentRule?.trim()) filter.push({ 'toLower(currentRule)': { contains: this.filter.currentRule.toLowerCase() } });

        this.$odata
            .getList<Jidoka>('jidoka', {
                top,
                skip,
                filter,
                orderBy,
                count: true,
                expand: {
                    line: { select: ['id', 'name'] },
                    station: { select: ['id', 'name'] },
                    lastUpdateUser: { select: ['id', 'displayName'] },
                },
            })
            .then((a) => {
                this.data = a.data;
            })
            .finally(() => {
                this.isLoading = false;
                this.lastUpdate = new Date();

                localStorage.setItem('jidoka_kvalita_itemsPerPage', this.options.itemsPerPage.toString());
            });
    }

    add(): void {
        this.form?.resetValidation();
        this.editItem = {} as Jidoka;
        this.editDialog = true;
    }

    edit(item: Jidoka): void {
        this.form?.resetValidation();
        this.editItem = Object.assign({}, item);
        this.editDialog = true;
    }

    save(item: Jidoka): void {
        if (this.form.validate()) {
            this.saving = true;

            if (item.station && item.targetFPY != null) {
                if (typeof item.station === 'string') {
                    item.stationName = item.station;
                } else {
                    item.stationId = item.station.id;
                }
            }

            (item.id ? this.$odata.update<Jidoka>('jidoka', item.id, item) : this.$odata.create<Jidoka>('jidoka', item))
                .then(() => {
                    this.$snackbar.success('Jidoka.Detail.Messages.Saved');

                    this.fetchData();
                    this.fillStations();

                    this.editDialog = false;
                })
                .catch(() => this.$snackbar.error(this.$t('Jidoka.Detail.Messages.Error').toString()))
                .finally(() => (this.saving = false));
        }
    }

    remove(item: Jidoka): void {
        if (confirm(this.$t('Jidoka.Detail.Confirm.Remove').toString())) {
            this.$odata.remove('jidoka', item.id).then(() => {
                this.fetchData();
                this.$snackbar.success('Jidoka.Detail.Messages.Remove');
            });
        }
    }

    removeStation(item: Station): void {
        if (confirm(this.$t('Jidoka.Detail.Confirm.RemoveStation').toString())) {
            this.$odata.remove('station', item.id).then(() => {
                this.fillStations();
                this.$snackbar.success('Jidoka.Detail.Messages.RemoveStation');
            });
        }
    }
}
