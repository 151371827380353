












import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import { Modules } from '@/modules/modules';

import { AlertGetter } from '../store/alert.getter';

@Component({
    computed: {
        ...mapGetters(Modules.ALERT, [AlertGetter.ALERT]),
    },
})
export default class AlertComponent extends Vue {}
