import Vue from 'vue';
import Vuex from 'vuex';

import { Modules } from '@/modules/modules';

import snackbarStore from '@/modules/snackbar/store/snackbar.store';
import authStore from '@/modules/auth/store/auth.store';
import { User } from '@/models/user';
import axios from 'axios';
import { ModAuthGetter } from '@/modules/auth/store';

import buildQuery from 'odata-query';
import { ODataResult } from '@/models/odata.result';
import { LineProduct } from '@/models';
import alertStore from '@/modules/alert/store/alert.store';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        version: '1.0.32',
        appStage: 'test',
        dataUrl: '',
        icon: '',
        title: '',
        qualityNew: 0,
        qualityOld: 0,
        requestCount: 0,
        lockedProductCount: 0,
    },
    getters: {
        AppVersion: (state) => state.version,
        AppStage: (state) => state.appStage.toUpperCase(),
        DataUrl: (state) => state.dataUrl,
        RequestCount: (state) => state.requestCount,
        Icon: (state) => state.icon,
        Title: (state) => state.title,
        QualityNew: (state) => state.qualityNew,
        QualityOld: (state) => state.qualityOld,
        QualityCount: (state) => state.qualityNew + state.qualityOld,
        LockedProductCount: (state) => state.lockedProductCount,
    },
    mutations: {
        SetAppStage: (state, payload) => {
            state.appStage = payload;
        },
        SetDataUrl: (state, payload) => {
            state.dataUrl = payload;
        },
        SetRequestCount: (state, payload) => {
            state.requestCount = payload;
        },
        SetIcon: (state, payload) => {
            state.icon = payload;
        },
        SetTitle: (state, payload) => {
            state.title = payload;
        },
        SetQualityNew: (state, payload) => {
            state.qualityNew = payload;
        },
        SetQualityOld: (state, payload) => {
            state.qualityOld = payload;
        },
        SetLockedProductCount: (state, payload) => {
            state.lockedProductCount = payload;
        },
    },
    actions: {
        InitRequestCount: (context) => {
            const queryOptions = {
                filter: { or: [{ isActive: { eq: true }, isAllowed: { eq: false } }, { requestDelete: { eq: true } }] },
                top: 0,
                count: true,
            };

            return axios
                .get<ODataResult<User>>('/odata/user' + buildQuery(queryOptions), {
                    headers: { Authorization: context.getters[ModAuthGetter.AUTH_HEADER] },
                })
                .then((a) => {
                    context.commit('SetRequestCount', a.data['@odata.count']);
                });
        },
        InitQualityCount: (context) => {
            context.dispatch('InitQualityNew');
            context.dispatch('InitQualityOld');
        },
        InitQualityOld: (context) => {
            const queryOptions = {
                func: 'GetOld()',
                filter: { isActive: { eq: true } },
                top: 0,
                count: true,
            };

            return axios
                .get<ODataResult<LineProduct>>('/odata/lineProduct' + buildQuery(queryOptions), {
                    headers: { Authorization: context.getters[ModAuthGetter.AUTH_HEADER] },
                })
                .then((a) => {
                    context.commit('SetQualityOld', a.data['@odata.count']);
                });
        },
        InitQualityNew: (context) => {
            const queryOptions = {
                func: 'GetNew()',
                filter: { isActive: { eq: true } },
                top: 0,
                count: true,
            };

            return axios
                .get<ODataResult<LineProduct>>('/odata/lineProduct' + buildQuery(queryOptions), {
                    headers: { Authorization: context.getters[ModAuthGetter.AUTH_HEADER] },
                })
                .then((a) => {
                    context.commit('SetQualityNew', a.data['@odata.count']);
                });
        },
        InitLockedProductCount: (context) => {
            const queryOptions = {
                filter: { updatorUserId: { ne: null } },
                top: 0,
                count: true,
            };

            return axios
                .get<ODataResult<any>>('/odata/incompleteLineProduct' + buildQuery(queryOptions), {
                    headers: { Authorization: context.getters[ModAuthGetter.AUTH_HEADER] },
                })
                .then((a) => {
                    context.commit('SetLockedProductCount', a.data['@odata.count']);
                });
        },
    },
    modules: {
        [Modules.SNACKBAR]: snackbarStore,
        [Modules.AUTH]: authStore,
        [Modules.ALERT]: alertStore,
    },
});
