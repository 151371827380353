



































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SelectFieldComponent extends Vue {
    @Prop() items!: any[];
    @Prop() value!: any;
    @Prop() label!: string;
    @Prop() hint!: string;

    @Prop({ default: 'id' }) itemValue!: string;
    @Prop({ default: 'name' }) itemText!: string;

    @Prop({ default: false, type: Boolean }) multiple!: boolean;
    @Prop({ default: 3 }) maxDisplay!: number | string;

    @Prop({ type: Boolean, default: true }) dense!: boolean;
    @Prop({ default: true, type: Boolean }) outlined!: boolean;
    @Prop({ type: Boolean }) readonly!: boolean;
    @Prop({ type: Boolean }) required!: boolean;
    @Prop({ type: Boolean }) requiredIndicator!: boolean;
    @Prop({ default: 'red' }) requiredIndicatorColor!: string;
    @Prop({ type: Boolean }) clearable!: boolean;

    @Prop({ default: false }) hideDetails!: boolean | string;

    @Prop({ default: () => [] }) rules!: any[];

    @Prop() props!: any;

    @Prop({ type: Boolean, default: false }) translate!: boolean;
    @Prop() translatePrefix!: string;

    get getMaxDisplay(): number {
        return +this.maxDisplay;
    }

    get isReadonly(): boolean {
        return this.readonly;
    }

    get ruleset(): any[] {
        const result = [...this.rules];

        if (this.required) result.push((v: any) => !!v || this.$t('Validation.Required'));

        return result;
    }

    get requiredColor(): string {
        if (!this.required && this.requiredIndicator) {
            return this.requiredIndicatorColor;
        }

        return 'red';
    }

    getItemText(item: any): string {
        let value = item[this.itemText];

        if (this.translate && this.translatePrefix) {
            value = this.$t(`${this.translatePrefix}.${value}`);
        }

        return value;
    }
}
