import { RouteConfig } from 'vue-router';

import UserView from '@/ui/views/user.view.vue';
import LineView from '@/ui/views/line.view.vue';
import StationView from '@/ui/views/station.view.vue';
import AlertView from '@/ui/views/alert.view.vue';
import GroupPage from '@/ui/pages/admin/group.page.vue';
import AuditPage from '@/ui/pages/admin/audit.page.vue';
import AdminSettingPage from '@/ui/pages/admin/setting.page.vue';

import IncompleteLineProductLockedListPage from '@/ui/pages/admin/incomplete-line-product-locked-list.page.vue';

import { USER_ROUTES } from './user.routes';
import { LINE_ROUTES } from './line.routes';
import { ALERT_ROUTES } from './alert.routes';

export const ADMIN_ROUTES: RouteConfig[] = [
    {
        path: 'user',
        component: UserView,
        children: USER_ROUTES,
    },
    {
        path: 'line',
        component: LineView,
        children: LINE_ROUTES,
    },
    {
        path: 'alert',
        component: AlertView,
        children: ALERT_ROUTES,
    },
    {
        path: 'group',
        component: GroupPage,
        name: 'group.list',
    },
    {
        path: 'setting',
        component: AdminSettingPage,
        name: 'setting.list',
    },
    {
        path: 'products-locked',
        component: IncompleteLineProductLockedListPage,
        name: 'admin.products-locked.list',
    },
    {
        path: 'audit',
        component: AuditPage,
        name: 'admin.audit.list',
    },
];
