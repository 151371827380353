































import Vue from 'vue';

export default Vue.extend({
    props: {
        showConvert: {
            type: Boolean,
            default: false,
        },
        showRequired: {
            type: Boolean,
            default: false,
        },
        showUser: {
            type: Boolean,
            default: false,
        },
    },
});
