import { filter, get } from 'lodash';
import { formatDate } from '..';

import { exportExcel, ISettings } from '../export-excel';

export function generateExcel(name: string, headers: any[], data: any[]): void {
    const exportData = [
        {
            sheet: name,
            columns: [
                ...headers
                    .filter((f) => !f.ignore)
                    .map((m: any) => {
                        return { label: m.text, value: (row: any) => getData(m, row) };
                    }),
            ],
            content: [...data],
        },
    ];

    const settings: ISettings = {
        fileName: name,
        writeOptions: {},
    };

    exportExcel(exportData as any, settings);
}

function getData(header: any, row: any) {
    let result: any = row;

    if (header.data) {
        return header.data(row);
    }

    result = get(row, header.value);

    if (result && header.date) {
        if (typeof header.date === 'string') {
            return formatDate(result, header.date);
        }

        return formatDate(result);
    }

    if (header.bool) return result ? 'ANO' : 'NE';

    return result;
}
