






























































































































































































import { LineProduct, LineProductAddInfo } from '@/models';
import { Line } from '@/models/line';
import { ODataResult } from '@/models/odata.result';
import { User } from '@/models/user';
import { GridOptions } from '@/models/vuetify';
import { Modules } from '@/modules';
import { AuthGetter } from '@/modules/auth/store';
import { debounce } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import RefresherComponent from './refresher.component.vue';
import LineProductInfoComponent from './line-product-info.component.vue';

@Component({
    computed: {
        ...mapGetters(Modules.AUTH, [AuthGetter.USER]),
    },
    components: {
        'c-line-product-addinfo': LineProductInfoComponent,
        'c-refresher': RefresherComponent,
    },
})
export default class LineProductComponent extends Vue {
    USER!: User;
    onlyActive = true;

    refreshTime = 0;

    selectedAll = false;
    intermidate = false;

    toggleSelect(e: { items: any[]; value: boolean }): void {
        if (e.value) {
            this.selected.push(...e.items);
        } else {
            this.selected = [...this.selected.filter((m) => e.items.map((d) => d.id).indexOf(m.id) === -1)];
        }
    }

    toggleSelectAll(e: boolean): void {
        if (e) {
            this.isLoading = true;

            const filter: any[] = [];

            this.currHeaders.forEach((header) => {
                const key = header.value;

                console.log(key);
                console.log(header);

                if (this.filter[key] && header.number) {
                    const prop = `${key.replaceAll('.', '/')}`;
                    filter.push({ [prop]: { eq: +this.filter[key] } });
                } else if (header.isActive) {
                    if (this.filter.isActive !== null) {
                        filter.push({ isActive: Boolean(this.filter.isActive) });
                    }
                } else if (header.isReleased) {
                    if (this.filter.isReleased !== null) {
                        filter.push({ isReleased: Boolean(this.filter.isReleased) });
                    }
                } else if (header.isLine) {
                    if ((this.filter.line || []).length) {
                        filter.push({ lineId: { in: this.filter.line } });
                    }
                } else if (header.productSide) {
                    if ((this.filter.productSides || []).length) {
                        filter.push({ product: { side: { in: this.filter.productSides } } });
                    }
                } else if (header.user) {
                    if ((this.filter.users || []).length) {
                        filter.push({ updatorUserId: { in: this.filter.users } });
                    }
                } else if (header.isBoolean) {
                    if (this.$isBoolean(this.filter[key])) {
                        const prop = `toLower(${key.replaceAll('.', '/')})`;
                        filter.push({ [prop]: { eq: this.filter[key] } });
                    }
                } else if (this.filter[key]) {
                    const prop = `toLower(${key.replaceAll('.', '/')})`;
                    filter.push({ [prop]: { contains: this.filter[key].toLowerCase() } });
                }
            });

            if (this.USER.lineId) {
                filter.push({ lineId: this.USER.lineId });
            }

            if (this.onlyActive) {
                filter.push({ isActive: true });
            }

            const finalFilter: any[] = [];
            if (this.onlySelected) {
                finalFilter.push({ id: { in: [0, ...this.selected.map((m) => m.id)] } });
            } else {
                if (this.selected.length && filter.length) {
                    finalFilter.push({ or: [filter, [{ id: { in: this.selected.map((m) => m.id) } }]] });
                } else {
                    finalFilter.push(...filter);
                }
            }

            this.$odata
                .getList<any>('lineProduct', {
                    filter: finalFilter,
                    select: ['id'],
                })
                .then((a) => (this.selected = a.data.value))
                .finally(() => {
                    this.isLoading = false;
                });
        } else {
            this.selected = [];
        }
    }

    @Prop() headers!: any[];

    @Prop({ type: Boolean, default: false }) helpAsys!: boolean;
    @Prop({ type: Boolean, default: false }) helpSiplace!: boolean;
    @Prop({ type: Boolean, default: false }) helpDek!: boolean;

    @Prop({ type: Boolean, default: true }) showExport!: boolean;

    @Prop() inFilter!: any;
    @Prop() onlySelectedSetup!: { onlySelected: boolean; selected: LineProduct[] };

    @Prop() refresh!: boolean;

    @Watch('selected', { immediate: true })
    onChangeSelected(): void {
        this.$emit('changeSelection', this.selected);

        this.$nextTick(() => {
            if (this.data && this.data['@odata.count']) {
                if (this.data['@odata.count'] === this.selected.length) {
                    this.selectedAll = true;
                    this.intermidate = false;
                } else if (this.selected.length) {
                    this.selectedAll = false;
                    this.intermidate = true;
                } else {
                    this.selectedAll = false;
                    this.intermidate = false;
                }
            } else {
                this.selectedAll = false;
                this.intermidate = false;
            }
        });
    }

    @Watch('inFilter', { immediate: true })
    onChangeSmtNumber(val: any): void {
        this.filter = {
            ...this.filter,
            ...val,
        };

        this.$forceUpdate();
    }

    @Watch('refresh')
    onChangeRefresh(val: boolean): void {
        if (val) {
            this.$nextTick(() => {
                this.fetchData();
            });
        }
    }

    @Watch('onlySelectedSetup', { immediate: true })
    onChangeOnlySelectedSetup(): void {
        if (this.onlySelectedSetup) {
            this.onlySelected = this.onlySelectedSetup.onlySelected;
            this.selected = this.onlySelectedSetup.selected;
        }
    }

    onlySelected = false;

    selected: LineProduct[] = [];

    productSmtNumber = '';
    addInfos: LineProductAddInfo[] = [];
    dialog = false;

    lines: Line[] = [];
    users: User[] = [];

    productSides = [
        { value: 'Strana 1', text: 'Strana 1' },
        { value: 'Strana 2', text: 'Strana 2' },
    ];

    activeState = [
        { value: null, text: this.$t('Page.Sheets.Filter.ActiveState.All') },
        { value: true, text: this.$t('Page.Sheets.Filter.ActiveState.Active') },
        { value: false, text: this.$t('Page.Sheets.Filter.ActiveState.NotActive') },
    ];

    releaseState = [
        { value: null, text: this.$t('Page.Sheets.Filter.ReleaseState.All') },
        { value: true, text: this.$t('Page.Sheets.Filter.ReleaseState.Released') },
        { value: false, text: this.$t('Page.Sheets.Filter.ReleaseState.UnReleased') },
    ];

    firstLoad = true;
    isLoading = false;
    exportIsLoading = false;
    data: ODataResult<any> = { value: [], '@odata.count': 0 };

    filter: any = { line: [], productSides: [], users: [], isActive: true, isReleased: true };

    get currHeaders(): any[] {
        if (this.USER.lineId) {
            return this.headers.filter((f) => !f.isLine);
        }

        return this.headers;
    }

    changeDebounce!: () => void;
    created(): void {
        this.changeDebounce = debounce(() => {
            if (this.options.page != 1) {
                this.options.page = 1;
            } else {
                this.fetchData();
            }
        }, 400);
    }

    pasteClass(item: LineProduct): string {
        if (item.product?.dekKohYoung?.dek?.paste?.includes('A2C69031000')) {
            return 'tmp-pasta--indium';
        }
        if (item.product?.dekKohYoung?.dek?.paste?.includes('A2C53308753')) {
            return 'tmp-pasta--senju';
        }
        if (item.product?.dekKohYoung?.dek?.paste?.includes('A2C53422041')) {
            return 'tmp-pasta--senju-v4';
        }
        if (item.product?.dekKohYoung?.dek?.paste?.includes('A2C50101070')) {
            return 'tmp-pasta--koki';
        }

        return '';
    }

    itemClass(item: LineProduct): string {
        const result: string[] = [];
        if (!item.isActive) {
            result.push('row-inactive');
        }

        return result.join(' ');
    }

    @Watch('headers')
    onChangeHeaders(): void {
        this.fetchData();
    }

    mounted(): void {
        this.$odata
            .getList<Line>('line', { orderBy: ['name'], filter: [{ isActive: { eq: true } }] })
            .then((a) => (this.lines = a.data.value));

        this.$odata
            .getList<User>('user', { orderBy: ['displayName'], filter: [{ isActive: { eq: true } }] })
            .then((a) => (this.users = a.data.value));
    }

    addInfoClick(item: LineProduct): void {
        this.addInfos = [];
        this.productSmtNumber = '';

        this.$nextTick(() => {
            this.productSmtNumber = item.product.smtNumber;
            this.addInfos = item.addInfos;
            this.dialog = true;
        });
    }

    onlySelectedClick(): void {
        this.onlySelected = !this.onlySelected;
        this.$emit('onlySelectedChange', { onlySelected: this.onlySelected, selected: [...this.selected] });
        this.fetchData();
    }

    fetchData(): void {
        if (isNaN(+this.options.itemsPerPage) || !this.options.itemsPerPage) {
            this.options.itemsPerPage = 20;
        }

        if (!this.currHeaders.length) {
            this.data = { value: [], '@odata.count': 0 };
            this.selected = [];

            return;
        }

        if (this.firstLoad) {
            if (this.$route.query && Object.keys(this.$route.query).length) {
                if (this.$route.query.productSmtNumber !== undefined)
                    this.filter['product.smtNumber'] = this.$route.query.productSmtNumber?.toString();
                this.filter['line'] = this.$route.query.line ? [+this.$route.query.line] : [];
                if (this.$route.query.isReleased !== undefined)
                    this.filter['isReleased'] = this.$isBooleanTrue(this.$route.query.isReleased);
                if (this.$route.query.isActive !== undefined) this.onlyActive = this.$isBooleanTrue(this.$route.query.isActive);
            }

            if (this.inFilter) {
                if (this.inFilter['product.smtNumber']) {
                    this.filter['product.smtNumber'] = this.inFilter['product.smtNumber'];
                }
                if (this.inFilter['product.thtNumber']) {
                    this.filter['product.thtNumber'] = this.inFilter['product.thtNumber'];
                }
                if (this.inFilter['line']) {
                    this.filter['line'] = this.inFilter['line'];
                }
                if (this.inFilter['isReleased']) {
                    this.filter['isReleased'] = this.inFilter['isReleased'];
                }
                if (this.inFilter['product.dekKohYoung.dps.number']) {
                    this.onlyActive = this.inFilter['product.dekKohYoung.dps.number'];
                }
            }

            this.firstLoad = false;
        }

        this.isLoading = true;

        const top = this.options.itemsPerPage;
        const skip = (this.options.page - 1) * this.options.itemsPerPage;
        const orderBy = this.options.sortBy.map((sort, index) => sort.replaceAll('.', '/') + (this.options.sortDesc[index] ? ' desc' : ''));

        const filter: any[] = [];

        this.currHeaders.forEach((header) => {
            const key = header.value;

            if (this.filter[key] && header.number) {
                const prop = `${key.replaceAll('.', '/')}`;
                filter.push({ [prop]: { eq: +this.filter[key] } });
            } else if (header.isActive) {
                if (this.filter.isActive !== null) {
                    filter.push({ isActive: Boolean(this.filter.isActive) });
                }
            } else if (header.isReleased) {
                if (this.filter.isReleased !== null) {
                    filter.push({ isReleased: Boolean(this.filter.isReleased) });
                }
            } else if (header.isLine) {
                if (this.filter.line.length) {
                    filter.push({ lineId: { in: this.filter.line } });
                }
            } else if (header.productSide) {
                if (this.filter.productSides.length) {
                    filter.push({ product: { side: { in: this.filter.productSides } } });
                }
            } else if (header.user) {
                if (this.filter.users.length) {
                    filter.push({ updatorUserId: { in: this.filter.users } });
                }
            } else if (header.isBoolean) {
                if (this.$isBoolean(this.filter[key])) {
                    const prop = `${key.replaceAll('.', '/')}`;
                    filter.push({ [prop]: { eq: this.filter[key] } });
                }
            } else if (this.filter[key]) {
                const prop = `toLower(${key.replaceAll('.', '/')})`;
                filter.push({ [prop]: { contains: this.filter[key].toLowerCase() } });
            }
        });

        if (this.USER.lineId) {
            filter.push({ lineId: this.USER.lineId });
        }

        if (this.onlyActive) {
            filter.push({ isActive: true });
        }

        const finalFilter: any[] = [];
        if (this.onlySelected) {
            finalFilter.push({ id: { in: [0, ...this.selected.map((m) => m.id)] } });
        } else {
            if (this.selected.length && filter.length) {
                finalFilter.push({ or: [filter, [{ id: { in: this.selected.map((m) => m.id) } }]] });
            } else {
                finalFilter.push(...filter);
            }
        }

        this.$odata
            .getList<any>('lineProduct', {
                top,
                skip,
                orderBy,
                filter: finalFilter,
                count: true,
                expand: {
                    addInfos: {},
                    product: {
                        expand: {
                            dekKohYoung: {
                                expand: {
                                    dps: {},
                                    dek: {},
                                },
                            },
                        },
                    },
                    line: {},
                    asys: {},
                    setup: {},
                    updatorUser: { select: ['id', 'displayName'] },
                },
            })
            .then((a) => (this.data = a.data))
            .finally(() => {
                this.setColors('released');
                this.setColors('unreleased');

                this.setColors('tmp-pasta--indium');
                this.setColors('tmp-pasta--senju');
                this.setColors('tmp-pasta--senju-v4');
                this.setColors('tmp-pasta--koki');

                this.refreshTime = 0;
                this.$nextTick(() => {
                    this.refreshTime = 5 * 60;

                    localStorage.setItem('lp_itemsPerPage', this.options.itemsPerPage.toString());
                });
                this.isLoading = false;

                this.$emit('changeFilter', {
                    'product.smtNumber': this.filter['product.smtNumber'],
                    'product.thtNumber': this.filter['product.thtNumber'],
                    'product.dekKohYoung.dps.number': this.filter['product.dekKohYoung.dps.number'],
                    line: this.filter['line'],
                    isReleased: this.filter['isReleased'],
                });
            });
    }

    setColors(className: string): void {
        var items = document.getElementsByClassName(className);

        for (var i = 0; i < items.length; i++) {
            const parent = items[i].parentElement;
            if (parent) {
                parent.className = 'cell-' + className.replaceAll('tmp-', '');
            }
        }
    }

    exportData(): void {
        const orderBy = this.options.sortBy.map(
            (sort, index) => sort.replaceAll('.', '/') + (this.options.sortDesc[index] ? ' desc' : '')
        ) as any;

        this.exportIsLoading = true;

        this.$odata
            .getListPost<any>('lineProduct', {
                filter: { id: { in: this.selected.map((m) => m.id) } },
                orderBy,
                expand: {
                    addInfos: {},
                    product: {
                        expand: {
                            dekKohYoung: {
                                expand: {
                                    dps: {},
                                    dek: {},
                                },
                            },
                        },
                    },
                    line: {},
                    asys: {},
                    setup: {},
                },
            })
            .then((a) => {
                this.$emit('export', a.data.value);
            })
            .finally(() => (this.exportIsLoading = false));
    }

    options: GridOptions = {
        page: 1,
        itemsPerPage: +(localStorage.getItem('lp_itemsPerPage') || 20),
        sortBy: [],
        sortDesc: [],
        multiSort: true,
        mustSort: false,
    };
}
