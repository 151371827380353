













































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DateFieldComponent extends Vue {
    @Prop() value!: string;
    @Prop() label!: string;

    @Prop({ type: Boolean }) readonly!: boolean;
    @Prop({ default: true, type: Boolean }) dense!: boolean;
    @Prop({ default: true, type: Boolean }) outlined!: boolean;

    @Prop({ default: () => [] }) rules!: any[];

    @Prop({ type: Boolean }) required!: boolean;
    @Prop({ type: Boolean }) requiredIndicator!: boolean;

    @Prop({ type: Boolean }) cv!: boolean;

    dateHumanFormat = '';

    get isReadonly(): boolean {
        return this.readonly;
    }

    get ruleset(): any[] {
        const result = [...this.rules];

        if (this.required) result.push((v: any) => !!v || this.$t('Validation.Required'));

        result.push((v: any) => this.validaFormat(v) || this.$t('Validation.WrongFormatDate'));

        return result;
    }

    get computerFormat(): string | null {
        return this.value ? this.$formatDate(this.value, 'yyyy-MM-dd') : null;
    }

    get humanFormat(): string | null {
        return this.value ? this.$formatDate(this.value) : null;
    }

    onChangeText(e: string): void {
        this.dateHumanFormat = e;
    }

    onClearClicked(): void {
        this.$emit('input', null);
    }

    onInputChange(e: string): void {
        this.$emit('input', e ? new Date(e) : null);
        this.modal = false;
    }

    validaFormat(v: string): boolean {
        if (!v) return true;

        try {
            const [day, month, year] = v.split('.');
            return !isNaN(new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`).getTime());
        } catch {
            return false;
        }
    }

    parseDate(date: string): void {
        if (!date) {
            return;
        }

        if (this.validaFormat(date)) {
            const [day, month, year] = date.split('.');
            this.$emit('input', new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`));
        }
    }

    modal = false;
}
