













































































import { LineProduct } from '@/models';
import { Line } from '@/models/line';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class KvalitaHistoryDialog extends Vue {
    @Prop() lineProductId!: number;

    audit: any = null;
    audits: any[] = [];
    lines: Line[] = [];
    auditValues: any = {};
    loading = false;
    lineProduct: LineProduct | null = null;

    @Watch('lineProductId', { immediate: true })
    onChangeLineProductId(): void {
        this.fetchData();
    }

    mounted(): void {
        this.$odata
            .getList<Line>('line', { orderBy: ['name'], select: ['id', 'name', 'isActive'] })
            .then((a) => (this.lines = a.data.value));
    }

    fetchData(): void {
        this.loading = true;

        if (!this.lineProductId) {
            this.audit = null;
            this.audits = [];

            return;
        }

        this.$odata
            .getById<LineProduct>('lineProduct', this.lineProductId, { expand: { product: { select: ['smtNumber'] } } })
            .then((a) => (this.lineProduct = a.data));

        this.$odata
            .getList<any>('audit', {
                func: 'Quality()',
                expand: { user: { select: ['displayName'] }, lineProduct: { expand: { product: { select: ['smtNumber'] } } } },
                filter: [
                    { lineProductId: { eq: this.lineProductId } },
                    { group: { eq: 'Quality' } },
                    { newValues: { contains: 'IsReleased' } },
                ],
                orderBy: 'timestamp desc',
                top: 1,
            })
            .then((a) => {
                const filter: any[] = [];

                if (a.data.value.length) {
                    filter.push({ timestamp: { gt: new Date(a.data.value[0].timestamp) } });
                }

                filter.push({ lineProductId: { eq: this.lineProductId } });
                filter.push({ group: { eq: 'PE' } });

                this.$odata
                    .getList<any>('audit', {
                        func: 'Quality()',
                        filter,
                        expand: { user: { select: ['displayName'] }, lineProduct: { expand: { product: { select: ['smtNumber'] } } } },
                        orderBy: 'timestamp asc',
                        count: true,
                    })
                    .then((response) => {
                        if (response.data['@odata.count'] > 0) {
                            this.audit = response.data.value[0];
                            this.audits = [a.data.value[0], ...response.data.value];
                        } else {
                            if (a.data.value[0]) {
                                this.audit = a.data.value[0];
                                this.audits = [a.data.value[0]];
                            } else {
                                this.audit = null;
                                this.audits = [];
                            }
                        }

                        this.auditValues = this.mapAuditValues();
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
    }

    getLine(id: number): Line | undefined {
        return this.lines.find((f) => f.id == id);
    }

    mapAuditValues(): any {
        if (this.audit) {
            const result: any = {};

            this.audits.forEach((audit) => {
                const oldValues = JSON.parse(audit.oldValues);
                const newValues = JSON.parse(audit.newValues);

                Object.keys(oldValues).forEach((key) => {
                    if (!result[key]) {
                        result[key] = [oldValues[key]];
                    }

                    if (result[key] && key === 'IsReleased' && !result[key][0] && oldValues[key]) {
                        result[key] = [oldValues[key]];
                    }
                });

                Object.keys(newValues).forEach((key) => {
                    if (result[key]) {
                        result[key][1] = newValues[key];
                        result[key][2] = audit.user.displayName;
                        result[key][3] = audit.timestamp;
                    } else {
                        result[key] = [null, newValues[key], audit.user.displayName, audit.timestamp];
                    }
                });
            });

            const keysForRemove: string[] = [];

            Object.keys(result).forEach((f) => {
                if (result[f][0] == result[f][1]) {
                    keysForRemove.push(f);
                }
            });

            keysForRemove.forEach((f) => {
                delete result[f];
            });

            return result;
        }

        return {};
    }
}
