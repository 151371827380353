import { Modules } from '@/modules/modules';

import { ActionTree } from 'vuex';
import { AlertMutation } from './alert.mutation';
import { AlertState } from './alert.state';

import axios from 'axios';
import buildQuery, { QueryOptions } from 'odata-query';

import { Alert } from '../models';
import { ODataResult } from '@/models/odata.result';

export enum AlertAction {
    INIT_ALERT = 'INIT_ALERT',
}

export enum ModAlertAction {
    INIT_ALERT = Modules.ALERT + '/' + AlertAction.INIT_ALERT,
}

export const alertActions: ActionTree<AlertState, any> = {
    [AlertAction.INIT_ALERT]: (context) => {
        const queryOptions: Partial<QueryOptions<Alert>> = {
            count: true,
            func: 'GetCurrentAlert()',
        };

        return axios.get<ODataResult<Alert>>('/odata/alert' + buildQuery(queryOptions)).then((a) => {
            if (a.data['@odata.count'] > 0) {
                context.commit(AlertMutation.SHOW_ALERT, a.data.value[0]);
            } else {
                context.commit(AlertMutation.SHOW_ALERT, null);
            }
        });
    },
};
