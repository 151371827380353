





















































import { LineProduct } from '@/models';
import { User } from '@/models/user';
import { Modules } from '@/modules';
import { AuthGetter } from '@/modules/auth/store';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import { generateExcel } from '@/helpers/sheets/generate-excel';

import LineProductComponent from '../line-product.component.vue';
import DekHeaderComponent from './headers/header-dek.component.vue';
import VueHtml2pdf from 'vue-html2pdf';
import PdfDekLineProductComponent from '../exports/pdf/line-product/pdf-dek.component.vue';

@Component({
    computed: {
        ...mapGetters(Modules.AUTH, [AuthGetter.USER]),
    },
    components: {
        'c-line-product': LineProductComponent,
        'c-header-dek': DekHeaderComponent,
        'c-pdf-dek': PdfDekLineProductComponent,
        VueHtml2pdf,
    },
})
export default class OperatorDekTab extends Vue {
    @Ref('html2Pdf') html2Pdf!: any;

    @Prop() inFilter!: any;
    @Prop() onlySelectedSetup!: any;

    get isActive(): boolean {
        return this.$route.query.tab === 'dek';
    }

    lastChange: LineProduct | null = null;

    dataForExport: LineProduct[] = [];

    exportDialog = false;

    USER!: User;
    lastUpdated: LineProduct | null = null;
    headers = [
        {
            text: 'Info',
            value: 'addInfos',
            class: 'light-green--text subtitle-2',
            ignore: true,
            sortable: false,
        },
        { text: this.$t('Entity.Product.SmtNumber'), value: 'product.smtNumber', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Line.Name'), value: 'line.name', class: 'light-green--text subtitle-2', isLine: true },
        { text: this.$t('Entity.Product.Name'), value: 'product.name', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Dps.Number'), value: 'product.dekKohYoung.dps.number', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Product.ThtNumber'), value: 'product.thtNumber', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Dek.Program'), value: 'product.dekKohYoung.dek.program', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Dek.Paste'), value: 'product.dekKohYoung.dek.paste', class: 'light-green--text subtitle-2', f: true },
        { text: this.$t('Entity.Dek.Squeegee'), value: 'product.dekKohYoung.dek.squeegee', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Dek.Template'), value: 'product.dekKohYoung.dek.template', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.Dek.Tooling'), value: 'product.dekKohYoung.dek.tooling', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.DekKohYoung.Aoi'), value: 'product.dekKohYoung.aoi', class: 'light-green--text subtitle-2' },
        {
            text: this.$t('Entity.DekKohYoung.Support'),
            value: 'product.dekKohYoung.support',
            class: 'light-green--text subtitle-2',
            isBoolean: true,
            data: (row: LineProduct) =>
                this.$isBoolean(row.product.dekKohYoung.support)
                    ? row.product.dekKohYoung.support
                        ? this.$t('Global.Yes')
                        : this.$t('Global.No')
                    : '',
        },
        {
            text: this.$t('Entity.LineProduct.IsReleased'),
            value: 'isReleased',
            class: 'light-green--text subtitle-2',
            isReleased: true,
            data: (row: any) => (row.isReleased ? this.$t('Enums.ReleaseState.Released') : this.$t('Enums.ReleaseState.UnReleased')),
        },
    ];

    htmlToPdfOptions = {
        margin: [5, 1, 10, 2],
        html2canvas: {
            y: 0,
            scrollY: 0,
        },
        filename: `${this.$t('Global.DekKohYoung').toString()}.pdf`,
        image: {
            type: 'jpeg',
            quality: 0.98,
        },

        jsPDF: {
            format: 'a4',
            orientation: 'landscape',
        },
    };

    mounted(): void {
        this.$odata
            .getList<LineProduct>('lineProduct', {
                orderBy: ['lastUpdate desc'] as any,
                top: 1,
                filter: { lastUpdate: { ne: null }, helpDek: true },
                expand: { updatorUser: { select: ['id', 'displayName'] } },
            })
            .then((a) => {
                if (a.data.value.length) {
                    this.lastChange = a.data.value[0];
                }
            });
    }

    generateReport(data: LineProduct[]): void {
        this.dataForExport = [...data];

        if (this.dataForExport.length) {
            this.exportDialog = true;
        } else {
            this.$snackbar.error('Global.NoDataSelected');
        }
    }

    generatePdf(): void {
        this.$nextTick(() => {
            this.html2Pdf.generatePdf();

            this.exportDialog = false;
        });
    }

    generateExcel(): void {
        generateExcel(this.$t('Global.DekKohYoung').toString(), this.headers, this.dataForExport);

        this.exportDialog = false;
    }
}
