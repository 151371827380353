



























import { IncompleteLineProduct } from '@/models';
import { ODataResult } from '@/models/odata.result';
import { GridOptions } from '@/models/vuetify';
import RefresherComponent from '@/ui/components/refresher.component.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        'c-refresher': RefresherComponent,
    },
})
export default class IncompleteLineProductLockedListPage extends Vue {
    productsLocked: ODataResult<IncompleteLineProduct> | null = null;
    selected: IncompleteLineProduct[] = [];
    isLoading = false;
    refreshTime = 0;

    headers = [
        { text: this.$t('Entity.Product.SmtNumber'), value: 'productSmtNumber', class: 'light-green--text subtitle-2' },
        { text: this.$t('Entity.LineProduct.UpdatorUser'), value: 'updatorUser.displayName', class: 'light-green--text subtitle-2' },
    ];

    options: GridOptions = {
        page: 1,
        itemsPerPage: +(localStorage.getItem('locked_itemsPerPage') || 20),
        sortBy: ['productSmtNumber'],
        sortDesc: [false],
        multiSort: true,
        mustSort: false,
    };

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-lock-outline');
        this.$store.commit('SetTitle', this.$t('Page.Admin.ProductsLocked.Title'));

        this.fetchData();
    }

    async fetchData(): Promise<void> {
        this.isLoading = true;

        if (isNaN(+this.options.itemsPerPage) || !this.options.itemsPerPage) {
            this.options.itemsPerPage = 20;
        }

        const skip = (this.options.page - 1) * this.options.itemsPerPage;
        const top = this.options.itemsPerPage;

        const orderBy = this.options.sortBy.map((sort, index) => sort + (this.options.sortDesc[index] ? ' desc' : '')) as any;

        await this.$odata
            .getList<IncompleteLineProduct>('incompleteLineProduct', {
                expand: { updatorUser: { select: ['id', 'displayName'] } },
                count: true,
                top,
                orderBy,
                skip,
                filter: { updatorUserId: { ne: null } },
            })
            .then((data) => (this.productsLocked = data.data))
            .finally(() => {
                this.isLoading = false;
                this.refreshTime = 0;
                this.$nextTick(() => {
                    this.refreshTime = 5 * 60;

                    localStorage.setItem('locked_itemsPerPage', this.options.itemsPerPage.toString());
                });
            });
    }

    unlock(): void {
        const ids = this.selected.map((s) => s.id);
        this.$odata.function('incompleteLineProduct', { func: { EndEdit: { ids } } }).then(() => {
            this.fetchData();
            this.$snackbar.success(this.$t('Page.Admin.ProductsLocked.Message.Update').toString());

            this.$store.dispatch('InitLockedProductCount');
        });
    }
}
