import { Module } from 'vuex';
import { authActions } from './auth.action';
import { authGetters } from './auth.getter';
import { authMutations } from './auth.mutation';

import { AuthState } from './auth.state';

const authStore: Module<AuthState, any> = {
    namespaced: true,
    state: {
        user: null,
        groups: [],

        expiration: sessionStorage.getItem('con_expi') ? new Date(sessionStorage.getItem('con_expi') || '') : null,
        token: sessionStorage.getItem('con_token'),
        type: sessionStorage.getItem('con_type'),
    },
    getters: authGetters,
    mutations: authMutations,
    actions: authActions,
};

export default authStore;
