import { MutationTree } from 'vuex';
import { AuthState } from './auth.state';

import { AuthResponse } from '../models';

import { Modules } from '@/modules/modules';
import { User } from '@/models/user';

export enum AuthMutation {
    SET_AUTH = 'SET_AUTH',
    CLEAR_AUTH = 'CLEAR_AUTH',

    SET_USER = 'SET_USER',
    SET_PRAVA = 'SET_PRAVA',
}

export enum ModAuthMutation {
    SET_AUTH = Modules.AUTH + '/' + AuthMutation.SET_AUTH,
    CLEAR_AUTH = Modules.AUTH + '/' + AuthMutation.CLEAR_AUTH,

    SET_USER = Modules.AUTH + '/' + AuthMutation.SET_USER,
    SET_PRAVA = Modules.AUTH + '/' + AuthMutation.SET_PRAVA,
}

export const authMutations: MutationTree<AuthState> = {
    [AuthMutation.SET_USER]: (state: AuthState, payload: User) => {
        if (payload) {
            state.user = payload;
            state.groups = payload.userGroups.map((m) => m.group.appName);
        } else {
            state.user = null;
            state.groups = [];
        }
    },

    [AuthMutation.SET_AUTH]: (state: AuthState, payload: AuthResponse) => {
        state.token = payload.token;
        state.expiration = new Date(payload.expiration);
        state.type = payload.type;

        sessionStorage.setItem('con_token', payload.token);
        sessionStorage.setItem('con_expi', state.expiration.toISOString());
        sessionStorage.setItem('con_type', payload.type);
    },

    [AuthMutation.CLEAR_AUTH]: (state: AuthState) => {
        state.token = null;
        state.expiration = null;
        state.type = null;
        state.user = null;

        sessionStorage.removeItem('con_token');
        sessionStorage.removeItem('con_expi');
        sessionStorage.removeItem('con_type');
    },
};
