











































































import { VForm } from '@/models/vuetify';
import { Alert } from '@/modules/alert/models';
import { ModAlertAction } from '@/modules/alert/store/alert.action';
import addHours from 'date-fns/addHours';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';

@Component
export default class AlertDetailComponent extends Vue {
    alert: Alert | null = null;
    isNew = false;

    isVersion = true;

    @Ref('form') form!: VForm;

    @Watch('$route.params.id', { immediate: true })
    onChangeUrlParam(id: number): void {
        this.isNew = id > 0 ? false : true;

        if (!this.isNew) {
            this.fetchData(id);
        } else {
            this.alert = { action: addHours(new Date(), 4), infoHours: 4, type: 'info' } as Alert;
        }
    }

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-car-brake-alert');
        this.$store.commit('SetTitle', this.$t('Page.Admin.Alert.List.Title'));
    }

    async fetchData(id: number): Promise<void> {
        await this.$odata.getById<Alert>('alert', +id).then((data) => {
            this.isVersion = !!data.data.key;
            this.alert = data.data;
        });
    }

    submitForm(): void {
        if (this.form.validate() && this.alert) {
            if (this.isVersion) {
                this.alert.type = 'info';
                this.alert.key = 'Alert.NewVersion';
                this.alert.message = undefined;
            } else {
                this.alert.key = undefined;
            }

            if (this.isNew) {
                this.createAlert();
            } else {
                this.updateAlert();
            }
        }
    }

    createAlert(): void {
        this.$odata.create<Alert>('alert', this.alert).then(() => {
            this.$snackbar.success(this.$t('Global.DataSaved').toString());

            this.$store.dispatch(ModAlertAction.INIT_ALERT.toString());

            this.$router.push({ name: 'alert.list' });
        });
    }

    updateAlert(): void {
        if (this.alert) {
            this.$odata.update<Alert>('alert', +this.alert.id, this.alert).then(() => {
                this.$snackbar.success(this.$t('Global.DataSaved').toString());

                this.$store.dispatch(ModAlertAction.INIT_ALERT.toString());

                this.$router.push({ name: 'alert.list' });
            });
        }
    }
}
