
















































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class HelpComponent extends Vue {
    isLoading = false;
    files: any[] = [];

    helpSubject = '';
    helpEmail = '';
    helpPhone = '';

    phoneDialog = false;

    mounted(): void {
        this.fetchFiles();
    }

    fetchFiles(): void {
        this.isLoading = true;

        this.$odata.getList<any>('adminSetting').then(({ data }) => {
            this.helpSubject = data.value.find((f) => f.key === 'HELP_SUBJECT')?.value;
            this.helpEmail = data.value.find((f) => f.key === 'HELP_EMAIL')?.value;
            this.helpPhone = data.value.find((f) => f.key === 'HELP_PHONE')?.value;
        });

        this.$odata
            .getList<any>('helpFile')
            .then((data) => {
                this.files = data.data.value;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
}
