var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{attrs:{"label":_vm.label,"items":_vm.data,"value":_vm.value,"item-value":'id',"item-text":'name',"dense":_vm.dense,"readonly":_vm.isReadonly,"filled":_vm.isReadonly,"multiple":_vm.multiple,"max-display":_vm.getMaxDisplay,"clearable":_vm.clearable,"hide-details":_vm.hideDetails,"hint":_vm.hint,"outlined":_vm.outlined,"rules":_vm.ruleset},on:{"keyup":function($event){return _vm.$emit('keyup', $event)},"input":function($event){return _vm.$emit('input', $event)},"change":function($event){return _vm.$emit('change', $event)},"click:clear":function($event){return _vm.$emit('@click:clear', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < _vm.getMaxDisplay)?_c('span',{staticClass:"pr-2",class:{ 'line--not-active': !item.isActive }},[_vm._v(" "+_vm._s(item[_vm.itemText])+_vm._s(!_vm.multiple || _vm.value.length === index + 1 || index === _vm.getMaxDisplay - 1 ? '' : ', ')+" ")]):_vm._e(),(index == _vm.getMaxDisplay)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.value.length - _vm.getMaxDisplay)+")")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g({},on),[(_vm.multiple)?_c('v-list-item-action',[_c('v-simple-checkbox',_vm._g({attrs:{"value":attrs.inputValue,"color":"primary","ripple":false}},on))],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{class:!item.isActive ? 'line--not-active' : ''},[_vm._v(" "+_vm._s(item[_vm.itemText])+" ")])],1)],1)]}}])},[_c('template',{slot:"label"},[(_vm.label)?_c('span',[_vm._v(_vm._s(_vm.label)),(_vm.required)?_c('span',{style:({ color: _vm.requiredColor })},[_vm._v(" *")]):_vm._e()]):_vm._e(),(!_vm.label && (_vm.required || _vm.requiredIndicator))?_c('span',{style:({ color: _vm.requiredColor })},[_vm._v("*")]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }