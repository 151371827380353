import { Modules } from '@/modules/modules';
import router from '@/router';

import { ActionTree } from 'vuex';
import { AuthResponse } from '../models';
import { AuthGetter } from './auth.getter';
import { AuthMutation } from './auth.mutation';
import { AuthState } from './auth.state';

import axios from 'axios';
import { User } from '@/models/user';
import buildQuery, { QueryOptions } from 'odata-query';
import { Group } from '@/models/group';
import { ODataResult } from '@/models/odata.result';

export enum AuthAction {
    INIT_USER = 'INIT_USER',

    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    RE_LOGIN = 'RE_LOGIN',
    REFRESH = 'REFRESH',
}

export enum ModAuthAction {
    INIT_USER = Modules.AUTH + '/' + AuthAction.INIT_USER,

    LOGIN = Modules.AUTH + '/' + AuthAction.LOGIN,
    LOGOUT = Modules.AUTH + '/' + AuthAction.LOGOUT,
    RE_LOGIN = Modules.AUTH + '/' + AuthAction.RE_LOGIN,
    REFRESH = Modules.AUTH + '/' + AuthAction.REFRESH,
}

export const authActions: ActionTree<AuthState, any> = {
    [AuthAction.INIT_USER]: async (context) => {
        const queryOptions: Partial<QueryOptions<User>> = {
            select: ['id', 'userName', 'displayName', 'lineId', 'userGroups', 'isLdap'],
            expand: {
                line: { select: ['id', 'name', 'location'] },
                userGroups: { select: ['groupId'] },
            },
            func: 'GetCurrentUser()',
        };

        try {
            const a = await axios.get<User>('/odata/user' + buildQuery(queryOptions), {
                headers: { Authorization: context.getters[AuthGetter.AUTH_HEADER] },
            });
            const g = await axios.get<ODataResult<Group>>('/odata/user' + buildQuery({ func: 'GetGroups()' }), {
                headers: { Authorization: context.getters[AuthGetter.AUTH_HEADER] },
            });

            const user = { ...a.data };

            for (const group of g.data.value) {
                user.userGroups.find((f) => f.groupId === group.id)!.group = group;
            }

            return context.commit(AuthMutation.SET_USER, user);
        } catch (e) {
            console.log(e);
            context.dispatch(AuthAction.RE_LOGIN);
        }
    },

    [AuthAction.LOGIN]: (context, payload: AuthResponse) => {
        context.commit(AuthMutation.SET_AUTH, {
            token: payload.token,
            expiration: new Date(payload.expiration),
            type: payload.type,
        });
        const rd = router.currentRoute.query['redirect'];
        router.push(((rd as string)?.includes('auth') ? '/' : (rd as string)) || '/');
    },

    [AuthAction.LOGOUT]: (context) => {
        return axios.post('/api/auth/logout', { token: context.getters[AuthGetter.TOKEN] }).finally(() => {
            context.commit(AuthMutation.CLEAR_AUTH);
            router.push({ name: 'login-page' });
        });
    },

    [AuthAction.RE_LOGIN]: (context) => {
        return axios.post('/api/auth/logout', { token: context.getters[AuthGetter.TOKEN] }).finally(() => {
            context.commit(AuthMutation.CLEAR_AUTH);
            if (router.currentRoute.name !== 'login-page') {
                router.push({ name: 'login-page', query: { redirect: router.currentRoute.path } });
            }
        });
    },

    [AuthAction.REFRESH]: (context) => {
        return axios.get('/api/auth/refresh', { headers: { Authorization: context.getters[AuthGetter.AUTH_HEADER] } }).then((a) => {
            context.commit(AuthMutation.SET_AUTH, {
                token: a.data.token,
                expiration: new Date(a.data.expiration),
                type: a.data.type,
            });
        });
    },
};
