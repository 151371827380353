

















































































































































































































































































































































































































import { AuthAction, AuthGetter, ModAuthAction } from '@/modules/auth/store';
import { mapActions, mapGetters } from 'vuex';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Modules } from '@/modules';
import { eGroup } from '@/models/group';
import { VForm } from '@/models/vuetify';

import LanguageComponent from '../components/langugage.component.vue';
import ConfirmComponent from '../components/confirm/confirm.component.vue';
import AlertComponent from '@/modules/alert/components/alert.component.vue';
import { AlertGetter } from '@/modules/alert/store/alert.getter';
import HelpComponent from '../components/help/help.component.vue';

@Component({
    components: {
        'c-lang': LanguageComponent,
        'c-alert': AlertComponent,
        'c-help': HelpComponent,
    },
    computed: {
        ...mapGetters(Modules.AUTH, [AuthGetter.USER, AuthGetter.IN_GROUP, AuthGetter.EXPIRATION]),
        ...mapGetters(Modules.ALERT, [AlertGetter.ALERT]),
    },
    methods: {
        ...mapActions(Modules.AUTH, [AuthAction.LOGOUT, AuthAction.REFRESH]),
    },
})
export default class LayoutView extends Vue {
    @Ref('form') form!: VForm;
    @Ref('confirm') confirm!: ConfirmComponent;

    EXPIRATION!: Date;
    LOGOUT!: any;

    eGroup = eGroup;

    isMini = false;
    drawer = true;

    countdown = '';

    interval = 0;
    notiInterval = 0;

    requestDeleteDialog = false;
    reason = '';

    requestCount: number | null = null;

    helpDialog = false;

    get countDown(): string {
        return this.countdown;
    }

    @Watch('EXPIRATION')
    onExpirationChange(): void {
        this.setCountDown();
    }

    mounted(): void {
        this.$root.$confirm = this.confirm.open;

        this.$store.dispatch(ModAuthAction.INIT_USER.toString());
        this.setCountDown();
        this.notiCountDown();
    }

    initRequestsCount(): void {
        if (this.$inGroup(eGroup.ADMIN)) {
            this.$store.dispatch('InitRequestCount');
        }
    }

    initQualityCount(): void {
        if (this.$inGroup(eGroup.KVALITA)) {
            this.$store.dispatch('InitQualityCount');
        }
    }

    initLockedProductCount(): void {
        if (this.$inGroup([eGroup.PE, eGroup.ADMIN])) {
            this.$store.dispatch('InitLockedProductCount');
        }
    }

    notiCountDown(): void {
        if (this.notiInterval) {
            clearInterval(this.notiInterval);
        }

        this.notiInterval = setInterval(() => {
            this.initRequestsCount();
            this.initQualityCount();
            this.initLockedProductCount();
        }, 1000 * 60 * 5);
    }

    setCountDown(): void {
        if (this.interval) {
            clearInterval(this.interval);
        }

        this.interval = setInterval(() => {
            if (this.EXPIRATION) {
                var now = new Date().getTime();

                // Find the distance between now and the count down date
                var distance = this.EXPIRATION.getTime() - now;

                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                this.countdown = days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ';

                if (distance < 0) {
                    clearInterval(this.interval);
                    this.LOGOUT();
                }
            }
        }, 1000);
    }

    deleteAccount(): void {
        this.requestDeleteDialog = true;
    }

    deleteAccountConfirm(): void {
        if (this.form.validate()) {
            this.$odata.function('user', { func: { RequestForDelete: { reason: this.reason } } }).then(() => {
                this.$snackbar.info(this.$t('Global.DeleteAccountSended').toString());

                this.requestDeleteDialog = false;
            });
        }
    }

    deleteAccountCancel(): void {
        this.requestDeleteDialog = false;
        this.reason = '';
    }

    destroyed(): void {
        clearInterval(this.interval);
        clearInterval(this.notiInterval);
    }
}
