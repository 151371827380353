import { ModAuthAction, ModAuthGetter } from '@/modules/auth/store';
import store from '@/store';
import axios from 'axios';
import { flatMap } from 'lodash';
import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router';

export const isAuth: NavigationGuard = async (to: Route) => {
    if (to.matched.some((m) => m.meta.authRequired)) {
        if (!store.getters[ModAuthGetter.IS_LOGGED_IN]) {
            await store.dispatch(ModAuthAction.RE_LOGIN.toString());
        }
    }
};

export const isInGroup: NavigationGuard = async (to: Route, from: Route, next: NavigationGuardNext) => {
    const groups = flatMap(to.matched.map((m) => m.meta.groups)).filter((f) => !!f);
    if (groups.length) {
        try {
            let queryParam = '';

            groups.forEach((element) => {
                if (queryParam) queryParam += '&';
                queryParam += 'groups=' + element + '&';
            });

            const result = await axios.get<boolean>('/api/auth/inGroup?' + queryParam, {
                headers: { Authorization: store.getters[ModAuthGetter.AUTH_HEADER] },
            });

            if (!result.data) {
                next({ name: 'home' });
            }
        } catch (error) {
            next({ name: 'login-page', query: { redirect: to.fullPath } });
        }
    }
};
