


















































































































import { ODataResult } from '@/models/odata.result';
import { User } from '@/models/user';
import { Group } from '@/models/group';
import { Component, Vue } from 'vue-property-decorator';
import { debounce } from 'lodash';
import RefresherComponent from '@/ui/components/refresher.component.vue';
import { generateExcel } from '@/helpers/sheets/generate-excel';
import { GridOptions } from '@/models/vuetify';

@Component({
    components: {
        'c-refresher': RefresherComponent,
    },
})
export default class UserListGdprPage extends Vue {
    firstLoad = true;
    isLoading = false;
    isExport = false;

    refreshTime = 0;

    users: ODataResult<User> | null = null;

    groups: Group[] = [];

    selected: User[] = [];
    filter: any = {
        username: '',
        lastName: '',
        firstName: '',
    };

    options: GridOptions = {
        page: 1,
        itemsPerPage: +(localStorage.getItem('user_itemsPerPage') || 20),
        sortBy: ['userName'],
        sortDesc: [false],
        multiSort: true,
    };

    headers = [
        { text: this.$t('Page.Admin.User.List.Table.UserName'), value: 'userName', class: 'light-green--text subtitle-2' },
        { text: this.$t('Page.Admin.User.List.Table.LastName'), value: 'lastName', class: 'light-green--text subtitle-2' },
        { text: this.$t('Page.Admin.User.List.Table.FirstName'), value: 'firstName', class: 'light-green--text subtitle-2' },
        {
            text: this.$t('Page.Admin.User.List.Table.Group'),
            value: 'userGroups',
            class: 'light-green--text subtitle-2',
            sortable: false,
            data: (row: User): string => row.userGroups.map((s) => this.$t('Group.' + s.group.appName)).join(', '),
        },
        { text: this.$t('Page.Admin.User.List.Table.Gdpr'), value: 'gdpr', class: 'light-green--text subtitle-2', bool: true },
        {
            text: this.$t('Page.Admin.User.List.Table.GdprDate'),
            value: 'gdprDate',
            class: 'light-green--text subtitle-2',
            date: 'dd.MM.yyyy HH:mm',
        },
        {
            text: this.$t('Page.Admin.User.List.Table.GdprAdmin'),
            value: 'gdprAdmin',
            class: 'light-green--text subtitle-2',
            data: (row: User): string => (this.isAdmin(row) ? (row.gdprAdmin ? 'ANO' : 'NE') : ''),
        },
        {
            text: this.$t('Page.Admin.User.List.Table.GdprAdminDate'),
            value: 'gdprAdminDate',
            class: 'light-green--text subtitle-2',
            date: 'dd.MM.yyyy HH:mm',
        },
    ];

    changeDebounce!: () => void;
    created(): void {
        this.changeDebounce = debounce(() => {
            if (this.options.page != 1) {
                this.options.page = 1;
            } else {
                this.fetchData();
            }
        }, 400);
    }

    async fetchData(): Promise<void> {
        if (isNaN(+this.options.itemsPerPage) || !this.options.itemsPerPage) {
            this.options.itemsPerPage = 20;
        }

        if (this.firstLoad) {
            if (this.$route.query && Object.keys(this.$route.query).length) {
                this.filter.username = this.$route.query.userName?.toString();
            }

            this.firstLoad = false;
        }

        this.isLoading = true;
        const filter: any[] = [];

        const skip = (this.options.page - 1) * this.options.itemsPerPage;
        const top = this.options.itemsPerPage;

        filter.push({ isLdap: { eq: true } }, { isActive: { eq: true } });

        if (this.filter.username) {
            filter.push({ 'toLower(userName)': { contains: this.filter.username.toLowerCase() } });
        }

        if (this.filter.lastName) {
            filter.push({ 'toLower(lastName)': { contains: this.filter.lastName.toLowerCase() } });
        }

        if (this.filter.firstName) {
            filter.push({ 'toLower(firstName)': { contains: this.filter.firstName.toLowerCase() } });
        }

        const orderBy = this.options.sortBy.map((sort, index) => sort + (this.options.sortDesc[index] ? ' desc' : '')) as any;

        await this.$odata
            .getList<User>('user', {
                expand: { line: { select: ['name'] }, userGroups: { expand: { group: { select: ['name', 'appName'] } } } },
                top: top,
                count: true,
                orderBy,
                skip,
                filter: filter,
            })
            .then((data) => (this.users = data.data))
            .finally(() => {
                this.isLoading = false;
                this.refreshTime = 0;
                this.$nextTick(() => {
                    this.refreshTime = 5 * 60;

                    localStorage.setItem('user_itemsPerPage', this.options.itemsPerPage.toString());
                });
            });
    }

    fetchDataFilter(): void {
        this.$odata.getList<Group>('group').then((data) => (this.groups = data.data.value));
    }

    mounted(): void {
        this.$store.commit('SetIcon', 'mdi-account-multiple-outline');
        this.$store.commit('SetTitle', this.$t('Page.Admin.User.List.Title'));

        this.fetchDataFilter();
    }

    onFilterSubmit(): void {
        this.selected = [];
        this.fetchData();
    }

    rowClick(e: User): void {
        if (!e.isAnonymized) {
            this.$router.push({ name: 'user.detail', params: { id: e.id.toString() } });
        } else {
            alert('Uživatel byl anonymizován');
        }
    }

    isAdmin(item: User): boolean {
        return !!item.userGroups.find((f) => f.group.appName === 'ADMIN');
    }

    exportData(): void {
        this.isExport = true;
        const filter: any[] = [];

        filter.push({ isLdap: { eq: true } }, { isActive: { eq: true } });

        if (this.filter.username) {
            filter.push({ 'toLower(userName)': { contains: this.filter.username.toLowerCase() } });
        }

        if (this.filter.lastName) {
            filter.push({ 'toLower(lastName)': { contains: this.filter.lastName.toLowerCase() } });
        }

        if (this.filter.username) {
            filter.push({ 'toLower(firstName)': { contains: this.filter.firstName.toLowerCase() } });
        }

        const orderBy = this.options.sortBy.map((sort, index) => sort + (this.options.sortDesc[index] ? ' desc' : '')) as any;

        this.$odata
            .getList<User>('user', {
                expand: { line: { select: ['name'] }, userGroups: { expand: { group: { select: ['name', 'appName'] } } } },
                count: true,
                orderBy,
                filter: filter,
            })
            .then((data) => generateExcel('UserListGdpr', this.headers, data.data.value))
            .finally(() => {
                this.isExport = false;
            });
    }
}
